//FICHIER DE CONFIGURATION POUR LES VARIABLES DE LARGEUR/HAUTEUR


// Dimension maximal
$max-width: 1200px;
$max-mobilewidth: 325px;
$min-width: 600px;

// MEDIA QUERIES
$vw-thin: 320px;
$vw-small: 375px;
$vw-medium: 820px;
$vw-large: 1024px;
$vw-huge: $max-width;
