

//.carousel-index {
//  display: none;
//}

.hidden {
  display: none;
}


.opa0{
  opacity: 0;
}

.bouge-pu{
  position: absolute;
  color: #FFDF00;
  top:7px;
  left: 10px;
}

.slide-toggle {
  z-index: 3;
}

.add-r {
  position: relative;
}

.re-add {
  position: fixed;
}

p, li {
  font-family: 'Oxygen', sans-serif;
}


//version desktop ici





//ici le code pour le mobile
@media screen and(min-width: 360px) {

  .intro{
    display: none;
  }
  .menu-scroll {
    display: none;
  }
  .menu{
    .bg-menu{
      ul{
        position: relative;
      }
      .cont-reseau-d {
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 20%;
        left: 34%;

        a {
          text-decoration: none;
        }

        div {
          border: solid 2px white;
          width: 30px;
          height: 30px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;

        }

        a:first-child {
          display: inline-block;
          margin-bottom: 20px;
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }

          img:first-child, img:first-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            top: 30%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            bottom: 140%;
            transition: all ease 300ms;
          }


        }

        a:first-child:hover {
          img {
            transform: translateY(35px);
          }

        }

        a:last-child {
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }


          img:first-child, img:first-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            top: 15%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            bottom: 150%;
            content: '';
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition: all ease 300ms;

          }


        }

        a:last-child:hover {
          img {

            transform: translateY(45px);
          }
        }

      }
    }
  }
  header {
    //width: 375px;
    //margin-top: 0;

  }
  .mobile {
    width: 100%;
    overflow: hidden;
position: fixed;
    z-index: 99;
    nav {
      background-color: black;

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        height: 110px;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;

        li:first-child {
          width: 105px;
            img {
            width: 100%;
            height: 50%;
            margin-left: 5%;


          }

        }

        li {
          margin: 10% 10px;
          color: white;
          font-family: Montserrat, 'sans-serif';
          font-weight: 500;
          font-size: rem(15);

          a {

            text-decoration: none;
            color: white;
          }

          .ham-cont {
            border: 2px solid #FFFFFF;
            box-sizing: border-box;
            display: flex;
            height: 50px;
            width: 50px;
            margin-left: -30%;
            //margin-right: 30%;

            .actual-ham {
              display: flex;
              flex-direction: column;
              box-sizing: border-box;
              margin-left: 10%;
              margin-top: 30%;


              //les pains du hamburger
              span {
                display: inline-block;
                border: 1px solid #FFDF00;
                box-sizing: border-box;
                width: 30px;
                height: 2px;
                background-color: #FFDF00;
                margin: 3.5px 4px;
              }

              .t-off1 {
                animation: t-off1 500ms ease forwards;
              }

              @keyframes t-off1 {
                0% {
                  transform: translateY(5px) rotate(135deg);

                }
                100% {
                  transform: rotate(0deg);
                }
              }

              .t-off2 {
                animation: t-off2 500ms ease forwards;
              }

              @keyframes t-off2 {
                0% {
                  transform: translateY(-5px) rotate(-135deg);

                }
                100% {
                  transform: rotate(0deg);

                }
              }

              .t-on1 {

                animation: t-on1 300ms ease forwards;
              }

              @keyframes t-on1 {
                0% {
                  transform: rotate(0deg);

                }
                100% {
                  transform: translateY(5px) rotate(135deg);

                }
              }

              .t-on2 {
                animation: t-on2 300ms ease forwards;
              }

              @keyframes t-on2 {
                0% {
                  transform: rotate(0deg);

                }
                100% {
                  transform: translateY(-5px) rotate(-135deg);

                }
              }

            }
          }

        }

      }
    }


  }
  @keyframes tite-ligne {
    0% {
      width: 0px;
    }
    100% {
      width: 138px;
    }
  }

  .hero-wrapper {
    background-image: url("../images/michael-descharles-UzV2VrbEOC8-unsplash 1.png");
    background-size: cover;
    background-position-y:center ;
    background-position-x:center;
    margin-top: 0;

    .boite-hero {
      display: flex;
      height: 690px;
      position: relative;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0) 100%);

      .col-noire {
        background-color: black;
        width: 25%;
        height: inherit;
      }


      .cont-reseau-d {
        display: none;
      }

      .hero {

        margin-top: 60px;

        div {
margin-top: 45%;

          h1 {

            width: 99%;
            position: relative;
            //top: 10%;
            left: -10%;
            color: white;
            font-family: Montserrat;
            font-size: rem(40);
          }

        }
      }

      .tite-ligne {
        position: absolute;
        display: inline-block;
        height: 5px;
        left: 10%;
        top: 420px;
        animation: tite-ligne 600ms ease-in-out forwards;
        //animation-delay: 300ms;


        background: #FFDF00;
      }




      .cont-fleche {
        position: absolute;
        top: 550px;
        left: 80%;

        .fleche-bas-a {
          position: relative;
          box-sizing: border-box;
          height: 60px;
          width: 48px;

          display: flex;
          //border: 1px solid white;
          padding: 15px 18px;

          img {
            //position: absolute;
            margin-top: 0;
            margin-bottom: 0;
            width: 13px;
            height: 43px;
            z-index: 22;
          }


          //jsute la fleche qui bouge


          .fleche-bas-div {
            position: absolute;
            top: 0;
            left: 0;
            height: 170px;
            width: 100%;

            background-color: #FFDF00;
            clip-path: polygon(0 0, 100% 0, 100% 15%, 0 30%);
            z-index: 2;


          }

          //.fleche-bas-div:hover{
          //  top:0;
          //  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          //}
        }

        .fleche-bas-a {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

          img::after {
            // transform: translateY(-55px);
          }


          img {
            animation: animate 1.8s ease infinite;
            animation-delay: 1.5s;
            @keyframes animate {
              0% {
                transform: translateY(0px);
                opacity: 1;
              }

              50% {
                transform: translateY(60px);
              }

              60% {
                transform: translateY(-55px);
                opacity: 0;
              }
              100% {
                transform: translateY(0);
              }

            }

          }


        }
      }


    }


  }

  .carousel {
    display: flex;
    flex-direction: column-reverse;

    .partie-droite {
      width: 100%;
      margin: 5% auto;

      h1 {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        font-family: Montserrat;


        br {
          display: none;
        }

        span {
          color: #FFDF00;
        }

      }

      p {
        width: 80%;
        font-size: rem(16);
        margin: 5% auto;
        line-height: rem(22);
        font-weight: 600;

      }
    }

    a {
      position: relative;
      display: flex;
      //display: inline-block;
      align-items: center;
      text-transform: uppercase;
      text-decoration: none;
      color: black;
      z-index: 55;
      margin-left: 10%;
      margin-top: 15%;
      padding-left: 2%;
      font-weight: bold;
      font-family: Oxygen;
      font-size: rem(15);

      div {
        background-color: #FFDF00;
        position: absolute;
        width: 164px;
        height: 60px;
        left: -2%;


        clip-path: polygon(0 0, 60% 0, 40% 100%, 0 100%);
        z-index: -1;
      }

      img {
        margin-left: 1%;
        width: auto;
        height: 12px;
        z-index: 55;
      }
    }

    .carou {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: #EAEAEA;
      margin-top: 90px;
      margin-left: 0;
      //box-sizing: border-box;

      .cont-fleche-carou {
        margin-top: 10%;
        margin-left: 60%;

        display: flex;

        img:first-child {
          cursor: pointer;

          transform: rotate(180deg) translateY(-1px);
          margin-right: 5px;
          width: 43px;

        }

        img:last-child {
          cursor: pointer;

          width: 43px;
          margin-left: 5px;

        }


      }

      .greyed {
        opacity: 0.5;
      }



      .slides {
        position: relative;
        height: 455px;
        //box-sizing: inherit;
        //clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 0);
        font-family: Montserrat, 'sans-serif';
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
        //z-index: 222;
        justify-content: center;
        overflow: hidden;
        .slide{

          position: relative;
          width: 100%;
          height: 75%;
          left: 10%;
          //z-index: 55;
          display: flex;
          //flex-direction: column;
          justify-content: center;
          align-items: center;
            h1{

             // margin-top: 500px;
              color: #FFDF00;
              font-family: Montserrat;
              font-weight: 700;
              font-size: rem(40);
              margin-bottom: 0px;
            }
          h2{
              text-transform: uppercase;
            font-family: Montserrat;
            font-size: rem(25);
            width: 80%;
            margin-top: 0;
          }
          &:nth-child(2){
            left: 6%;
            //background-color: red;

          }

        }
        #fr-2{
          left: 2%;
        }

        //div {
        //
        //  position: absolute;
        //  z-index: 22;
        //  left: 15%;
        //  overflow: hidden;
        //
        //  h1 {
        //    color: #FFDF00;
        //    font-size: rem(40);
        //    margin-bottom: 0;
        //  }
        //
        //  h2 {
        //    margin-top: 0;
        //    color: #000;
        //    font-size: rem(25);
        //  }
        //}

        //div:nth-child(1), div:nth-child(1)::before {
        //
        //  top: 10%;
        //  transition: all ease 300ms;
        //}






        div:nth-child(2), div:nth-child(2)::before {

          top: 50%;
          transition: all ease 300ms;
        }


        div:nth-child(3), div:nth-child(3)::before {
          top: 80%;
          transition: all ease 300ms;
        }
      }
    }
  }

  .bandeau {
    //display: flex;
    //flex-direction: column;
    //height: 728px;
    //width: 100%;
    //background-image: url("../images/Gestion-Samurai_Home-BG-2.jpg");
    //background-attachment: fixed;
    //background-position: top;
    //
    //
    //.filtre {
    //  display: flex;
    //  flex-direction: column;
    //  justify-content: space-evenly;
    //  align-items: center;
    //  background-color: rgba(0, 0, 0, 0.8);
    //  width: 100%;
    //  height: inherit;
    //  padding-bottom: 10%;
    //
    //  .chiffre {
    //    display: flex;
    //    flex-direction: column;
    //    align-items: center;
    //
    //
    //
    //    span:first-child {
    //      display: inline-block;
    //      height: 65px;
    //      color: #FFDF00;
    //      font-size: rem(50);
    //      font-weight: 300;
    //      font-family: Montserrat;
    //      margin-bottom: 0;
    //    }
    //
    //    span:last-child {
    //      display: flex;
    //      color: white;
    //      align-items: flex-start;
    //      text-align: left;
    //      margin-right: auto;
    //      margin-left: auto;
    //      margin-top: 0;
    //      font-family: Oxygen;
    //      font-weight: 400;
    //      font-size: rem(20);
    //    }
    //
    //
    //
    //
    //  }
    //}
    display: none;

  }
  .bandeau-mobile {
    display: flex;
    flex-direction: column;
    height: 728px;
    width: 100%;
    background-image: url("../images/Gestion-Samurai_Home-BG-2.jpg");
    background-attachment: fixed;
    background-position: top;


    .filtre {
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
      width: 100%;
      height: inherit;
      padding-bottom: 10px;
      padding-top: 20px;


      .chiffre {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 45px;



        span:first-child {
          display: inline-block;
          height: 65px;
          color: #FFDF00;
          font-size: rem(50);
          font-weight: 300;
          font-family: Montserrat;
          margin-bottom: 0;
        }

        span:last-child {
          display: flex;
          color: white;
          align-items: flex-start;
          text-align: left;
          margin-right: auto;
          margin-left: auto;
          margin-top: 0;
          font-family: Oxygen;
          font-weight: 400;
          font-size: rem(20);
        }




      }
    }

  }
  .work-w-us {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;

    .work-w-us-text {
      width: 80%;
      //margin-left: 5%;

      h1 {
        font-size: rem(30);
        font-weight: 700;
        font-family: Montserrat;
        color: #000;
        margin-top: 50px;
        margin-bottom: 20px;
      }

      p {
        width: 80%;
        font-size: rem(16);
        font-family: Oxygen, 'sans-serif';
        font-weight: 400;
        line-height: rem(22);
        margin-bottom: 60px;
      }

      span {
        font-size: inherit;
        color: #FFDF00;
      }

      a {
        position: relative;
        display: flex;
        //display: inline-block;
        align-items: center;
        text-transform: uppercase;
        text-decoration: none;
        color: black;
        z-index: 55;
        margin-left: 2%;
        margin-top: 10%;
        padding-left: 4%;
        font-weight: 700;
        font-family: Oxygen;
        font-size: rem(15);
        font-style: normal;
        //font-feature-settings: 'cpsp' on;
        letter-spacing: 0.1em;

        div {
          background-color: #FFDF00;
          position: absolute;
          width: 164px;
          height: 60px;
          left: -2%;
          clip-path: polygon(0 0, 60% 0, 45% 100%, 0 100%);
          z-index: -1;
        }

        img {
          margin-left: 3%;
          width: auto;
          height: 12px;
          z-index: 55;
        }
      }
    }

    .work-w-us-img {
      position: relative;
      height: 450px;
      margin-right: 100%;
      margin-top: 30%;

      img {

        width: 345px;
        position: absolute;
        top:16px;
        //height: ;

      }

      div {

        width: 100vw;
        height: 265px;
        position: absolute;
        top: 185px;
        left: -26.5%;
        z-index: 5;
        background-color: #FFDF00;
        mix-blend-mode: darken;
        clip-path: polygon(100% 0, 100% 100%, 0 100%);
      }
    }
  }

  footer {
    z-index: 33;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    padding: 0;
    width: 100%;
    //height: 734px;
    height: fit-content;
    //overflow: hidden;
    .footer-haut, .col-droite, .col-milieu, .col-gauche, .footer-ligne-bas{
      display: none;
    }

    .footer-bas {
      display: none;
    }

    .footer-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
background-color: black;
      //margin-top: 70%;
//margin-left:auto ;
     // margin-right: auto;
      width: 100%;

      p:nth-child(1) {
        width: 80%;
        font-family: Oxygen;
        font-style: normal;
        font-weight: 500;
        font-size: rem(20);
        line-height: rem(25.25);
        //margin: 15% auto;
        margin-bottom: 15%;
        text-transform: uppercase;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        br{
          display: none;
        }

        span {
            color: #FFDF00;

        }
      }

      p:nth-child(2){
        text-transform: uppercase;
        color: #FFDF00;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
        font-family: Oxygen;
        font-weight: 500;
        font-style: normal;
        font-size: rem(20);

      }
      p:nth-child(3){
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        font-family: Oxygen;
        font-weight: 500;
        font-style: normal;
        font-size: rem(16);
      }

      p:nth-child(4){
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        font-family: Oxygen;
        font-weight: 500;
        font-style: normal;
        font-size: rem(16);
      }
      img{
        width: 198px;
        height: 198px;
        //position: relative;
      }
      .cont-mobile-img{
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .cont-footer-res{
        display: flex;
        justify-content: center;
      }

      .cont-reseau-d {
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 490px;
        //left: 34%;
        //margin-left: auto;
       // margin-right: auto;

        a {
          text-decoration: none;
        }

        div {
          border: solid 2px white;
          width: 30px;
          height: 30px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          //margin-left: 20px;

        }

        a:first-child {
          display: inline-block;
          margin-bottom: 20px;
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }

          img:first-child, img:first-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            top: 30%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            bottom: 140%;
            transition: all ease 300ms;
          }


        }

        a:first-child:hover {

          img {
            transform: translateY(35px);
          }

        }

        a:last-child {
          position: relative;
          margin-left: 20px;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }


          img:first-child, img:first-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            top: 15%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            bottom: 150%;
            content: '';
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition: all ease 300ms;

          }


        }

        a:last-child:hover {
          img {

            transform: translateY(45px);
          }
        }

      }


    }

    .footer-mobile-bas{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10%;
      width: 80%;
      p:first-child{
        font-family: Montserrat;
        font-weight: 400;
        font-style: normal;
        font-size: rem(12);
        text-align: center;
        margin-top: 33px;
      }
      p:last-child{
        margin-bottom: 15%;
        font-family: Montserrat;
        font-weight: 400;
        font-style: normal;
        font-size: rem(12);
        color: white;
        margin-left: auto;
        span{
          color:#FFDF00;
          a{
            text-decoration: none;
            //background-color: red;
            color: #FFDF00;
          }
        }
      }
    }



  }

}
@media screen and(min-width: 450px){
  .work-w-us{
    .work-w-us-img{
      img{
        left: 18vw;
      }
    }
  }
}


//code pour la tablette
@media screen and(min-width: 768px){
  .menu-scroll{
    display: none;
  }

  .menu{
    .bg-menu{
      ul{
        position: relative;
      }
      .cont-reseau-d {
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 20%;
        left: 34%;

        a {
          text-decoration: none;
        }

        div {
          border: solid 2px white;
          width: 30px;
          height: 30px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;

        }

        a:first-child {
          display: inline-block;
          margin-bottom: 20px;
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }

          img:first-child, img:first-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            top: 30%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            bottom: 140%;
            transition: all ease 300ms;
          }


        }

        a:first-child:hover {
          img {
            transform: translateY(35px);
          }

        }

        a:last-child {
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }


          img:first-child, img:first-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            top: 15%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            bottom: 150%;
            content: '';
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition: all ease 300ms;

          }


        }

        a:last-child:hover {
          img {

            transform: translateY(45px);
          }
        }

      }
    }
  }

  .hero-wrapper{
    background-image: url("../images/michael-descharles-UzV2VrbEOC8-unsplash_1_1228.png");
    .boite-hero{
      .col-noire{
        width: 17%;
      }
      .hero{
        div{
          margin-top: 40%;
          h1{
            margin-top: 190px;
            font-size: rem(60);
            top: -100px;
           // width: 80%;
            br{
              //display: none;
            }
          }
        }
      }
      .tite-ligne{
        top: 460px;
        left: 9%;
      }
      .cont-fleche{
        left: 90%;
      }
    }

  }
  .carousel{
    .carou{
      .slides{
        .slide-1{
          margin-left: 0;
        }
        .slide-2{
          margin-left: 10%;
        }
        .slide-3{
          margin-left: 5%;
        }
        .slide-1-fr{

        }
        .slide-2-fr{
margin-left: 0;
        }
        .slide-3-fr{
          margin-left: 8%;
        }

      }
    }
    a{
      margin-left: 12%;
      margin-top: 80px;
    }
    .partie-droite{
      margin-top: 67px;
    }
    h1{
      font-size: rem(50);

    }
    p{
      margin-top: 5px;
    }
    .carou{
      .slides{
        .slide:nth-child(2){
          left: 0;
        }
      }
      .slide:nth-child(2){
        left: 2%;
        //background-color: red;
      }
      .slide:nth-child(3){
        left: 7%;
      }

      .cont-fleche-carou{
        margin-left: 75%;
      }
    }
  }
  .work-w-us{
    overflow: hidden;
    //height: 650px;
    .work-w-us-text{
      h1{
        font-size: rem(50);
        //background-color: red;
      }
    }
    .work-w-us-img{
      height: 70vh;
      margin-top: 50px;

      div{
        width: 100vw;
        left: -23%;
        top: 50%;
        overflow: hidden;
        height: 367px;
      }
      img{
        //width: 100%;
      width: auto;
        height: auto;
        left: 25vw;
        top: 92px;
      }
    }
  }

  //footer .footer-mobile .cont-reseau-d{
  //  top:513px;
  //  left: 42%;
  //}

//
  footer {
    z-index: 33;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    padding: 0;
    width: 100%;
    //height: 734px;
    height: fit-content;
    //overflow: hidden;
    .footer-haut, .col-droite, .col-milieu, .col-gauche {
      display: none;
    }

    .footer-bas {
      display: none;
    }
    .footer-ligne-bas{
      display: none;
    }

    .footer-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      background-color: black;
      //margin-top: 70%;
      //margin-left:auto ;
      // margin-right: auto;
      width: 100%;

      p:nth-child(1) {
        width: 80%;
        font-family: Oxygen;
        font-style: normal;
        font-weight: 500;
        font-size: rem(20);
        line-height: rem(25.25);
        //margin: 15% auto;
        margin-bottom: 15%;
        text-transform: uppercase;
        position: relative;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        br{
          display: none;
        }

        span {
          color: #FFDF00;

        }
      }

      p:nth-child(2){
        text-transform: uppercase;
        color: #FFDF00;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
        font-family: Oxygen;
        font-weight: 500;
        font-style: normal;
        font-size: rem(20);

      }
      p:nth-child(3){
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        font-family: Oxygen;
        font-weight: 500;
        font-style: normal;
        font-size: rem(16);
      }

      p:nth-child(4){
        margin-top: 0;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        font-family: Oxygen;
        font-weight: 500;
        font-style: normal;
        font-size: rem(16);
      }
      img{
        width: 198px;
        height: 198px;
        //position: relative;
      }
      .cont-footer-res{
        display: flex;
        justify-content: center;
      }

      .cont-reseau-d {
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 550px;
        //left: 42.5%;
        //margin-left: auto;
        // margin-right: auto;

        a {
          text-decoration: none;
        }

        div {
          border: solid 2px white;
          width: 30px;
          height: 30px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          //margin-left: 20px;

        }

        a:first-child {
          display: inline-block;
          margin-bottom: 20px;
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }

          img:first-child, img:first-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            top: 30%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            bottom: 140%;
            transition: all ease 300ms;
          }


        }

        a:first-child:hover {

          img {
            transform: translateY(35px);
          }

        }

        a:last-child {
          position: relative;
          margin-left: 20px;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }


          img:first-child, img:first-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            top: 15%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            bottom: 150%;
            content: '';
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition: all ease 300ms;

          }


        }

        a:last-child:hover {
          img {

            transform: translateY(45px);
          }
        }

      }


    }

    .footer-mobile-bas{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10%;
      width: 80%;
      p:first-child{
        font-family: Montserrat;
        font-weight: 400;
        font-style: normal;
        font-size: rem(12);
        text-align: center;
        margin-top: 33px;
      }
      p:last-child{
        margin-bottom: 15%;
        font-family: Montserrat;
        font-weight: 400;
        font-style: normal;
        font-size: rem(12);
        color: white;
        margin-left: auto;
        span{
          color:#FFDF00;
          a{
            text-decoration: none;
            //background-color: red;
            color: #FFDF00;
          }
        }
      }
    }



  }
}
@media screen and(min-width: 820px){
  .work-w-us{
    .work-w-us-img{
      div{
        top: 460px;
      }
      img{
        top:197px;
      }
    }
  }


}

//desktop
@media screen and(min-width: 1024px) {
  .mobile, .mobile-hero {
    display: none;
  }
  body {
    //transform-style: preserve-3d;
    //position: relative;
  }

  .cont-col, .footer-ligne-bas{
    display: none;
  }

  .anchore-intro{
    position: relative;
  }
  .intro {
    display: none;
    width: 100%;
    background-color: black;
    position: fixed;
    //display: block;
    top:-230px;
    left: 0;
    height: 100vh;
    animation:intro 500ms ease forwards;
    //animation-delay: 4s;

    //animation-delay: 1s;
    z-index: 1666;
    img{
      display: block;
      width: 100%;
      animation: delete-gif 100ms forwards;
      animation-delay:4s;

      z-index: 1666;

    }

  }

  @keyframes intro {
    0%{

      background-color: black;

//z-index: 1666;
      // height: 100vh;
    }

    100%{

      background-color: transparent;
      //display: none;


      //opacity: 0;
    }
  }

  @keyframes delete-gif {
    0%{
      opacity: 1;
      display: block;
    }
    100%{
      display: none;
      opacity: 0;
      z-index: -1;
    }
  }

  .menu-scroll {
    display: flex;
    justify-content: space-between;
    background-color: black;
  position: fixed;
    width: 100%;
    z-index: 87;
    transition: all ease 200ms;
    //logo
    img {
      width: 150px;
      height: 150px;
      margin-left: 61px;
    }

    nav {
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        height: 110px;


        li {
          margin: 10% 10px;
          color: white;
          font-family: Montserrat, 'sans-serif';
          font-weight: 500;
          font-size: rem(15);

          .call-link{
            color: white;
            transition: color ease 300ms;
            &:hover{
              color: #FFDF00;
            }
          }


          a {

            text-decoration: none;
            color: white;
          }


          .ham-cont {
            border: 2px solid #FFFFFF;
            box-sizing: border-box;
            display: flex;
            height: 50px;
            width: 140px;
            margin-left: 30px;
            margin-right: 40px;
            overflow: hidden;

            .actual-ham {

              display: flex;
              flex-direction: column;
              box-sizing: border-box;
              margin-left: 10%;
              margin-top: 8.5%;
              z-index: 55;


              //les pains du hamburger
              span {
                display: inline-block;
                border: 1px solid #FFDF00;
                box-sizing: border-box;
                width: 30px;
                height: 2px;
                background-color: #FFDF00;
                margin: 4px 5px;
              }

              .t-off1 {
                animation: t-off1 500ms ease forwards;
              }

              @keyframes t-off1 {
                0% {
                  transform: translateY(5px) rotate(135deg);

                }
                100% {
                  transform: rotate(0deg);
                }
              }

              .t-off2 {
                animation: t-off2 500ms ease forwards;
              }

              @keyframes t-off2 {
                0% {
                  transform: translateY(-5px) rotate(-135deg);

                }
                100% {
                  transform: rotate(0deg);

                }
              }

              .t-on1 {

                animation: t-on1 300ms ease forwards;
              }

              @keyframes t-on1 {
                0% {
                  transform: rotate(0deg);

                }
                100% {
                  transform: translateY(5px) rotate(135deg);

                }
              }

              .t-on2 {
                animation: t-on2 300ms ease forwards;
              }

              @keyframes t-on2 {
                0% {
                  transform: rotate(0deg);

                }
                100% {
                  transform: translateY(-5px) rotate(-135deg);

                }
              }

            }

            .cont-menu-mot{
              position: relative;
              transition: all ease 400ms;
              span {
                position: absolute;
                display: inline-block;
                border: none;
                background-color: #101010;
                box-sizing: border-box;
                margin-left: 10%;
                margin-top: 5%;
                z-index: 2;
               // transition: all ease 200ms;
              }
              .bouge1, .bouge1::before{
                position: absolute;
                top:-130px;
                left: 10px;

                transition: all ease 300ms;
                //background-color: blue;

              }
              .bouge2, .bouge2::before{
                top:7px;
                left: 10px;
                //background-color: red;
                color:white;
                transition: all ease 300ms;
                //bottom: 100%;
                //content: '';
              }




            }
            //le mot MENU
            //ici les effets du hover sur le bouton menu
            //transition: all ease 200ms;
            &:hover{
              .cont-menu-mot{
                .bouge1{
                  position: absolute;
                  transform: translateY(138px);
                  //left: 10px;
                  color: #FFDF00;
                  //background-color: blue;

                }
                .bouge2{
                  //background-color: red;
                  //top:55px;
                  transform: translateY(50px);

                  //left: 10px;
                }
              }

            }

          }
        }

        li:nth-child(2) {
          width: 50px;
          height: 2px;
          border: 1px solid #FFDF00;
          box-sizing: border-box;
          transform: rotate(120deg);
          background-color: #FFDF00;
        }
      }


    }
  }

  //.hero-wrapper {
  //
  //  .boite-hero {
  //    display: flex;
  //
  //    .col-noire {
  //      width: 16%;
  //      background-color: black;
  //    }
  //
  //    .hero {
  //      width: 100%;
  //      height: 830px;
  //      background-image: url("../images/Gestion-Samurai_Home-BG.jpg");
  //      background-position: top;
  //      background-color: #101010;
  //      background-repeat: no-repeat;
  //      //background-size: cover;
  //
  //      div {
  //        position: relative;
  //        background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(255, 255, 255, 0) 100%);
  //        //mix-blend-mode: multiply;
  //        height: 830px;
  //        margin-top: 0;
  //        h1{
  //          font-size: rem(80);
  //          top:80px;
  //          left: -8%;
  //        }
  //      }
  //
  //
  //      h1 {
  //        position: absolute;
  //        display: block;
  //        font-size: rem(100);
  //        color: white;
  //        left: -5.2%;
  //        top: -10%;
  //
  //        font-family: 'Montserrat', sans-serif;
  //      }
  //
  //
  //    }
  //
  //    .tite-ligne {
  //      position: absolute;
  //      display: inline-block;
  //      height: 5px;
  //      left: 7.5%;
  //      top: 500px;
  //      animation: tite-ligne 600ms ease-in-out forwards;
  //      //animation-delay: 300ms;
  //
  //
  //      background: #FFDF00;
  //    }
  //
  //    @keyframes tite-ligne {
  //      0% {
  //        width: 0px;
  //      }
  //      100% {
  //        width: 180px;
  //      }
  //    }
  //
  //    .cont-reseau-d {
  //      position: absolute;
  //      top: 670px;
  //      left: 8%;
  //
  //      a {
  //        text-decoration: none;
  //      }
  //
  //      div {
  //        border: solid 2px white;
  //        width: 30px;
  //        height: 30px;
  //        text-align: center;
  //        display: flex;
  //        justify-content: center;
  //        align-items: center;
  //
  //      }
  //
  //      a:first-child {
  //        display: inline-block;
  //        margin-bottom: 10px;
  //        position: relative;
  //
  //        div {
  //          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //        }
  //
  //        img:first-child, img:first-child::before {
  //          position: absolute;
  //          width: 6px;
  //          height: 14px;
  //          top: 30%;
  //          transition: all ease 300ms;
  //        }
  //
  //        img:last-child, img:last-child::before {
  //          position: absolute;
  //          width: 6px;
  //          height: 14px;
  //          bottom: 140%;
  //          transition: all ease 300ms;
  //        }
  //
  //
  //      }
  //
  //      a:first-child:hover {
  //        img {
  //          transform: translateY(35px);
  //        }
  //
  //      }
  //
  //      a:last-child {
  //        position: relative;
  //
  //        div {
  //          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //        }
  //
  //
  //        img:first-child, img:first-child::before {
  //          position: absolute;
  //          width: 13px;
  //          height: 13px;
  //          top: 175%;
  //          transition: all ease 300ms;
  //        }
  //
  //        img:last-child, img:last-child::before {
  //          position: absolute;
  //          width: 13px;
  //          height: 13px;
  //          bottom: 100%;
  //          content: '';
  //          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //          transition: all ease 300ms;
  //
  //        }
  //
  //
  //      }
  //
  //      a:last-child:hover {
  //        img {
  //
  //          transform: translateY(50px);
  //        }
  //      }
  //
  //    }
  //
  //    .cont-fleche {
  //      position: absolute;
  //      top: 680px;
  //      left: 90%;
  //
  //      .fleche-bas-a {
  //        position: relative;
  //        box-sizing: border-box;
  //        height: 60px;
  //
  //        display: flex;
  //        //border: 1px solid white;
  //        //padding: 15px 25px;
  //
  //        img {
  //          //position: absolute;
  //          margin-top: 0;
  //          margin-bottom: 0;
  //          width: 13px;
  //          height: 43px;
  //          z-index: 22;
  //        }
  //
  //
  //        //jsute la fleche qui bouge
  //
  //
  //        .fleche-bas-div {
  //          position: absolute;
  //          top: 0;
  //          left: 0;
  //          height: 170px;
  //          width: 100%;
  //
  //          background-color: #FFDF00;
  //          clip-path: polygon(0 0, 100% 0, 100% 15%, 0 30%);
  //          z-index: 2;
  //
  //
  //        }
  //
  //        //.fleche-bas-div:hover{
  //        //  top:0;
  //        //  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //        //}
  //      }
  //
  //      .fleche-bas-a:hover {
  //        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  //
  //        img::after {
  //          // transform: translateY(-55px);
  //        }
  //
  //
  //        img {
  //          animation: animate 1.8s ease infinite;
  //          @keyframes animate {
  //            0% {
  //              transform: translateY(0px);
  //              opacity: 1;
  //            }
  //
  //            50% {
  //              transform: translateY(60px);
  //            }
  //
  //            60% {
  //              transform: translateY(-55px);
  //              opacity: 0;
  //            }
  //            100% {
  //              transform: translateY(0);
  //            }
  //
  //          }
  //
  //        }
  //
  //
  //      }
  //    }
  //
  //
  //  }
  //}

  .carousel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;

    height: 750px;

    //margin-left: 9%;

    .carou {
      display: flex;
      flex-direction: column;
      width: 520px;
      margin-top: 0;
      background-color: #EAEAEA;
      //box-sizing: border-box;

      .cont-fleche-carou {
        margin-top: 40px;
        margin-left: 65%;

        display: flex;

        img:first-child {
          cursor: pointer;

          transform: rotate(180deg) translateY(-1px);
          margin-right: 5px;
          width: 43px;

        }

        img:last-child {
          cursor: pointer;

          width: 43px;
          margin-left: 5px;

        }


      }

      .greyed {
        opacity: 0.5;
      }

      .hidden {
        display: none;
      }

      .slides {
        position: relative;
        //height: 830px;
        //box-sizing: inherit;
        //clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 0);
        font-family: Montserrat, 'sans-serif';
        font-weight: bold;
        display: flex;
        z-index: 2;
        justify-content: center;
        overflow: hidden;
        margin-top: 30px;

        .slide{

          position: relative;
          width: 100%;
          height: 75%;
          left: 10%;
          //z-index: 55;
          display: flex;
          //flex-direction: column;
          justify-content: center;
          align-items: center;
          h1{

            // margin-top: 500px;
            color: #FFDF00;
            font-family: Montserrat;
            font-weight: 700;
            font-size: rem(65);
            margin-bottom: 0;
          }
          h2{
            text-transform: uppercase;
            font-family: Montserrat;
            font-size: rem(35);
            width: 80%;
            margin-top: 0;
          }
        }


        div:nth-child(1), div:nth-child(1)::before {

          top: 10%;
          transition: all ease-in-out 100ms;
        }



        .animate-up {
          transform: translateY(-125%);
        }

        .animate-up2 {
          transform: translateY(-270%);
        }

        .animate-down {
          transform: translateY(50px);
        }


        div:nth-child(2), div:nth-child(2)::before {

          top: 50%;
          transition: all ease-in-out 100ms;
        }


        div:nth-child(3), div:nth-child(3)::before {
          top: 80%;
          transition: all ease-in-out 100ms;
        }
      }
    }

    .partie-droite {
      width: 771px;
      //margin: auto;
      margin-left: 10px;
      margin-bottom: 230px;
      margin-top: auto;


      h1 {
        font-size: rem(50);
        font-family: Montserrat;
        margin-left: 8%;
        width: 80%;

        br{
          display: none;
        }
        span {
          color: #FFDF00;
        }
      }

      p {
        font-family: Oxygen;
        font-size: rem(20);
        font-weight: 600;
        margin-left: 8%;
        width: 80%;
      }

      a {
        position: relative;
        display: flex;
        //display: inline-block;
        align-items: center;
        text-transform: uppercase;
        text-decoration: none;
        color: black;
        z-index: 55;
        margin-left: 9%;
        margin-top: 10%;
        font-weight: bold;
        font-family: Oxygen;
        transition: all ease 200ms;
        padding-left: 2%;
        width: 30%;


        div {
          background-color: #FFDF00;
          position: absolute;
          width: 200px;
          height: 60px;
          left: -2%;
          transition: all ease 300ms;

          clip-path: polygon(0 0, 50% 0, 40% 100%, 0 100%);
          z-index: -1;
        }
        .en-link1{
          width: 90%;
        }

        img {
          margin-left: 5%;
          width: auto;
          height: 12px;
          z-index: 55;
          transition: all ease 300ms;
        }
      }
      a:hover{

        div{
          width: 90%;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
        .en-link1{
          width: 75%;
        }
        img{
          margin-left: 10%;
          opacity: 0;
        }
      }
    }


  }

  .bandeau {
    display: flex;
    height: 410px;
    width: 97%;
    background-image: url("../images/Gestion-Samurai_Home-BG-2.jpg");
    background-attachment: fixed;



    .filtre {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
      width: 100%;

      .chiffre {
        display: flex;
        flex-direction: column;
        align-items: center;
        .odo-cont{
          display: flex;
          align-items: center;
          font-size: rem(55);
          font-family: Montserrat;
          font-weight: 200;
          color: #FFDF00;
          .odometer{
            display: block;
            font-size: rem(55);
            //margin: 200px 0;
            text-align: center;
            width: 100%;
            color: #FFDF00;
            //font-family: "Comic Sans MS";
            font-weight: 200;

           // height: 110px;
            animation-duration: 3s;
            .odometer-inside{
              font-family: Montserrat;
              .odometer-digit{
                font-family: Montserrat;
              }
            }
          }
        }


        //span:first-child {
        //  display: inline-block;
        //  height: 110px;
        //  color: #FFDF00;
        //  font-size: rem(80);
        //  font-weight: 200;
        //  font-family: Montserrat;
        //  margin-bottom: 0;
        //}

        .sous-titre {
          display: flex;
          color: white;
          align-items: flex-start;
          text-align: left;
          margin-right: auto;
          margin-top: 0;
          font-family: Oxygen;
          font-weight: 400;
          font-size: rem(25);
          margin-left: auto;
        }
      }
      .chiffre:nth-child(2), .chiffre:nth-child(3){
        margin-top: 25px;
        .sous-titre{
          //color: red;
          margin-top: 20px;
          text-align: center;
        }

      }
    }

  }
  .bandeau-mobile{
    display: none;
  }


  .work-w-us {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
padding-bottom: 60px;
    //margin-top: 5%;

    .work-w-us-text {
      width: 50%;
      margin-left: 5%;

      h1 {
        font-size: rem(65);
        font-family: Montserrat;
        color: #000;
        margin-bottom: 33.5px;
      }

      p {
        width: 85%;
        font-size: rem(20);
        font-family: Oxygen, 'sans-serif';
        font-weight: bold;
        line-height: rem(30);
      }

      span {
        font-size: inherit;
        color: #FFDF00;
      }

      a {
        position: relative;
        display: flex;
        //display: inline-block;
        align-items: center;
        text-transform: uppercase;
        text-decoration: none;
        color: black;
        z-index: 55;
        margin-left: 1%;
        margin-top: 16%;
        font-weight: bold;
        font-family: Oxygen;
        font-size: rem(18);
        font-style: normal;
        //font-feature-settings: 'cpsp' on;
        //letter-spacing: 0.1em;
        padding-left: 2%;
        width: 50%;

        div {

          background-color: #FFDF00;
          position: absolute;
          width: 200px;
          height: 60px;
          left: -2%;
          clip-path: polygon(0 0, 60% 0, 45% 100%, 0 100%);
          z-index: -1;
          transition: all ease 300ms;
        }

        img {
          margin-left: 5%;
          width: auto;
          height: 12px;
          z-index: 55;
          transition: all ease 300ms;
        }
      }
      a:hover{
        div{
          //width: 220px;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
        .en-link2{
          width: 38%;
        }

        img{
          margin-left: 10%;
          opacity: 0;
        }
      }
    }

    .work-w-us-img {
      position: relative;
      height: 733px;
      margin-right: 0;

      width: 762px;
      img {
position: absolute;
        left: auto;
        width: 520px;
        height: 733px;
        top:60px;
        z-index: 2;
        //position: relative;

        .opa1{
          opacity: 1;
        }
        .opa0{
          opacity: 0;
        }
        //premiere image

      }


      div {

        width: 100%;
        height: 561px;
        position: absolute;
        z-index: 5;
        top: 32%;
        left: 0%;
        overflow: hidden;
        background-color: #FFDF00;
        mix-blend-mode: darken;
        clip-path: polygon(100% 0, 100% 100%, 0 100%);
      }
    }
  }


  footer {
    margin-top: 0;
    display: flex;
    //flex-direction: ;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background-color: black;
    padding: 0;
    width: 100%;
    //height: 450px;
    overflow: hidden;
    p{
      margin-top: 0;
      text-transform: uppercase;
      span{
        color: #FFDF00;
      }
    }
    .footer-ligne-bas{
       display: none;
     }

    .footer-mobile{
      display: flex;
      margin-top: 50px;
    }

    .cont-col{
      .col-gauche{
        display: block;
        margin-top: 100px;
        margin-left: 3%;
        p:first-child{

          font-size: 1.75rem;
          line-height: 1.8rem;
          span{
            color: #FFDF00;
          }
        }
        p:last-child{
          font-size:rem(12) ;
          font-weight: 400;
          font-family: Montserrat;
        }
      }

      .col-milieu{
        display: block;
        .cont-footer-res{
          display: flex;
          justify-content: center;
          height: auto;
          .cont-reseau-d {
            position: absolute;
            display: flex;
            justify-content: space-between;
            //top: 10%;
            // left: 43%;

            a {
              text-decoration: none;
              &:last-child{
                div{
                  margin-left: 20px;
                }
              }
            }

            div {
              border: solid 2px white;
              width: 30px;
              height: 30px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              //margin-left: 20px;

            }


            a:first-child {
              display: inline-block;
              margin-bottom: 20px;
              position: relative;

              div {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              }

              img:first-child, img:first-child::before {
                position: absolute;
                width: 6px;
                height: 14px;
                top: 30%;
                transition: all ease 300ms;
              }

              img:last-child, img:last-child::before {
                position: absolute;
                width: 6px;
                height: 14px;
                bottom: 140%;
                transition: all ease 300ms;
              }


            }

            a:first-child:hover {
              img {
                transform: translateY(35px);
              }

            }

            a:last-child {
              position: relative;

              div {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              }


              img:first-child, img:first-child::before {
                position: absolute;
                width: 13px;
                height: 13px;
                top: 15%;
                transition: all ease 300ms;
              }

              img:last-child, img:last-child::before {
                position: absolute;
                width: 13px;
                height: 13px;
                bottom: 150%;
                content: '';
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
                transition: all ease 300ms;

              }


            }

            a:last-child:hover {
              img {

                transform: translateY(45px);
              }
            }

          }
        }

      }
      .col-droite{
        display: block;
        margin-right: 3%;
        p{
          text-align: right;
          font-weight: 500;
          font-size: rem(16);
        }
        p:nth-child(1){
          font-size: rem(20);
          font-family: Montserrat;
          margin-top: 100px;

        }
        p:last-child{
          font-size: rem(12);
          a{
            text-decoration: none;
            color: #FFDF00;
          }
        }

      }
    }




    .footer-haut {
      width: 100%;
      margin-top: 2%;
      display: flex;
      justify-content: space-between;
      p.master{
        margin-top: 70px;


          font-size: rem(28);
        br{
          display: inline;
        }

      }

      p:first-child {
        margin-left: 3%;
        font-family: Oxygen;
        font-size: rem(20);
        color: white;
        width: 15%;
        line-height: rem(25);
        //letter-spacing: rem(5);
        text-transform: uppercase;
        //margin-top: 200px;

        span {
          color: #FFDF00;
        }
      }

      img {
        margin-top: 0;
        width: 250px;
        height: 250px;
        //margin-left: auto;
        //margin-right: 7%;
      }

      .liste-footer-haut {
        //margin-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-right: 3%;

        p {
          width: auto;
          font-weight: 500;
        }

        p:nth-child(1) {
          font-family: Montserrat;
          font-size: rem(20);
          color: #FFDF00;
          margin-bottom: 0;
          //margin-left: 38%;
          margin-top: 70px;
        }

        p:nth-child(2) {
          width: fit-content;
          //margin-top: 10%;
          //width: border-box;
          display: block;
          font-size: rem(16);
          font-family: Montserrat;
          text-align: center;
          //margin-right: 15%;
          //margin-left: 47%;
        }

        p:nth-child(3) {
          display: block;
          font-size: rem(16);
          font-family: Montserrat;
          text-transform: uppercase;
          text-align: right;
          line-height: rem(20);
          //margin-left: -13%;


        }

      }
    }

    .footer-bas {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: inherit;
      overflow: hidden;
      margin-top: 20px;

      .cont-footer-res{
        position: relative;
        display: flex;
        margin-left: auto;
        margin-right: 20%;

      }

      p:first-child {
        display: block;
        text-transform: uppercase;
        font-weight: 400;
        font-size: rem(12);
        font-family: Montserrat;
        margin-top: 1%;
        margin-left: 3%;
        //margin-right: auto;
      }

      p:last-child {
        display: block;
        text-transform: uppercase;
        font-weight: 400;
        font-size: rem(12);
        font-family: Montserrat;
        margin-top: 1%;
        margin-right: 3%;

        span {
          color: #FFDF00;
          a{
            text-decoration: none;
            //background-color: red;
            color: #FFDF00;
          &:visited{
            text-decoration: none;
            color: #FFDF00;
          }
          }
        }
      }

      .cont-reseau-d {
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 10%;
        left: 43%;

        a {
          text-decoration: none;
          &:last-child{
            div{
              margin-left: 20px;
            }
          }
        }

        div {
          border: solid 2px white;
          width: 30px;
          height: 30px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          //margin-left: 20px;

        }


        a:first-child {
          display: inline-block;
          margin-bottom: 20px;
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }

          img:first-child, img:first-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            top: 30%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            bottom: 140%;
            transition: all ease 300ms;
          }


        }

        a:first-child:hover {
          img {
            transform: translateY(35px);
          }

        }

        a:last-child {
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }


          img:first-child, img:first-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            top: 15%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            bottom: 150%;
            content: '';
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition: all ease 300ms;

          }


        }

        a:last-child:hover {
          img {

            transform: translateY(45px);
          }
        }

      }
    }

  }

}

@media screen and (min-width: 1280px){
  footer{
    .footer-mobile{
      .cont-footer-res{
        .cont-reseau-d{
          top:595px;
        }
      }
    }
    .footer-bas{
      .cont-footer-res{
        margin-right: 24%;
        margin-left: auto;
      }
    }
  }
}

@media screen and(min-width: 1280px){
  .intro{display: block}
  .hero-wrapper{
    background-image: url("../images/Gestion-Samurai_Home-BG.jpg");
    background-position: center center;
    .boite-hero{
      height: 100vh;
    }
  }

  footer{
    display: block;
    height: fit-content;
    .footer-mobile{
      display: none;
    }

    .cont-col{
      display: flex;
      justify-content: space-between;

      .col-gauche{
        //display: block;
        margin-left: 3%;
        p:first-child{

          font-size: 1.75rem;
          line-height: 1.8rem;
          span{
            color: #FFDF00;
          }
        }
      }
      .col-milieu{
        margin-top: 40px;
        img{
          margin-top:0 ;
        }


        img:first-child{
          //margin-top: 40px;
          margin-bottom: 40px;
        }


      }
      .col-droite{
        p{
          font-family: Montserrat;
        }

      }

    }
    .footer-ligne-bas{
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      p{
        font-family: Montserrat;
      }
      p:first-child{
        margin-left: 3%;
        font-size: rem(12);
        font-family: Montserrat;
        font-weight: 400;
      }
      p:last-child{
        margin-right: 3%;
        font-size: rem(12);
        font-weight: 500;
        font-family: Montserrat;
        span{
          color: #FFDF00;
          a{
            color: #FFDF00;
            text-decoration: none ;
          }
        }
      }
    }
  }
}

@media only screen and(min-width: 1440px){
  .intro{
    display: block;
  }
  .hero-wrapper{
    background-image: url("../images/Gestion-Samurai_Home-BG.jpg");
    .boite-hero{
      .tite-ligne{
        top:500px;
        left: 13%;
      }
      .cont-fleche{
        //top:80vh;
      }
      .cont-reseau-d {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        top: 555px;
        left: 12%;

        a {
          text-decoration: none;
        }

        div {
          border: solid 2px white;
          width: 30px;
          height: 30px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;

        }

        a:first-child {
          display: inline-block;
          margin-bottom: 20px;
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }

          img:first-child, img:first-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            top: 30%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            bottom: 140%;
            transition: all ease 300ms;
          }


        }

        a:first-child:hover {
          img {
            transform: translateY(35px);
          }

        }

        a:last-child {
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }


          img:first-child, img:first-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            top: 24%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            bottom: 150%;
            content: '';
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition: all ease 300ms;

          }


        }

        a:last-child:hover {
          img {

            transform: translateY(35px);
          }
        }

      }
    }
  }


  .work-w-us{
    .work-w-us-text{
      a{
        //width: ;
      }
    }
  }
  .carousel{

    .carou{
      margin-left: auto;
      margin-right: auto;
      .slides{
        .slide-1-fr{
          margin-left: 0;
        }
        .slide-2-fr{

        }
        .slide-3-fr{
          margin-left: 3%;
        }
        .slide-3{
          margin-left: 0%;
        }
        .slide:nth-child(2){
          left: 0;
        }
      }
    }
    .partie-droite{
      margin-top: 80px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  footer{
    display: block;

    //justify-content: center;

    p{
      text-transform: uppercase
    }

    .footer-mobile{
      display: none;
    }
    .cont-col{
      display: flex;
      justify-content: space-between;

      .col-gauche{
        //display: block;
        margin-left: 3%;
        p:first-child{

          font-size: 1.75rem;
          line-height: 1.8rem;
          span{
            color: #FFDF00;
          }
        }
      }
      .col-milieu{
        margin-top: 40px;
        img{
          margin-top:0 ;
        }


      img:first-child{
          //margin-top: 40px;
          margin-bottom: 40px;
        }


      }
      .col-droite{
        p{
          font-family: Montserrat;
        }

      }

    }
    .footer-ligne-bas{
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      p{
        font-family: Montserrat;
      }
      p:first-child{
        margin-left: 3%;
        font-size: rem(12);
        font-family: Montserrat;
        font-weight: 400;
      }
      p:last-child{
        margin-right: 3%;
        font-size: rem(12);
        font-weight: 500;
        font-family: Montserrat;
        span{
          color: #FFDF00;
          a{
            color: #FFDF00;
            text-decoration: none ;
          }
        }
      }
    }



    .footer-haut{
      display: flex;
      //justify-content: center;

      .liste-footer-haut{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-right: 3%;
      }
      p{
        //margin-left: auto;
        //margin-right: auto;
      }

      img{
        display: flex;
        //justify-content: center;
        //margin-right: 15%;
        //margin-left: auto;
      }
    }

    .footer-bas{
      .cont-footer-res{
        margin-left: auto;
        margin-right: 26%;
      }
      .cont-reseau-d{
        left:49.5%;
      }
    }
  }

}
@media screen and(min-width: 1600px){
  .carousel{
    .carou{
      .slides{

        .slide-1-fr{

        }
        .slide-2-fr{

        }
        div:nth-child(3){
          .slide-3-fr{
            margin-left: 8%;
          }
        }
        .slide-3{
          margin-left: 5%;
        }

        div:nth-child(3){

          left:5%;
        }
        .slide:nth-child(2){
          //background-color: red;
          left: 0%;
        }
      }
    }
  }
  footer{
    .footer-haut{
      img{
        //margin-right: 16%;
      }
      .liste-footer-haut{
       // margin-left: 2%;
      }
    }
    .footer-bas{
      .cont-footer-res{
        margin-left: auto;
        margin-right: 27%;;
      }
    }
  }
}

@media screen and(min-width: 1602px){
  footer{
    .footer-haut{
      .liste-footer-haut{
       // margin-left: 0;
      }
    }
  }
}

@media screen and(min-width: 1920px){

  .hero-wrapper{
    background-position-y:center ;


    .boite-hero{
      position: relative;
      height: 100vh;
      .hero{
        div{
          h1{
            margin-top: 375px;
          }
        }
      }
      .cont-fleche{
        top:714px;

        //background-color: red;
      }
      .cont-reseau-d{
        top: 734px;
       // top:80%;
      }
      .tite-ligne{
        top: 612px;
        left: 13%;
      }
    }
  }

  footer{
    .footer-bas{
      .cont-footer-res{
        //margin-left: 7%;
        margin-right: 29%;
      }
    }
  }

  //  display: flex;
  //  justify-content: center;
  //  .footer-haut{
  //    display: flex;
  //    justify-content: center;
  //    p{
  //      //margin-left: auto;
  //     // margin-right: auto;
  //    }
  //
  //    img{
  //      display: flex;
  //      justify-content: center;
  //      //margin-right: 26%;
  //     // margin-left: auto;
  //    }
  //  }
  //
  //  .footer-bas{
  //    .cont-reseau-d{
  //     // left:47.5%;
  //    }
  //    p:last-child{
  //     // margin-right: 2.5%;
  //    }
  //  }
  //}
}