@media screen and(min-width: 360px) {
  .services-hero {
    background-color: black;
    position: relative;

    overflow: hidden;
    .cont-fleche{
      top: 770px;
    }
    div{
      position: relative;
    }

    h1 {
      //margin-top: 40%;
      //margin-left: 10%;
      margin-left: 10%;
      margin-top: 45%;
      text-transform: uppercase;
      font-family: Montserrat;
      font-weight: 700;
      font-size: rem(40px);
      color: white;
    }

    .services-tite-l {
      position: absolute;
      display: inline-block;
      height: 5px;
      left: 10%;
      top: 70px;
      background: #FFDF00;
      animation: tite-ligne 600ms ease-in-out forwards;
    }
    @keyframes tite-ligne {
      0% {
        width: 0px;
      }
      100% {
        width: 138px;
      }
    }
    .cont-reseau-d{
      display: none;
    }

    p {
      color: white;
      font-family: Oxygen;
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(22);
      width: 80%;
      margin: 20% auto;

    }

    .cont-hero-img-services {
      background-image: url("../images/Gestion-Samurai_Services-BG-Rentals.svg");
      height: 251px;
      overflow: hidden;
      margin-left: 10%;
      background-size: cover;

      ul {
        list-style: none;
        line-height: rem(35);
        margin-top: 30px;

        li {
          margin-top: 0;

          span.active {
            color: #FFDF00;
          }
          #condo-span, #comm-span, #rentals-span{
            color: #FFDF00;
          }

          span{
            font-size: rem(25);
          }

          a {
            text-decoration: none;
            text-transform: uppercase;
            color: white;
            font-size: rem(25);
            cursor: pointer;
          }
        }

        li.non-active {
          opacity: 0.5;
        }
      }
    }
  }

  .complete-package{
    margin-top: 70px;
    width: 80%;
    margin-left: 10%;
    h1{
      margin-left: 10%;
      font-family: Montserrat;
      font-weight: 700;
      font-size: 2.25rem;
      line-height: rem(36.57px);
      span{
        color: #FFDF00;
      }
    }
    p{
     // margin-left: 10%;
      font-family: Oxygen;
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(22);
      width: 80%;
      margin: 10% auto;
      margin-bottom: 0;
      margin-top: 0;
    }

  }

  .services-carousel{
    width: 100%;
    margin-top: 80px;
    //display: none;
    .carou{
      display: flex;
      justify-content: center;
      margin: 10px auto;

      .cont-fleche{
        top:700px;
      }
    }
  }
  .cont-fleche {
    position: absolute;
    top: 85%;
    left: 80%;

    .fleche-bas-a {
      position: relative;
      box-sizing: border-box;
      height: 60px;
      width: 48px;

      display: flex;
      //border: 1px solid white;
      padding: 15px 18px;

      img {
        //position: absolute;
        margin-top: 0;
        margin-bottom: 0;
        width: 13px;
        height: 43px;
        z-index: 22;
      }


      //jsute la fleche qui bouge


      .fleche-bas-div {
        position: absolute;
        top: 0;
        left: 0;
        height: 170px;
        width: 100%;

        background-color: #FFDF00;
        clip-path: polygon(0 0, 100% 0, 100% 15%, 0 30%);
        z-index: 2;


      }

      //.fleche-bas-div:hover{
      //  top:0;
      //  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      //}
    }

    .fleche-bas-a {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

      img::after {
        // transform: translateY(-55px);
      }


      img {
        animation: animate 1.8s ease infinite;
        animation-delay: 1.5s;
        @keyframes animate {
          0% {
            transform: translateY(0px);
            opacity: 1;
          }

          50% {
            transform: translateY(60px);
          }

          60% {
            transform: translateY(-55px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
          }

        }

      }


    }
  }
  .services-carousel{
    margin-bottom: -10px;
    .carou {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      background-color: #EAEAEA;
      //box-sizing: border-box;

      .cont-fleche-carou {
        margin-top: 50px;
        margin-left: 60%;

        display: flex;

        img:first-child {
          cursor: pointer;

          transform: rotate(180deg) translateY(-1px);
          margin-right: 5px;
          width: 43px;

        }

        img:last-child {
          cursor: pointer;

          width: 43px;
          margin-left: 5px;

        }


      }

      .greyed {
        opacity: 0.5;
      }

      .hidden {
        display: none;
      }

      .slides {
        position: relative;
        height: fit-content;
        //box-sizing: inherit;
        //clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 0);
        font-family: Montserrat, 'sans-serif';
        font-weight: bold;
        display: flex;
        z-index: 2;
        justify-content: center;
        //overflow: hidden;

        .slide{
          padding-bottom:30px ;

          h1{
            color: #FFDF00;
            margin-left: 15%;
            font-family: Montserrat;
            font-size: rem(40px);
            line-height: rem(48.76px);
            margin-bottom: 0;
          }
          h2{
            text-transform: uppercase;
            margin-left: 15%;
            font-family: Montserrat;
            font-size: rem(20px);
            line-height: rem(24.38px);
            margin-top: 0;
          }

          .cont-bullet{
            width: 80%;
            display: flex;
            //justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            p:first-child{
              font-size: rem(30);
              font-weight: 300;
              margin-right: 5%;
              margin-top: 0;
              height: 35px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
            }
            p:last-child{
              font-family: Oxygen;
              font-weight: 400;
              font-size: rem(14);
              line-height: rem(22);
              margin-bottom: 10px;
              margin-top: 0;
            }

          }
          &:nth-child(2){
            //background-color: red;
            .cont-bullet:nth-child(6){
              //background-color: red;
              //margin-left: 7%;
            }
          }
        }


      }
    }
  }


}
@media screen and(min-width: 768px){
  .mobile{
    width: 100%;
    overflow: hidden;
    position: fixed;
    z-index: 99;
  }
  .services-hero{
    h1{
      margin-top: 155px;
      font-size: 3.75rem;
    }
    .services-tite-l{
      top: 95px;
    }
    p{
      margin-top: 60px;
      margin-bottom: 80px;
    }
    .cont-hero-img-services{
      height: 435px;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      ul{
        padding-left: 8%;
        padding-top: 30px;
        li{
          margin-bottom: 10px;
          span{
            font-size: rem(40);
          }
          a{
            font-size: rem(40);

          }
        }
      }
      li{
        font-size: rem(30);
      }

    }
    .cont-fleche{
top:810px;
    }
  }
  .complete-package{
    p{
     margin-top: 20px;
    }
  }
  .services-carousel{
    .carou{
      .cont-fleche-carou{
        margin-left: 80%;
      }
      .slides{
        justify-content: flex-start;
        height: fit-content;
        // margin-right: auto;
        //margin-left: 10%;
        .cont-fleche-carou{
          margin-left: 78%;
        }
        .slide{
          margin-left: 5%;
          margin-right: auto;
          padding-bottom: 40px;
          &:nth-child(2){
            //background-color: red;
            .cont-bullet:nth-child(6){
              //background-color: red;
              margin-left: 10%;
            }
          }
          .slide-1{
            margin-left: 1%;
          }
          .slide-2{
            margin-left: 5%;
          }
          .slide-3{
            margin-left: 2%;
          }
          .cont-bullet{
            justify-content: flex-start;
            align-items: center;
            margin-left: 10%;
            margin-right: auto;
            margin-bottom: 0;
            line-height: rem(10);
            p{
              margin-right: 20px;
            }
          &:last-child{
margin-left: 10%;
          }
          }
        }


      }
    }

  }


}
@media screen and(min-width: 1024px){
  .menu{
    position: relative;
    .cont-reseau-d {
      position: absolute;
      top: 100%;
      left: 5%;

      a {
        text-decoration: none;
      }

      div {
        border: solid 2px white;
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

      }

      a:first-child {
        display: inline-block;
        margin-bottom: 20px;
        position: relative;

        div {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

        img:first-child, img:first-child::before {
          position: absolute;
          width: 6px;
          height: 14px;
          top: 30%;
          transition: all ease 300ms;
        }

        img:last-child, img:last-child::before {
          position: absolute;
          width: 6px;
          height: 14px;
          bottom: 140%;
          transition: all ease 300ms;
        }


      }

      a:first-child:hover {
        img {
          transform: translateY(35px);
        }

      }

      a:last-child {
        position: relative;

        div {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }


        img:first-child, img:first-child::before {
          position: absolute;
          width: 13px;
          height: 13px;
          top: 175%;
          transition: all ease 300ms;
        }

        img:last-child, img:last-child::before {
          position: absolute;
          width: 13px;
          height: 13px;
          bottom: 100%;
          content: '';
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          transition: all ease 300ms;

        }


      }

      a:last-child:hover {
        img {

          transform: translateY(50px);
        }
      }

    }
  }

  #rentals, #comm, #condo{
    transition: all ease 200ms;
    &:hover{
    transform: translateX(15px);
  }

  }
  .active-li{
    color: white;
    //display: block;
  }

  .mobile{
    display: none;
  }
  .services-hero{
    display: flex;
padding-top: 162px;
    width: 100%;

    .services-tite-l{
      top: 263px;
      width: 20%;
      left: 10%;
    }
    div:first-child{
      width: 50%;
      h1{
        font-size: rem(80);
      }

    }

    .cont-hero-img-services{
background-repeat: no-repeat;
      background-size: cover;

      margin-left: 15%;
      width: 50%;
      height: 75vh;
      ul{
        background-color: rgba(0,0,0,0.2);
        padding-top: 55px;
        padding-left: 10%;
margin-top: 0;
        height: inherit;
        li{
          span{
            color: #FFDF00;
          }
        }
      }
    }
    .cont-reseau-d{
      display: none;
    }
    //.cont-reseau-d {
    //  position: absolute;
    //  top: 625px;
    //  left: 5%;
    //
    //  a {
    //    text-decoration: none;
    //  }
    //
    //  div {
    //    border: solid 2px white;
    //    width: 30px;
    //    height: 30px;
    //    text-align: center;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //
    //  }
    //
    //  a:first-child {
    //    display: inline-block;
    //    margin-bottom: 10px;
    //    position: relative;
    //
    //    div {
    //      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    //    }
    //
    //    img:first-child, img:first-child::before {
    //      position: absolute;
    //      width: 6px;
    //      height: 14px;
    //      top: 30%;
    //      transition: all ease 300ms;
    //    }
    //
    //    img:last-child, img:last-child::before {
    //      position: absolute;
    //      width: 6px;
    //      height: 14px;
    //      bottom: 140%;
    //      transition: all ease 300ms;
    //    }
    //
    //
    //  }
    //
    //  a:first-child:hover {
    //    img {
    //      transform: translateY(35px);
    //    }
    //
    //  }
    //
    //  a:last-child {
    //    position: relative;
    //
    //    div {
    //      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    //    }
    //
    //
    //    img:first-child, img:first-child::before {
    //      position: absolute;
    //      width: 13px;
    //      height: 13px;
    //      top: 175%;
    //      transition: all ease 300ms;
    //    }
    //
    //    img:last-child, img:last-child::before {
    //      position: absolute;
    //      width: 13px;
    //      height: 13px;
    //      bottom: 100%;
    //      content: '';
    //      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    //      transition: all ease 300ms;
    //
    //    }
    //
    //
    //  }
    //
    //  a:last-child:hover {
    //    img {
    //
    //      transform: translateY(50px);
    //    }
    //  }
    //
    //}
  }
  .cont-fleche{
    left: 90%;
    transition: all ease 100ms;
  }

  .complete-package{
display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin-top:80px;
    margin-bottom: 0;
    margin-left: 0;
    div{
      display: flex;
      flex-direction: column;
      //align-items: flex-start;
      justify-content: center;
      margin-left: 15%;

      h1{
        margin-left: 0;
        font-size: rem(60);
        margin-bottom: 20px;
      }
      p{
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 60px;
      }
    }


  }
  .services-carousel{
    .carou{
      .cont-fleche-carou{
        margin-left: 80%;
      }
    }
  }


}


@media screen and(min-width: 1440px){
  .services-hero{
    height: 780px;
    h1{
      margin-top: 60px;
    }
    .cont-hero-img-services{
      height: inherit;
      ul{
        margin-bottom: 0;
      }
    }

    .cont-reseau-d {
      display: block;
      position: absolute;
      top: 625px;
      left: 4.5%;

      a {
        text-decoration: none;
      }

      div {
        border: solid 2px white;
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

      }

      a:first-child {
        display: inline-block;
        margin-bottom: 10px;
        position: relative;

        div {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

        img:first-child, img:first-child::before {
          position: absolute;
          width: 6px;
          height: 14px;
          top: 30%;
          transition: all ease 300ms;
        }

        img:last-child, img:last-child::before {
          position: absolute;
          width: 6px;
          height: 14px;
          bottom: 140%;
          transition: all ease 300ms;
        }


      }

      a:first-child:hover {
        img {
          transform: translateY(35px);
        }

      }

      a:last-child {
        position: relative;

        div {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }


        img:first-child, img:first-child::before {
          position: absolute;
          width: 13px;
          height: 13px;
          top: 22%;
          transition: all ease 300ms;
        }

        img:last-child, img:last-child::before {
          position: absolute;
          width: 13px;
          height: 13px;
          bottom: 220%;
          content: '';
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          transition: all ease 300ms;

        }


      }

      a:last-child:hover {
        img {

          transform: translateY(50px);
        }
      }

    }


    .services-tite-l{
      top:168px;
      left: 9.5%;
    }
  }
  .flex-box{
    display: flex;
    flex-direction: row-reverse;
    height: fit-content;
    margin-bottom: auto;
    .complete-package{
      margin-top: 0;
      div{
        h1{
          margin-right: 1%;
        }
      }
    }
    .services-carousel{
      margin-top: 0;
      .carou{
        margin-top: 0;
       .slides{
         .slide{
           //padding-bottom: 23px;
           .slide-1, .slide-2, .slide-3{
             padding-bottom: 23px;
           }
         }
       }
      }
    }

  }



}