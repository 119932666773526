//FICHIER DE CONFIGURATION POUR LES VARIABLES DE POLICE


/// Grosseur des textes

$fz-normal: rem(18);


/// Grosseur des titres

$fz-h1: rem(36);
$fz-h2: rem(30);
$fz-h3: rem(24);
$fz-h4: rem(24);
$fz-h5: rem(20);
$fz-h6: rem(16);

$fz-nav:rem(18);
$fz-button:rem(24);

$fz-mobilenormal: rem(12);

$fz-mobileh1: rem(36);
$fz-mobileh2: rem(30);
$fz-mobileh3: rem(24);
$fz-mobileh4: rem(18);
$fz-mobileh5: rem(12);
$fz-mobileh6: rem(8);

$fz-mobilep: rem(12);

$fz-mobilenav:rem(24);
$fz-mobilebutton:rem(12);

/// Hauteur de ligne
$lh-extra-thin: 1;
$lh-thin: 1.2;
$lh-normal: 2;
$lh-medium: 1.8;
$lh-large: 2;
$lh-extra-large: 2.4;


/// Font family

$ff-normal: 'Raleway', sans-serif;
$ff-title: 'Oswald', sans-serif;


/// Fonts weight
$fw-thin: 100;
$fw-extra-light: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
$fw-black: 900;




