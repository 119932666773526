//FICHIER DE CONFIGURATION POUR LES ÉLÉMENTS TITRE DE LA PAGE


h1, h2, h3, h4, h5, h6{
  font-family: $ff-title;
  line-height: $lh-thin;

}

h1{
  font-size: $fz-h1;
  text-transform: uppercase;
  font-weight: $fw-bold;
}

h2{
  font-size: $fz-h2;
  font-weight: $fw-bold;
  //text-transform: uppercase;
}

h3{
  font-size: $fz-h3;
  font-weight: $fw-regular;
}

h4{
  font-size: $fz-h4;
}

h5{
  font-size: $fz-h5;
}

h6{
  font-size: $fz-h6;
}
