@media screen and(min-width: 360px) {
  .about-hero {
    color: white;
    background-color: black;
    margin-top: 0;
    position: relative;
    overflow: hidden;

    .cont-reseau-d{
      display: none;
    }

    .cont-gauche-about-hero {
      position: relative;

      h1 {
        display: block;
        font-family: Montserrat;
        margin: 0;
        margin-left: 10%;
        margin-top: 45%;


      }

      .about-tite-l {
        position: absolute;
        display: inline-block;
        height: 5px;
        left: 10%;
        top: 70px;
        animation: tite-ligne 600ms ease-in-out forwards;
        //animation-delay: 300ms;


        background: #FFDF00;
      }
      @keyframes tite-ligne {
        0% {
          width: 0px;
        }
        100% {
          width: 138px;
        }
      }
    }


    p {
      font-family: Oxygen;
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(22);
      width: 80%;
      margin: 20% auto;
      margin-top: 60px;
    }

    .about-img {
      margin-left: 10%;
      width: 100%;
      height: 330px;

      img {
        width: 132%;
        height: auto;
      }
    }


    .cont-fleche {
      position: absolute;
      top: 85%;
      left: 80%;

      .fleche-bas-a {
        position: relative;
        box-sizing: border-box;
        height: 60px;
        width: 48px;

        display: flex;
        //border: 1px solid white;
        padding: 15px 18px;

        img {
          //position: absolute;
          margin-top: 0;
          margin-bottom: 0;
          width: 13px;
          height: 43px;
          z-index: 22;
        }


        //jsute la fleche qui bouge


        .fleche-bas-div {
          position: absolute;
          top: 0;
          left: 0;
          height: 170px;
          width: 100%;

          background-color: #FFDF00;
          clip-path: polygon(0 0, 100% 0, 100% 15%, 0 30%);
          z-index: 2;


        }

        //.fleche-bas-div:hover{
        //  top:0;
        //  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        //}
      }

      .fleche-bas-a {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

        img::after {
          // transform: translateY(-55px);
        }


        img {
          animation: animate 1.8s ease infinite;
          animation-delay: 1.5s;
          @keyframes animate {
            0% {
              transform: translateY(0px);
              opacity: 1;
            }

            50% {
              transform: translateY(60px);
            }

            60% {
              transform: translateY(-55px);
              opacity: 0;
            }
            100% {
              transform: translateY(0);
            }

          }

        }


      }
    }

  }
  .our-team{
    .ourt{
      margin-left: 10%;
    }
  }

  .our-company {
    color: black;

    margin-top: 80px;
    position: relative;
    overflow: hidden;

    h1 {
      display: block;
      font-family: Montserrat;
      margin: 0;
      margin-left: 10%;


      span {
        color: #FFDF00;
      }
    }

    h2 {
      text-transform: uppercase;
      font-weight: 700;
      font-size: rem(14);
      line-height: rem(22);
      font-family: Oxygen;
      margin-left: 10%;

      span {
        color: #FFDF00;
        font-weight: 700;
      }
    }

    p {
      width: 80%;
      margin-left: 10%;
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(22);

    }

    div {
      margin-right: 10%;
      margin-top: 0px;
      //margin-bottom: 60px;

      img {
        margin-top: 40px;
        width: 100%;
       // height: 360px;
        margin-right: 5%;
      }
    }

  }

  .core-values {

    margin-top: 80px;




    h1 {
      margin-left: 5%;
      font-family: Montserrat;
      font-weight: 700;
      font-size: rem(30);
      line-height: rem(36.57px);
      margin-top: 0;

      span {
        color: #FFDF00;
      }
    }

    p {
      margin-left: 5%;
      width: 80%;
      font-family: Oxygen;
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(22);
      margin-bottom: 80px;
    }
    h1:first-child{
      margin-left: 10%;
    }

    .p-p{
      margin-left: 10%;
      //background-color: red;
    }


    .swiper {
      width: 80%;

      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #EAEAEA;
        height: 250px;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        flex-direction: column;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        &:nth-child(1){
          //h2{
          //  margin-right: 28%;
          //}
        }
        &:nth-child(2){
          h2{
            //margin-right: 20%;
            margin-top: 70px;
          }
          .fr-2-about{
            margin-top: 47px;
          }
        }
        &:nth-child(3){
          h2{
            margin-top: 27px;
            //margin-right: 33%;
          }
        }
        &:nth-child(4){
          h2{
            margin-top: 70px;
            //margin-right: 22%;
            //background-color: red;
          }
        }
        &:nth-child(5){
          h2{
            //background-color: red;
           // margin-right: 0%;
            margin-left: 12%;
          }
        }
        &:nth-child(6){
          h2{
            margin-top: 25px;
            //margin-right: 52%;
          }
          .fun{
            //margin-right: 35%;
          }
          .fr-6-about{
            margin-top: 15px;
          }
        }


        h2{
          //background-color: red;
          text-align: left;
          text-transform: uppercase;
          margin-right: auto;
          margin-left: 12%;
          font-family: Montserrat;
          margin-bottom: 0;
          margin-top: 45px;
        }
        p{
          text-align: left;
        }
      }

      .swiper-pagination {
       // position: absolute;
        //top:70%;
      }


    }

    .cont-swiper-core {
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      margin-top: 30px;
      //margin-left: 10%;
      //color: #FFDF00;
      .cont-button-core{
        display: flex;
        flex-direction: row-reverse;
        margin-right: 10%;
        .swiper-button-next-core, .swiper-button-prev-core {
          width: 50px;
          height: 36px;
          cursor: pointer;
          //  background-color: red;
          //margin-left: auto;
          img {
            width: 50px;
            height: 14px;
          }
        }

        .swiper-button-prev-core {
          //margin-left: auto;
          img {
            transform: rotate(180deg) translateY(-1px);
          }
        }

        .swiper-button-next-core {
          margin-left: 10px;
        }
      }

      .swiper-pagination-core{
        top:40%;
        width: 47%;
        left: 10%;
        span{
          background: #FFDF00;
        }
      }
    }


    .cont-controls-values {
      margin-top: 5%;
      height: 36px;
      display: flex;
      justify-content: space-evenly;
    }

    .cont-tracker-values {

      width: 30%;
      height: 6px;
      background-color: #c4c4c4;
      margin-top: 4%;

      .tracker-values {
        width: 15%;
        border: 3px solid #FFDF00;
        background-color: #FFDF00;
      }
    }








    .cont-arrow-values {

      //margin-left: 60%;
      margin-right: 0;

      img {
        width: 50px;
        height: 14px;
      }

      img:first-child {
        transform: rotate(180deg) translateY(-16px);
      }
    }
  }

  .our-team {
    margin-top: 80px;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;

    h1 {
      margin-left: 10%;
      font-family: Montserrat;
      font-weight: 700;
      font-size: rem(30);
      line-height: rem(36.57px);
      margin-bottom: 40px;

      span {
        color: #FFDF00;
      }

    }
    .swiper2 {
      width: 80%;
      //overflow: hidden;
      .swiper-wrapper{
        //overflow: hidden;
        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;

          /* Center slide text vertically */
          //display: -webkit-box;
          //display: -ms-flexbox;
          //display: -webkit-flex;
          display: flex;
          //-webkit-box-pack: center;
          //-ms-flex-pack: center;
          flex-direction: column;
          //-webkit-justify-content: center;
          justify-content: flex-end;
          ///-webkit-box-align: center;
          //-ms-flex-align: center;
          //-webkit-align-items: center;
          align-items: center;
          height: 358px;
          .cont-nom {
            width: 100%;
            background: rgba(0, 0, 0, 0.8);
            color: white;
            //padding: 5%;
            padding-top: 5px;
            padding-bottom: 10px;

            p {
              margin-left: auto;
              text-align: left;
              padding-left: 5%;

            }

            p:first-child {
              font-family: Oxygen;
              text-transform: uppercase;
              font-weight: 700;
              font-size: rem(18);
              color: #FFDF00;
              margin-bottom: 0;
              //padding-right: 5%;
            }

            p:last-child {
              font-family: Oxygen;
              font-size: rem(14);
              text-transform: uppercase;
              margin-top: 0;
            }
          }
        }
        .swiper-slide:nth-child(1){
          background-image: url("../images/Gestion-Samurai_F1.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .swiper-slide:nth-child(2) {
          background-image: url("../images/Gestion-Samurai_F2.jpg");
          background-repeat: no-repeat;
        background-size: cover;
        }
        .swiper-slide:nth-child(3){
          background-image: url("../images/Gestion-Samurai_F3.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .swiper-slide:nth-child(4){
          background-image: url("../images/Gestion-Samurai_F4.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .swiper-slide:nth-child(5){
          background-image: url("../images/Gestion-Samurai_F5.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .swiper-slide:nth-child(6){
          background-image: url("../images/Gestion-Samurai_F6.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .swiper-slide:nth-child(7){
          background-image: url("../images/Gestion-Samurai_F7.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }
        .swiper-slide:nth-child(8){
          background-image: url("../images/Gestion-Samurai_F8.jpg");
          background-repeat: no-repeat;
          background-size: cover;
        }
      }



      .swiper-pagination {
        // position: absolute;
        //top:70%;
      }


    }

    .cont-swiper-team {
      display: flex;
      flex-direction: row-reverse;
      position: relative;
      //margin-left: 10%;
      //color: #FFDF00;
      width: 95%;
      margin-top: 30px;
      .cont-button-team{
        display: flex;
        flex-direction: row-reverse;
        margin-right: 5%;
        .swiper-button-next-team, .swiper-button-prev-team {
          width: 50px;
          height: 36px;
          cursor: pointer;
          //  background-color: red;
          //margin-left: auto;
          img {
            width: 50px;
            height: 14px;
          }
        }

        .swiper-button-prev-team {
          //margin-left: auto;
          img {
            transform: rotate(180deg) translateY(-1px);

          }
        }

        .swiper-button-next-team {
          margin-left: 10px;
        }
      }

      .swiper-pagination-team{
        top:40%;
        width: 50%;
        left: 10%;
        span{
          background: #FFDF00;
        }
      }
    }
    .carousel-team {
      //overflow: hidden;
      .slide {
        margin-left: auto;
        margin-right: auto;
        background-color: #e8e8e9;
        //padding: 5%;
        width: 80%;
        height: 358px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;




      }

      .slide:nth-child(2) {
        display: none;
      }

      .slide:nth-child(3) {
        display: none;
      }

      .slide1 {
        background-image: url("../images/mand-holding-cup-détouré 1.png");
        background-position: top;
        background-size: cover;
      }


    }

    .cont-controls-team {
      margin-top: 5%;
      height: 36px;
      display: flex;
      justify-content: space-evenly;
    }

    .cont-tracker-team {

      width: 30%;
      height: 6px;
      background-color: #c4c4c4;
      margin-top: 4%;

      .tracker-team {
        width: 15%;
        border: 3px solid #FFDF00;
        background-color: #FFDF00;
      }
    }

    .cont-arrow-team {

      //margin-left: 60%;
      margin-right: 0;

      img {
        width: 50px;
        height: 14px;
      }

      img:first-child {
        transform: rotate(180deg);
      }
    }


  }


}

@media screen and(min-width: 768px) {

  .mobile {
    width: 100%;
    overflow: hidden;
    position: fixed;
    z-index: 99;

    nav {
      background-color: black;

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        height: 110px;


        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
overflow: hidden;
        li:first-child {
          img {
            width: 105px;
            height: 105px;
            margin-left: 5%;

          }
        }

        li {
          margin: 10% 10px;
          color: white;
          font-family: Montserrat, 'sans-serif';
          font-weight: 500;
          font-size: rem(15);

          a {

            text-decoration: none;
            color: white;
          }

          .ham-cont {
            border: 2px solid #FFFFFF;
            box-sizing: border-box;
            display: flex;
            height: 50px;
            width: 50px;
            margin-left: -30%;
            //margin-right: 30%;

            .actual-ham {
              display: flex;
              flex-direction: column;
              box-sizing: border-box;
              margin-left: 10%;
              margin-top: 30%;


              //les pains du hamburger
              span {
                display: inline-block;
                border: 1px solid #FFDF00;
                box-sizing: border-box;
                width: 30px;
                height: 2px;
                background-color: #FFDF00;
                margin: 3.5px 4px;
              }

              .t-off1 {
                animation: t-off1 500ms ease forwards;
              }

              @keyframes t-off1 {
                0% {
                  transform: translateY(5px) rotate(135deg);

                }
                100% {
                  transform: rotate(0deg);
                }
              }

              .t-off2 {
                animation: t-off2 500ms ease forwards;
              }

              @keyframes t-off2 {
                0% {
                  transform: translateY(-5px) rotate(-135deg);

                }
                100% {
                  transform: rotate(0deg);

                }
              }

              .t-on1 {

                animation: t-on1 300ms ease forwards;
              }

              @keyframes t-on1 {
                0% {
                  transform: rotate(0deg);

                }
                100% {
                  transform: translateY(5px) rotate(135deg);

                }
              }

              .t-on2 {
                animation: t-on2 300ms ease forwards;
              }

              @keyframes t-on2 {
                0% {
                  transform: rotate(0deg);

                }
                100% {
                  transform: translateY(-5px) rotate(-135deg);

                }
              }

            }
          }

        }

      }
    }


  }

  .about-hero {
    p{
      margin-left: auto;
      margin-right: auto;
      margin-top: 60px;
      margin-bottom: 70px;
    }
    .cont-gauche-about-hero {
      h1 {
        font-size: 3.75rem;

        margin-top: 20%;
      }
      .about-tite-l{
        top: 95px;
      }
    }
  }

  .our-company{
    margin-top: 80px;
    div:first-child{
      margin-top: 0;
      h1{
        font-size: 3.125rem;

      }
    }
    div:last-child{
      margin-top: 0;
      img{
        margin-top: 80px;
      }
    }
  }
  .core-values{
    h1{
      font-size: 3.125rem;
    }
    .swiper{

      .swiper-slide:nth-child(1){
        h2{
          //margin-right: 52%;
        }
      }
      .swiper-slide:nth-child(2){
        h2{
          //margin-right: 48%;
          margin-top: 45px;

        }

      }
      .swiper-slide:nth-child(3){
        h2{
          //margin-right: 55%;
          margin-top: 27px;

        }
        .fr-3-about{
          margin-top: 50px;
        }

      }
      .swiper-slide:nth-child(4){
        h2{
          //margin-right: 49%;
margin-top: 45px;
        }

      }

      .swiper-slide:nth-child(5){
        h2{
         // margin-right: 41%;
          margin-top: 45px;

        }
        //.fr-5-about{
        //  //margin-right: 27%;
        //}

      }
      .swiper-slide:nth-child(6){
        h2{
        //  margin-right: 65%;
          margin-top: 30px;

        }

        .fr-6-about{
          margin-top: 35px;
        }



      }

      //.swiper-slide:nth-child(6){
      //  h2{
      // //   margin-right: 73%;
      //
      //  }
      //  .fun{
      //    margin-right: 55%;
      //  }
      //
      //}
      //.swiper-slide{
      //  h2{
      //    margin-right: 53%;
      //  }
      //}
    }
    .cont-swiper-core{
      .swiper-pagination-core{
        width: 61%;
      }
    }
  }
  .our-team{
    h1{
      margin-left: 10%;
      font-size: 3.125rem;
    }
    .swiper2{
      .swiper-wrapper{
        .swiper-slide:nth-child(2n-1), .swiper-slide:nth-child(2n){
          background-color: #e4e4e4;
          background-size: contain;
          background-position-x: center;
        }


        .swiper-slide:nth-child(2){
          background-image: url("../images/Gestion-Samurai_F2-detoure.png");
        }
        .swiper-slide:nth-child(3){
          background-image: url("../images/Gestion-Samurai_F3-detoure.png");
        }
        .swiper-slide:nth-child(4){
          background-image: url("../images/Gestion-Samurai_F4-detoure.png");
        }
        .swiper-slide:nth-child(5){
          background-image: url("../images/Gestion-Samurai_F5-detoure.png");
        }
        .swiper-slide:nth-child(6){
          background-image: url("../images/Gestion-Samurai_F6-detoure.png");
        }
        .swiper-slide:nth-child(7){
          background-image: url("../images/Gestion-Samurai_F7-detoure.png");
        }
        .swiper-slide:nth-child(8){
          background-image: url("../images/Gestion-Samurai_F8-detoure.png");
        }

      }


      //.swiper-slide:nth-child(1),
      //.swiper-slide:nth-child(2),
      //.swiper-slide:nth-child(3),
      //.swiper-slide:nth-child(4),
      //.swiper-slide:nth-child(5),
      //.swiper-slide:nth-child(6),
      //.swiper-slide:nth-child(7),
      //.swiper-slide:nth-child(8){
      //  background-size: contain;
      //}


      }
    .cont-swiper-team{
      .swiper-pagination-team{
        width: 65%;
      }
    }
    }

  }



@media screen and(min-width: 1024px) {


  .menu {
    .bg-menu {
      ul {
        position: relative;
      }

      .cont-reseau-d {
        position: absolute;
        display: flex;
        justify-content: space-between;
        top: 20%;
        left: 34%;

        a {
          text-decoration: none;
        }

        div {
          border: solid 2px white;
          width: 30px;
          height: 30px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;

        }

        a:first-child {
          display: inline-block;
          margin-bottom: 20px;
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }

          img:first-child, img:first-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            top: 30%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 6px;
            height: 14px;
            bottom: 140%;
            transition: all ease 300ms;
          }


        }

        a:first-child:hover {
          img {
            transform: translateY(35px);
          }

        }

        a:last-child {
          position: relative;

          div {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          }


          img:first-child, img:first-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            top: 15%;
            transition: all ease 300ms;
          }

          img:last-child, img:last-child::before {
            position: absolute;
            width: 13px;
            height: 13px;
            bottom: 150%;
            content: '';
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            transition: all ease 300ms;

          }


        }

        a:last-child:hover {
          img {

            transform: translateY(45px);
          }
        }

      }
    }
  }
  .mobile {
    display: none;
  }
  .about-hero {
    display: flex;
    background-color: black;
    color: white;
    height: 600px;
    .cont-reseau-d {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      top: 470px;
      left: 3%;

      a {
        text-decoration: none;
      }

      div {
        border: solid 2px white;
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;

      }

      a:first-child {
        display: inline-block;
        margin-bottom: 20px;
        position: relative;

        div {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

        img:first-child, img:first-child::before {
          position: absolute;
          width: 6px;
          height: 14px;
          top: 30%;
          transition: all ease 300ms;
        }

        img:last-child, img:last-child::before {
          position: absolute;
          width: 6px;
          height: 14px;
          bottom: 140%;
          transition: all ease 300ms;
        }


      }

      a:first-child:hover {
        img {
          transform: translateY(35px);
        }

      }

      a:last-child {
        position: relative;

        div {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }


        img:first-child, img:first-child::before {
          position: absolute;
          width: 13px;
          height: 13px;
          top: 24%;
          transition: all ease 300ms;
        }

        img:last-child, img:last-child::before {
          position: absolute;
          width: 13px;
          height: 13px;
          bottom: 150%;
          content: '';
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          transition: all ease 300ms;

        }


      }

      a:last-child:hover {
        img {

          transform: translateY(35px);
        }
      }

    }

    .cont-gauche-about-hero {
      display: flex;
      flex-direction: column;
      position: relative;


      h1 {
        font-family: Montserrat;
        font-size: rem(70);
        margin-top: 200px;
      }

      .about-tite-l {
        top:305px;
      }
    }

    .about-img {
      img {
        height: 700px;
        width: 100%;
        margin-top: 175px;
        //height: 75vh;
      }
    }



    .cont-fleche {
      left: 90%;
    }
  }
  .our-company {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-content: center;
    margin: 10% auto;
    margin-top: 0;

    div {
      margin: 0 auto;
      width: 40%;

    }

    div:first-child{
      margin-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h1{
        margin-top: 40px;
      }
    }
    div:last-child{
      img{
        margin-top: 0;
      }

    }
  }
  .core-values {
//    .swiper{
//      .swiper-slide:nth-child(1){
//        h2{
//          margin-right: 58%;
//        }
//      }
//      .swiper-slide:nth-child(2){
//        h2{
//margin-right: 55%;
//        }
//      }
//      .swiper-slide:nth-child(3){
//        h2{
//margin-right: 59%;
//        }
//        .fr-3-about{
//          margin-top: 30px;
//        }
//      }
//      .swiper-slide:nth-child(4){
//        h2{
//          margin-right: 56%;
//
//        }
//      }
//      .swiper-slide:nth-child(5){
//        h2{
//          margin-right: 53%;
//
//        }
//        .fr-5-about{
//          margin-right: 42%;
//        }
//      }
//      .swiper-slide:nth-child(6){
//        h2{
//          margin-right: 68%;
//
//        }
//        .fr-6-about{
//          margin-right: 60%;
//        }
//      }
//    }
    .carousel-values {
      justify-content: space-evenly;

      .slide {
        padding: 0;
        width: 30%;
      }

      .slide:nth-child(2) {
        display: flex;
      }

      .slide:nth-child(3) {
        display: flex;
      }
    }

    .cont-controls-values {
      align-items: center;
      margin-bottom: 10%;

      .cont-tracker-values {
        margin-top: 0;
      }

    }
  }


  .our-team {
    .carousel-team {
      display: flex;
      position: relative;

      .slide {
        padding: 0;
        width: 30%;
      }

      .slide:nth-child(2) {
        display: flex;
      }

      .slide:nth-child(3) {
        display: flex;
      }

    }

    .cont-controls-team {
      margin-top: 5%;
      margin-bottom: 10%;
      height: 36px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .cont-tracker-team {
        width: 30%;
        height: 6px;
        background-color: #c4c4c4;
        margin-top: 0%;

        .tracker-team {
          width: 15%;
          border: 3px solid #FFDF00;
          background-color: #FFDF00;
        }

      }
    }
  }
}

@media screen and(min-width: 1440px){
  .about-hero{
    height: 700px;
    .about-img{
      img{
        height: auto;
        width: 125%;
      }


    }

    .cont-gauche-about-hero{
      margin-left: 3%;

    }
    .cont-reseau-d{
      top:550px;
      left: 5.5%;
    }
  }
  .our-team{
    .ourt{
      margin-left: 7%;
    }
  }

  .our-company{


    div:first-child{
      h1{
        margin-top: 90px;
        font-size: rem(60);
      }
      h2{
        font-size: rem(18);
      }
    }
  }

  .core-values{
    h1:first-child{
      font-size: rem(60);
      margin-left: 7%;
    }
    .p-p{
      margin-left: 7%;
    }

    .swiper{
      margin-left: 7%;
      .swiper-slide{
        margin-right: 15px;
        h2{
          margin-left: 12%;
        }
      }

      .swiper-slide:nth-child(3){
        .fr-3-about{
          margin-top: 25px;
        }
      }
      .swiper-slide:nth-child(4){
        .fr-4-about{
          margin-top: 70px;
        }
      }
      .swiper-slide:nth-child(5){
        h2{
          margin-top: 25px;
        }
      }

    }

    //.swiper{
    //
    //  .swiper-slide{
    //
    //  }
    //
    //  .swiper-slide:nth-child(1){
    //    h2{
    //      margin-right: 48%;
    //    }
    //  }
    //  .swiper-slide:nth-child(2){
    //    h2{
    //      margin-right: 42%;
    //    }
    //  }
    //  .swiper-slide:nth-child(3){
    //    h2{
    //      margin-right: 52%;
    //      margin-top: 28px;
    //    }
    //  }
    //  .swiper-slide:nth-child(4){
    //    h2{
    //      margin-right: 44%;
    //      margin-top:61px ;
    //
    //    }
    //  }
    //  .swiper-slide:nth-child(5){
    //    h2{
    //      margin-right: 37%;
    //      margin-top: 64px;
    //
    //    }
    //  }
    //  .swiper-slide:nth-child(6){
    //    h2{
    //      margin-right: 69%;
    //
    //    }
    //    .fun{
    //      margin-right: 54%;
    //    }
    //  }
    //}

    .cont-swiper-core{
      margin-left: -3%;
      margin-right: 3%;
      .swiper-pagination-core{
        width: 70%;
      }
    }


  }
  .our-team{
    h1{
      margin-left: 7%;
    }
    .swiper2{
      margin-left: 7%;
      .swiper-wrapper{
        .swiper-slide{
          margin-right: 15px;
          height:450px;
        }
        .swiper-slide{
          .cont-nom{
            height: 0;
            padding: 0;
            transition: all ease 300ms;
          }

      }
        .swiper-slide:nth-child(1):hover{

         .cont-nom{
           height: 96px;
         }
       }
        .swiper-slide:nth-child(2):hover{

          .cont-nom{
            height: 96px;
          }
        }
        .swiper-slide:nth-child(3):hover{

          .cont-nom{
            height: 96px;
          }
        }
        .swiper-slide:nth-child(4):hover{

          .cont-nom{
            height: 96px;
          }
        }
        .swiper-slide:nth-child(5):hover{

          .cont-nom{
            height: 96px;
          }
        }
        .swiper-slide:nth-child(6):hover{

          .cont-nom{
            height: 96px;
          }
        }
        .swiper-slide:nth-child(7):hover{

          .cont-nom{
            height: 96px;
          }
        }
        .swiper-slide:nth-child(8):hover{

          .cont-nom{
            height: 96px;

            //padding-right: 35px;
          }
        }
    }
  }
    .cont-swiper-team{
      margin-left: -2.5%;
      //margin-right: 3%;
      .swiper-pagination-team{
        width: 74.5%;

      }
    }
}
}


@media screen and(min-width: 1600px){
  .core-values{
    .swiper{
      .swiper-slide:nth-child(5){
        h2{
          margin-top: 25px;
        }
        .fr-5-about{
          margin-right: 5%;
        }
      }

      .swiper-slide:nth-child(4){
        .fr-4-about{
          margin-top: 50px;
        }
      }
    }
  }
}
@media screen and (min-width: 1920px){
  .about-hero{
    height: 700px;
  }
  .core-values{
    .swiper{
      .swiper-slide:nth-child(1){
        h2{
          margin-right: 58%;
        }
      }
      .swiper-slide:nth-child(2){
        h2{
          margin-right: 53%;
        }
      }
      .swiper-slide:nth-child(3){
        h2{
          margin-right: 61%;
         // background-color: red;
        }
      }
      .swiper-slide:nth-child(4){
        h2{
          margin-right: 54%;
          margin-top: 50px;

        }
      }
      .swiper-slide:nth-child(5){
        h2{
          //margin-right: 42%;

        }
        .fr-5-about{
          margin-right: auto;
        }
      }
      .swiper-slide:nth-child(6){
        h2{
          margin-right: 73%;
          margin-top: 0;

        }
        .fun{
          margin-right: 61%;
        }
      }
    }
  }


}