@charset "UTF-8";
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "Raleway", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  color: #282828;
  line-height: 2;
  background: #FFFDFD;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Oswald", sans-serif;
  line-height: 1.2;
}

h1 {
  font-size: 2.25rem;
  text-transform: uppercase;
  font-weight: 700;
}

h2 {
  font-size: 1.875rem;
  font-weight: 700;
}

h3 {
  font-size: 1.5rem;
  font-weight: 400;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

hr {
  border: 0;
  height: 15px;
  background: #25E6E5;
  color: #25E6E5;
  margin-top: 0;
  width: 15%;
}

hr.hr-thin {
  height: 1px;
}

hr.hr-large {
  height: 8px;
}

img {
  width: 100%;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

button, .gform_button {
  margin: 80px 25px;
  padding: 20px;
  border: none;
  background-color: #282828;
  color: #FFFDFD;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  position: relative;
  width: 250px;
}

button::before {
  content: "";
  background-image: url("../images/cercle.png");
  position: absolute;
  width: 161px;
  height: 162px;
  z-index: -1;
  right: 45%;
  top: -70%;
}

button::after {
  content: "";
  background-image: url("../images/triangle.png");
  position: absolute;
  width: 85px;
  height: 93px;
  z-index: 1;
  bottom: 100%;
  right: -40%;
  transition: 1s;
}

button:hover::after {
  border: none;
  bottom: 60%;
  right: -20%;
  opacity: 1;
}

button:hover::after a {
  color: #25E6E5;
}

.lesboutons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
}

.lesboutons .unbouton {
  width: 45%;
}

.centrer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.centrer button {
  width: 300px;
}

.centrer button::before {
  right: 55%;
  top: -70%;
}

.centrer button::after {
  bottom: 100%;
  right: -30%;
}

.centrer button:hover::after {
  bottom: 60%;
  right: -15%;
}

@media screen and (max-width: 375px) {
  button::before {
    background-size: 45%;
    background-repeat: no-repeat;
    right: -20%;
    top: -50%;
  }
  button, .gform_button {
    font-size: 0.75rem;
    margin: 30px 25px;
    padding: 10px;
    font-weight: 700;
    width: 125px;
  }
  button::after {
    background-size: 35%;
    background-repeat: no-repeat;
    bottom: -40%;
    right: -100%;
  }
  button:hover::after {
    bottom: -120%;
    right: -55%;
  }
  .lesboutons {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }
  .lesboutons .unbouton {
    width: 45%;
  }
  .lesboutons button::after {
    background-size: 35%;
    background-repeat: no-repeat;
    bottom: -40%;
    right: -50%;
  }
  .lesboutons button:hover::after {
    bottom: -120%;
    right: -55%;
  }
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 0;
  padding: 25px;
  height: 100px;
  background-color: #282828;
  color: #FFFDFD;
}

footer p {
  font-size: 1rem;
}

.hidden {
  display: none;
}

.opa0 {
  opacity: 0;
}

.bouge-pu {
  position: absolute;
  color: #FFDF00;
  top: 7px;
  left: 10px;
}

.slide-toggle {
  z-index: 3;
}

.add-r {
  position: relative;
}

.re-add {
  position: fixed;
}

p, li {
  font-family: 'Oxygen', sans-serif;
}

@media screen and (min-width: 360px) {
  .intro {
    display: none;
  }
  .menu-scroll {
    display: none;
  }
  .menu .bg-menu ul {
    position: relative;
  }
  .menu .bg-menu .cont-reseau-d {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 20%;
    left: 34%;
  }
  .menu .bg-menu .cont-reseau-d a {
    text-decoration: none;
  }
  .menu .bg-menu .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .menu .bg-menu .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
  }
  .menu .bg-menu .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .menu .bg-menu .cont-reseau-d a:first-child img:first-child, .menu .bg-menu .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:first-child img:last-child, .menu .bg-menu .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  .menu .bg-menu .cont-reseau-d a:last-child {
    position: relative;
  }
  .menu .bg-menu .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .menu .bg-menu .cont-reseau-d a:last-child img:first-child, .menu .bg-menu .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 15%;
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:last-child img:last-child, .menu .bg-menu .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 150%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:last-child:hover img {
    transform: translateY(45px);
  }
  .mobile {
    width: 100%;
    overflow: hidden;
    position: fixed;
    z-index: 99;
  }
  .mobile nav {
    background-color: black;
  }
  .mobile nav ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    height: 110px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .mobile nav ul li:first-child {
    width: 105px;
  }
  .mobile nav ul li:first-child img {
    width: 100%;
    height: 50%;
    margin-left: 5%;
  }
  .mobile nav ul li {
    margin: 10% 10px;
    color: white;
    font-family: Montserrat, 'sans-serif';
    font-weight: 500;
    font-size: 0.9375rem;
  }
  .mobile nav ul li a {
    text-decoration: none;
    color: white;
  }
  .mobile nav ul li .ham-cont {
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    width: 50px;
    margin-left: -30%;
  }
  .mobile nav ul li .ham-cont .actual-ham {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-left: 10%;
    margin-top: 30%;
  }
  .mobile nav ul li .ham-cont .actual-ham span {
    display: inline-block;
    border: 1px solid #FFDF00;
    box-sizing: border-box;
    width: 30px;
    height: 2px;
    background-color: #FFDF00;
    margin: 3.5px 4px;
  }
  .mobile nav ul li .ham-cont .actual-ham .t-off1 {
    animation: t-off1 500ms ease forwards;
  }
  @keyframes t-off1 {
    0% {
      transform: translateY(5px) rotate(135deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .mobile nav ul li .ham-cont .actual-ham .t-off2 {
    animation: t-off2 500ms ease forwards;
  }
  @keyframes t-off2 {
    0% {
      transform: translateY(-5px) rotate(-135deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .mobile nav ul li .ham-cont .actual-ham .t-on1 {
    animation: t-on1 300ms ease forwards;
  }
  @keyframes t-on1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: translateY(5px) rotate(135deg);
    }
  }
  .mobile nav ul li .ham-cont .actual-ham .t-on2 {
    animation: t-on2 300ms ease forwards;
  }
  @keyframes t-on2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: translateY(-5px) rotate(-135deg);
    }
  }
  @keyframes tite-ligne {
    0% {
      width: 0px;
    }
    100% {
      width: 138px;
    }
  }
  .hero-wrapper {
    background-image: url("../images/michael-descharles-UzV2VrbEOC8-unsplash 1.png");
    background-size: cover;
    background-position-y: center;
    background-position-x: center;
    margin-top: 0;
  }
  .hero-wrapper .boite-hero {
    display: flex;
    height: 690px;
    position: relative;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  }
  .hero-wrapper .boite-hero .col-noire {
    background-color: black;
    width: 25%;
    height: inherit;
  }
  .hero-wrapper .boite-hero .cont-reseau-d {
    display: none;
  }
  .hero-wrapper .boite-hero .hero {
    margin-top: 60px;
  }
  .hero-wrapper .boite-hero .hero div {
    margin-top: 45%;
  }
  .hero-wrapper .boite-hero .hero div h1 {
    width: 99%;
    position: relative;
    left: -10%;
    color: white;
    font-family: Montserrat;
    font-size: 2.5rem;
  }
  .hero-wrapper .boite-hero .tite-ligne {
    position: absolute;
    display: inline-block;
    height: 5px;
    left: 10%;
    top: 420px;
    animation: tite-ligne 600ms ease-in-out forwards;
    background: #FFDF00;
  }
  .hero-wrapper .boite-hero .cont-fleche {
    position: absolute;
    top: 550px;
    left: 80%;
  }
  .hero-wrapper .boite-hero .cont-fleche .fleche-bas-a {
    position: relative;
    box-sizing: border-box;
    height: 60px;
    width: 48px;
    display: flex;
    padding: 15px 18px;
  }
  .hero-wrapper .boite-hero .cont-fleche .fleche-bas-a img {
    margin-top: 0;
    margin-bottom: 0;
    width: 13px;
    height: 43px;
    z-index: 22;
  }
  .hero-wrapper .boite-hero .cont-fleche .fleche-bas-a .fleche-bas-div {
    position: absolute;
    top: 0;
    left: 0;
    height: 170px;
    width: 100%;
    background-color: #FFDF00;
    clip-path: polygon(0 0, 100% 0, 100% 15%, 0 30%);
    z-index: 2;
  }
  .hero-wrapper .boite-hero .cont-fleche .fleche-bas-a {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .hero-wrapper .boite-hero .cont-fleche .fleche-bas-a img {
    animation: animate 1.8s ease infinite;
    animation-delay: 1.5s;
  }
  @keyframes animate {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    50% {
      transform: translateY(60px);
    }
    60% {
      transform: translateY(-55px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
    }
  }
  .carousel {
    display: flex;
    flex-direction: column-reverse;
  }
  .carousel .partie-droite {
    width: 100%;
    margin: 5% auto;
  }
  .carousel .partie-droite h1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-family: Montserrat;
  }
  .carousel .partie-droite h1 br {
    display: none;
  }
  .carousel .partie-droite h1 span {
    color: #FFDF00;
  }
  .carousel .partie-droite p {
    width: 80%;
    font-size: 1rem;
    margin: 5% auto;
    line-height: 1.375rem;
    font-weight: 600;
  }
  .carousel a {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    z-index: 55;
    margin-left: 10%;
    margin-top: 15%;
    padding-left: 2%;
    font-weight: bold;
    font-family: Oxygen;
    font-size: 0.9375rem;
  }
  .carousel a div {
    background-color: #FFDF00;
    position: absolute;
    width: 164px;
    height: 60px;
    left: -2%;
    clip-path: polygon(0 0, 60% 0, 40% 100%, 0 100%);
    z-index: -1;
  }
  .carousel a img {
    margin-left: 1%;
    width: auto;
    height: 12px;
    z-index: 55;
  }
  .carousel .carou {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #EAEAEA;
    margin-top: 90px;
    margin-left: 0;
  }
  .carousel .carou .cont-fleche-carou {
    margin-top: 10%;
    margin-left: 60%;
    display: flex;
  }
  .carousel .carou .cont-fleche-carou img:first-child {
    cursor: pointer;
    transform: rotate(180deg) translateY(-1px);
    margin-right: 5px;
    width: 43px;
  }
  .carousel .carou .cont-fleche-carou img:last-child {
    cursor: pointer;
    width: 43px;
    margin-left: 5px;
  }
  .carousel .carou .greyed {
    opacity: 0.5;
  }
  .carousel .carou .slides {
    position: relative;
    height: 455px;
    font-family: Montserrat, 'sans-serif';
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .carousel .carou .slides .slide {
    position: relative;
    width: 100%;
    height: 75%;
    left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel .carou .slides .slide h1 {
    color: #FFDF00;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 2.5rem;
    margin-bottom: 0px;
  }
  .carousel .carou .slides .slide h2 {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 1.5625rem;
    width: 80%;
    margin-top: 0;
  }
  .carousel .carou .slides .slide:nth-child(2) {
    left: 6%;
  }
  .carousel .carou .slides #fr-2 {
    left: 2%;
  }
  .carousel .carou .slides div:nth-child(2), .carousel .carou .slides div:nth-child(2)::before {
    top: 50%;
    transition: all ease 300ms;
  }
  .carousel .carou .slides div:nth-child(3), .carousel .carou .slides div:nth-child(3)::before {
    top: 80%;
    transition: all ease 300ms;
  }
  .bandeau {
    display: none;
  }
  .bandeau-mobile {
    display: flex;
    flex-direction: column;
    height: 728px;
    width: 100%;
    background-image: url("../images/Gestion-Samurai_Home-BG-2.jpg");
    background-attachment: fixed;
    background-position: top;
  }
  .bandeau-mobile .filtre {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: inherit;
    padding-bottom: 10px;
    padding-top: 20px;
  }
  .bandeau-mobile .filtre .chiffre {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
  }
  .bandeau-mobile .filtre .chiffre span:first-child {
    display: inline-block;
    height: 65px;
    color: #FFDF00;
    font-size: 3.125rem;
    font-weight: 300;
    font-family: Montserrat;
    margin-bottom: 0;
  }
  .bandeau-mobile .filtre .chiffre span:last-child {
    display: flex;
    color: white;
    align-items: flex-start;
    text-align: left;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0;
    font-family: Oxygen;
    font-weight: 400;
    font-size: 1.25rem;
  }
  .work-w-us {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;
  }
  .work-w-us .work-w-us-text {
    width: 80%;
  }
  .work-w-us .work-w-us-text h1 {
    font-size: 1.875rem;
    font-weight: 700;
    font-family: Montserrat;
    color: #000;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .work-w-us .work-w-us-text p {
    width: 80%;
    font-size: 1rem;
    font-family: Oxygen, 'sans-serif';
    font-weight: 400;
    line-height: 1.375rem;
    margin-bottom: 60px;
  }
  .work-w-us .work-w-us-text span {
    font-size: inherit;
    color: #FFDF00;
  }
  .work-w-us .work-w-us-text a {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    z-index: 55;
    margin-left: 2%;
    margin-top: 10%;
    padding-left: 4%;
    font-weight: 700;
    font-family: Oxygen;
    font-size: 0.9375rem;
    font-style: normal;
    letter-spacing: 0.1em;
  }
  .work-w-us .work-w-us-text a div {
    background-color: #FFDF00;
    position: absolute;
    width: 164px;
    height: 60px;
    left: -2%;
    clip-path: polygon(0 0, 60% 0, 45% 100%, 0 100%);
    z-index: -1;
  }
  .work-w-us .work-w-us-text a img {
    margin-left: 3%;
    width: auto;
    height: 12px;
    z-index: 55;
  }
  .work-w-us .work-w-us-img {
    position: relative;
    height: 450px;
    margin-right: 100%;
    margin-top: 30%;
  }
  .work-w-us .work-w-us-img img {
    width: 345px;
    position: absolute;
    top: 16px;
  }
  .work-w-us .work-w-us-img div {
    width: 100vw;
    height: 265px;
    position: absolute;
    top: 185px;
    left: -26.5%;
    z-index: 5;
    background-color: #FFDF00;
    mix-blend-mode: darken;
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
  }
  footer {
    z-index: 33;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    padding: 0;
    width: 100%;
    height: fit-content;
  }
  footer .footer-haut, footer .col-droite, footer .col-milieu, footer .col-gauche, footer .footer-ligne-bas {
    display: none;
  }
  footer .footer-bas {
    display: none;
  }
  footer .footer-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: black;
    width: 100%;
  }
  footer .footer-mobile p:nth-child(1) {
    width: 80%;
    font-family: Oxygen;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5781rem;
    margin-bottom: 15%;
    text-transform: uppercase;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  footer .footer-mobile p:nth-child(1) br {
    display: none;
  }
  footer .footer-mobile p:nth-child(1) span {
    color: #FFDF00;
  }
  footer .footer-mobile p:nth-child(2) {
    text-transform: uppercase;
    color: #FFDF00;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Oxygen;
    font-weight: 500;
    font-style: normal;
    font-size: 1.25rem;
  }
  footer .footer-mobile p:nth-child(3) {
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    font-family: Oxygen;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
  }
  footer .footer-mobile p:nth-child(4) {
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    font-family: Oxygen;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
  }
  footer .footer-mobile img {
    width: 198px;
    height: 198px;
  }
  footer .footer-mobile .cont-mobile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  footer .footer-mobile .cont-footer-res {
    display: flex;
    justify-content: center;
  }
  footer .footer-mobile .cont-reseau-d {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 490px;
  }
  footer .footer-mobile .cont-reseau-d a {
    text-decoration: none;
  }
  footer .footer-mobile .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  footer .footer-mobile .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
  }
  footer .footer-mobile .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  footer .footer-mobile .cont-reseau-d a:first-child img:first-child, footer .footer-mobile .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  footer .footer-mobile .cont-reseau-d a:first-child img:last-child, footer .footer-mobile .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  footer .footer-mobile .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  footer .footer-mobile .cont-reseau-d a:last-child {
    position: relative;
    margin-left: 20px;
  }
  footer .footer-mobile .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  footer .footer-mobile .cont-reseau-d a:last-child img:first-child, footer .footer-mobile .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 15%;
    transition: all ease 300ms;
  }
  footer .footer-mobile .cont-reseau-d a:last-child img:last-child, footer .footer-mobile .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 150%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  footer .footer-mobile .cont-reseau-d a:last-child:hover img {
    transform: translateY(45px);
  }
  footer .footer-mobile-bas {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    width: 80%;
  }
  footer .footer-mobile-bas p:first-child {
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    font-size: 0.75rem;
    text-align: center;
    margin-top: 33px;
  }
  footer .footer-mobile-bas p:last-child {
    margin-bottom: 15%;
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    font-size: 0.75rem;
    color: white;
    margin-left: auto;
  }
  footer .footer-mobile-bas p:last-child span {
    color: #FFDF00;
  }
  footer .footer-mobile-bas p:last-child span a {
    text-decoration: none;
    color: #FFDF00;
  }
}

@media screen and (min-width: 450px) {
  .work-w-us .work-w-us-img img {
    left: 18vw;
  }
}

@media screen and (min-width: 768px) {
  .menu-scroll {
    display: none;
  }
  .menu .bg-menu ul {
    position: relative;
  }
  .menu .bg-menu .cont-reseau-d {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 20%;
    left: 34%;
  }
  .menu .bg-menu .cont-reseau-d a {
    text-decoration: none;
  }
  .menu .bg-menu .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .menu .bg-menu .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
  }
  .menu .bg-menu .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .menu .bg-menu .cont-reseau-d a:first-child img:first-child, .menu .bg-menu .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:first-child img:last-child, .menu .bg-menu .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  .menu .bg-menu .cont-reseau-d a:last-child {
    position: relative;
  }
  .menu .bg-menu .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .menu .bg-menu .cont-reseau-d a:last-child img:first-child, .menu .bg-menu .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 15%;
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:last-child img:last-child, .menu .bg-menu .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 150%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:last-child:hover img {
    transform: translateY(45px);
  }
  .hero-wrapper {
    background-image: url("../images/michael-descharles-UzV2VrbEOC8-unsplash_1_1228.png");
  }
  .hero-wrapper .boite-hero .col-noire {
    width: 17%;
  }
  .hero-wrapper .boite-hero .hero div {
    margin-top: 40%;
  }
  .hero-wrapper .boite-hero .hero div h1 {
    margin-top: 190px;
    font-size: 3.75rem;
    top: -100px;
  }
  .hero-wrapper .boite-hero .tite-ligne {
    top: 460px;
    left: 9%;
  }
  .hero-wrapper .boite-hero .cont-fleche {
    left: 90%;
  }
  .carousel .carou .slides .slide-1 {
    margin-left: 0;
  }
  .carousel .carou .slides .slide-2 {
    margin-left: 10%;
  }
  .carousel .carou .slides .slide-3 {
    margin-left: 5%;
  }
  .carousel .carou .slides .slide-2-fr {
    margin-left: 0;
  }
  .carousel .carou .slides .slide-3-fr {
    margin-left: 8%;
  }
  .carousel a {
    margin-left: 12%;
    margin-top: 80px;
  }
  .carousel .partie-droite {
    margin-top: 67px;
  }
  .carousel h1 {
    font-size: 3.125rem;
  }
  .carousel p {
    margin-top: 5px;
  }
  .carousel .carou .slides .slide:nth-child(2) {
    left: 0;
  }
  .carousel .carou .slide:nth-child(2) {
    left: 2%;
  }
  .carousel .carou .slide:nth-child(3) {
    left: 7%;
  }
  .carousel .carou .cont-fleche-carou {
    margin-left: 75%;
  }
  .work-w-us {
    overflow: hidden;
  }
  .work-w-us .work-w-us-text h1 {
    font-size: 3.125rem;
  }
  .work-w-us .work-w-us-img {
    height: 70vh;
    margin-top: 50px;
  }
  .work-w-us .work-w-us-img div {
    width: 100vw;
    left: -23%;
    top: 50%;
    overflow: hidden;
    height: 367px;
  }
  .work-w-us .work-w-us-img img {
    width: auto;
    height: auto;
    left: 25vw;
    top: 92px;
  }
  footer {
    z-index: 33;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    padding: 0;
    width: 100%;
    height: fit-content;
  }
  footer .footer-haut, footer .col-droite, footer .col-milieu, footer .col-gauche {
    display: none;
  }
  footer .footer-bas {
    display: none;
  }
  footer .footer-ligne-bas {
    display: none;
  }
  footer .footer-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: black;
    width: 100%;
  }
  footer .footer-mobile p:nth-child(1) {
    width: 80%;
    font-family: Oxygen;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5781rem;
    margin-bottom: 15%;
    text-transform: uppercase;
    position: relative;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  footer .footer-mobile p:nth-child(1) br {
    display: none;
  }
  footer .footer-mobile p:nth-child(1) span {
    color: #FFDF00;
  }
  footer .footer-mobile p:nth-child(2) {
    text-transform: uppercase;
    color: #FFDF00;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Oxygen;
    font-weight: 500;
    font-style: normal;
    font-size: 1.25rem;
  }
  footer .footer-mobile p:nth-child(3) {
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    font-family: Oxygen;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
  }
  footer .footer-mobile p:nth-child(4) {
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    font-family: Oxygen;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
  }
  footer .footer-mobile img {
    width: 198px;
    height: 198px;
  }
  footer .footer-mobile .cont-footer-res {
    display: flex;
    justify-content: center;
  }
  footer .footer-mobile .cont-reseau-d {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 550px;
  }
  footer .footer-mobile .cont-reseau-d a {
    text-decoration: none;
  }
  footer .footer-mobile .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  footer .footer-mobile .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
  }
  footer .footer-mobile .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  footer .footer-mobile .cont-reseau-d a:first-child img:first-child, footer .footer-mobile .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  footer .footer-mobile .cont-reseau-d a:first-child img:last-child, footer .footer-mobile .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  footer .footer-mobile .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  footer .footer-mobile .cont-reseau-d a:last-child {
    position: relative;
    margin-left: 20px;
  }
  footer .footer-mobile .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  footer .footer-mobile .cont-reseau-d a:last-child img:first-child, footer .footer-mobile .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 15%;
    transition: all ease 300ms;
  }
  footer .footer-mobile .cont-reseau-d a:last-child img:last-child, footer .footer-mobile .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 150%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  footer .footer-mobile .cont-reseau-d a:last-child:hover img {
    transform: translateY(45px);
  }
  footer .footer-mobile-bas {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    width: 80%;
  }
  footer .footer-mobile-bas p:first-child {
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    font-size: 0.75rem;
    text-align: center;
    margin-top: 33px;
  }
  footer .footer-mobile-bas p:last-child {
    margin-bottom: 15%;
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    font-size: 0.75rem;
    color: white;
    margin-left: auto;
  }
  footer .footer-mobile-bas p:last-child span {
    color: #FFDF00;
  }
  footer .footer-mobile-bas p:last-child span a {
    text-decoration: none;
    color: #FFDF00;
  }
}

@media screen and (min-width: 820px) {
  .work-w-us .work-w-us-img div {
    top: 460px;
  }
  .work-w-us .work-w-us-img img {
    top: 197px;
  }
}

@media screen and (min-width: 1024px) {
  .mobile, .mobile-hero {
    display: none;
  }
  .cont-col, .footer-ligne-bas {
    display: none;
  }
  .anchore-intro {
    position: relative;
  }
  .intro {
    display: none;
    width: 100%;
    background-color: black;
    position: fixed;
    top: -230px;
    left: 0;
    height: 100vh;
    animation: intro 500ms ease forwards;
    z-index: 1666;
  }
  .intro img {
    display: block;
    width: 100%;
    animation: delete-gif 100ms forwards;
    animation-delay: 4s;
    z-index: 1666;
  }
  @keyframes intro {
    0% {
      background-color: black;
    }
    100% {
      background-color: transparent;
    }
  }
  @keyframes delete-gif {
    0% {
      opacity: 1;
      display: block;
    }
    100% {
      display: none;
      opacity: 0;
      z-index: -1;
    }
  }
  .menu-scroll {
    display: flex;
    justify-content: space-between;
    background-color: black;
    position: fixed;
    width: 100%;
    z-index: 87;
    transition: all ease 200ms;
  }
  .menu-scroll img {
    width: 150px;
    height: 150px;
    margin-left: 61px;
  }
  .menu-scroll nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    height: 110px;
  }
  .menu-scroll nav ul li {
    margin: 10% 10px;
    color: white;
    font-family: Montserrat, 'sans-serif';
    font-weight: 500;
    font-size: 0.9375rem;
  }
  .menu-scroll nav ul li .call-link {
    color: white;
    transition: color ease 300ms;
  }
  .menu-scroll nav ul li .call-link:hover {
    color: #FFDF00;
  }
  .menu-scroll nav ul li a {
    text-decoration: none;
    color: white;
  }
  .menu-scroll nav ul li .ham-cont {
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    width: 140px;
    margin-left: 30px;
    margin-right: 40px;
    overflow: hidden;
  }
  .menu-scroll nav ul li .ham-cont .actual-ham {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-left: 10%;
    margin-top: 8.5%;
    z-index: 55;
  }
  .menu-scroll nav ul li .ham-cont .actual-ham span {
    display: inline-block;
    border: 1px solid #FFDF00;
    box-sizing: border-box;
    width: 30px;
    height: 2px;
    background-color: #FFDF00;
    margin: 4px 5px;
  }
  .menu-scroll nav ul li .ham-cont .actual-ham .t-off1 {
    animation: t-off1 500ms ease forwards;
  }
  @keyframes t-off1 {
    0% {
      transform: translateY(5px) rotate(135deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .menu-scroll nav ul li .ham-cont .actual-ham .t-off2 {
    animation: t-off2 500ms ease forwards;
  }
  @keyframes t-off2 {
    0% {
      transform: translateY(-5px) rotate(-135deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .menu-scroll nav ul li .ham-cont .actual-ham .t-on1 {
    animation: t-on1 300ms ease forwards;
  }
  @keyframes t-on1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: translateY(5px) rotate(135deg);
    }
  }
  .menu-scroll nav ul li .ham-cont .actual-ham .t-on2 {
    animation: t-on2 300ms ease forwards;
  }
  @keyframes t-on2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: translateY(-5px) rotate(-135deg);
    }
  }
  .menu-scroll nav ul li .ham-cont .cont-menu-mot {
    position: relative;
    transition: all ease 400ms;
  }
  .menu-scroll nav ul li .ham-cont .cont-menu-mot span {
    position: absolute;
    display: inline-block;
    border: none;
    background-color: #101010;
    box-sizing: border-box;
    margin-left: 10%;
    margin-top: 5%;
    z-index: 2;
  }
  .menu-scroll nav ul li .ham-cont .cont-menu-mot .bouge1, .menu-scroll nav ul li .ham-cont .cont-menu-mot .bouge1::before {
    position: absolute;
    top: -130px;
    left: 10px;
    transition: all ease 300ms;
  }
  .menu-scroll nav ul li .ham-cont .cont-menu-mot .bouge2, .menu-scroll nav ul li .ham-cont .cont-menu-mot .bouge2::before {
    top: 7px;
    left: 10px;
    color: white;
    transition: all ease 300ms;
  }
  .menu-scroll nav ul li .ham-cont:hover .cont-menu-mot .bouge1 {
    position: absolute;
    transform: translateY(138px);
    color: #FFDF00;
  }
  .menu-scroll nav ul li .ham-cont:hover .cont-menu-mot .bouge2 {
    transform: translateY(50px);
  }
  .menu-scroll nav ul li:nth-child(2) {
    width: 50px;
    height: 2px;
    border: 1px solid #FFDF00;
    box-sizing: border-box;
    transform: rotate(120deg);
    background-color: #FFDF00;
  }
  .carousel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    height: 750px;
  }
  .carousel .carou {
    display: flex;
    flex-direction: column;
    width: 520px;
    margin-top: 0;
    background-color: #EAEAEA;
  }
  .carousel .carou .cont-fleche-carou {
    margin-top: 40px;
    margin-left: 65%;
    display: flex;
  }
  .carousel .carou .cont-fleche-carou img:first-child {
    cursor: pointer;
    transform: rotate(180deg) translateY(-1px);
    margin-right: 5px;
    width: 43px;
  }
  .carousel .carou .cont-fleche-carou img:last-child {
    cursor: pointer;
    width: 43px;
    margin-left: 5px;
  }
  .carousel .carou .greyed {
    opacity: 0.5;
  }
  .carousel .carou .hidden {
    display: none;
  }
  .carousel .carou .slides {
    position: relative;
    font-family: Montserrat, 'sans-serif';
    font-weight: bold;
    display: flex;
    z-index: 2;
    justify-content: center;
    overflow: hidden;
    margin-top: 30px;
  }
  .carousel .carou .slides .slide {
    position: relative;
    width: 100%;
    height: 75%;
    left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel .carou .slides .slide h1 {
    color: #FFDF00;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 4.0625rem;
    margin-bottom: 0;
  }
  .carousel .carou .slides .slide h2 {
    text-transform: uppercase;
    font-family: Montserrat;
    font-size: 2.1875rem;
    width: 80%;
    margin-top: 0;
  }
  .carousel .carou .slides div:nth-child(1), .carousel .carou .slides div:nth-child(1)::before {
    top: 10%;
    transition: all ease-in-out 100ms;
  }
  .carousel .carou .slides .animate-up {
    transform: translateY(-125%);
  }
  .carousel .carou .slides .animate-up2 {
    transform: translateY(-270%);
  }
  .carousel .carou .slides .animate-down {
    transform: translateY(50px);
  }
  .carousel .carou .slides div:nth-child(2), .carousel .carou .slides div:nth-child(2)::before {
    top: 50%;
    transition: all ease-in-out 100ms;
  }
  .carousel .carou .slides div:nth-child(3), .carousel .carou .slides div:nth-child(3)::before {
    top: 80%;
    transition: all ease-in-out 100ms;
  }
  .carousel .partie-droite {
    width: 771px;
    margin-left: 10px;
    margin-bottom: 230px;
    margin-top: auto;
  }
  .carousel .partie-droite h1 {
    font-size: 3.125rem;
    font-family: Montserrat;
    margin-left: 8%;
    width: 80%;
  }
  .carousel .partie-droite h1 br {
    display: none;
  }
  .carousel .partie-droite h1 span {
    color: #FFDF00;
  }
  .carousel .partie-droite p {
    font-family: Oxygen;
    font-size: 1.25rem;
    font-weight: 600;
    margin-left: 8%;
    width: 80%;
  }
  .carousel .partie-droite a {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    z-index: 55;
    margin-left: 9%;
    margin-top: 10%;
    font-weight: bold;
    font-family: Oxygen;
    transition: all ease 200ms;
    padding-left: 2%;
    width: 30%;
  }
  .carousel .partie-droite a div {
    background-color: #FFDF00;
    position: absolute;
    width: 200px;
    height: 60px;
    left: -2%;
    transition: all ease 300ms;
    clip-path: polygon(0 0, 50% 0, 40% 100%, 0 100%);
    z-index: -1;
  }
  .carousel .partie-droite a .en-link1 {
    width: 90%;
  }
  .carousel .partie-droite a img {
    margin-left: 5%;
    width: auto;
    height: 12px;
    z-index: 55;
    transition: all ease 300ms;
  }
  .carousel .partie-droite a:hover div {
    width: 90%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .carousel .partie-droite a:hover .en-link1 {
    width: 75%;
  }
  .carousel .partie-droite a:hover img {
    margin-left: 10%;
    opacity: 0;
  }
  .bandeau {
    display: flex;
    height: 410px;
    width: 97%;
    background-image: url("../images/Gestion-Samurai_Home-BG-2.jpg");
    background-attachment: fixed;
  }
  .bandeau .filtre {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
  }
  .bandeau .filtre .chiffre {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bandeau .filtre .chiffre .odo-cont {
    display: flex;
    align-items: center;
    font-size: 3.4375rem;
    font-family: Montserrat;
    font-weight: 200;
    color: #FFDF00;
  }
  .bandeau .filtre .chiffre .odo-cont .odometer {
    display: block;
    font-size: 3.4375rem;
    text-align: center;
    width: 100%;
    color: #FFDF00;
    font-weight: 200;
    animation-duration: 3s;
  }
  .bandeau .filtre .chiffre .odo-cont .odometer .odometer-inside {
    font-family: Montserrat;
  }
  .bandeau .filtre .chiffre .odo-cont .odometer .odometer-inside .odometer-digit {
    font-family: Montserrat;
  }
  .bandeau .filtre .chiffre .sous-titre {
    display: flex;
    color: white;
    align-items: flex-start;
    text-align: left;
    margin-right: auto;
    margin-top: 0;
    font-family: Oxygen;
    font-weight: 400;
    font-size: 1.5625rem;
    margin-left: auto;
  }
  .bandeau .filtre .chiffre:nth-child(2), .bandeau .filtre .chiffre:nth-child(3) {
    margin-top: 25px;
  }
  .bandeau .filtre .chiffre:nth-child(2) .sous-titre, .bandeau .filtre .chiffre:nth-child(3) .sous-titre {
    margin-top: 20px;
    text-align: center;
  }
  .bandeau-mobile {
    display: none;
  }
  .work-w-us {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 60px;
  }
  .work-w-us .work-w-us-text {
    width: 50%;
    margin-left: 5%;
  }
  .work-w-us .work-w-us-text h1 {
    font-size: 4.0625rem;
    font-family: Montserrat;
    color: #000;
    margin-bottom: 33.5px;
  }
  .work-w-us .work-w-us-text p {
    width: 85%;
    font-size: 1.25rem;
    font-family: Oxygen, 'sans-serif';
    font-weight: bold;
    line-height: 1.875rem;
  }
  .work-w-us .work-w-us-text span {
    font-size: inherit;
    color: #FFDF00;
  }
  .work-w-us .work-w-us-text a {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    z-index: 55;
    margin-left: 1%;
    margin-top: 16%;
    font-weight: bold;
    font-family: Oxygen;
    font-size: 1.125rem;
    font-style: normal;
    padding-left: 2%;
    width: 50%;
  }
  .work-w-us .work-w-us-text a div {
    background-color: #FFDF00;
    position: absolute;
    width: 200px;
    height: 60px;
    left: -2%;
    clip-path: polygon(0 0, 60% 0, 45% 100%, 0 100%);
    z-index: -1;
    transition: all ease 300ms;
  }
  .work-w-us .work-w-us-text a img {
    margin-left: 5%;
    width: auto;
    height: 12px;
    z-index: 55;
    transition: all ease 300ms;
  }
  .work-w-us .work-w-us-text a:hover div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .work-w-us .work-w-us-text a:hover .en-link2 {
    width: 38%;
  }
  .work-w-us .work-w-us-text a:hover img {
    margin-left: 10%;
    opacity: 0;
  }
  .work-w-us .work-w-us-img {
    position: relative;
    height: 733px;
    margin-right: 0;
    width: 762px;
  }
  .work-w-us .work-w-us-img img {
    position: absolute;
    left: auto;
    width: 520px;
    height: 733px;
    top: 60px;
    z-index: 2;
  }
  .work-w-us .work-w-us-img img .opa1 {
    opacity: 1;
  }
  .work-w-us .work-w-us-img img .opa0 {
    opacity: 0;
  }
  .work-w-us .work-w-us-img div {
    width: 100%;
    height: 561px;
    position: absolute;
    z-index: 5;
    top: 32%;
    left: 0%;
    overflow: hidden;
    background-color: #FFDF00;
    mix-blend-mode: darken;
    clip-path: polygon(100% 0, 100% 100%, 0 100%);
  }
  footer {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    background-color: black;
    padding: 0;
    width: 100%;
    overflow: hidden;
  }
  footer p {
    margin-top: 0;
    text-transform: uppercase;
  }
  footer p span {
    color: #FFDF00;
  }
  footer .footer-ligne-bas {
    display: none;
  }
  footer .footer-mobile {
    display: flex;
    margin-top: 50px;
  }
  footer .cont-col .col-gauche {
    display: block;
    margin-top: 100px;
    margin-left: 3%;
  }
  footer .cont-col .col-gauche p:first-child {
    font-size: 1.75rem;
    line-height: 1.8rem;
  }
  footer .cont-col .col-gauche p:first-child span {
    color: #FFDF00;
  }
  footer .cont-col .col-gauche p:last-child {
    font-size: 0.75rem;
    font-weight: 400;
    font-family: Montserrat;
  }
  footer .cont-col .col-milieu {
    display: block;
  }
  footer .cont-col .col-milieu .cont-footer-res {
    display: flex;
    justify-content: center;
    height: auto;
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d {
    position: absolute;
    display: flex;
    justify-content: space-between;
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a {
    text-decoration: none;
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:last-child div {
    margin-left: 20px;
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:first-child img:first-child, footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:first-child img:last-child, footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:last-child {
    position: relative;
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:last-child img:first-child, footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 15%;
    transition: all ease 300ms;
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:last-child img:last-child, footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 150%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  footer .cont-col .col-milieu .cont-footer-res .cont-reseau-d a:last-child:hover img {
    transform: translateY(45px);
  }
  footer .cont-col .col-droite {
    display: block;
    margin-right: 3%;
  }
  footer .cont-col .col-droite p {
    text-align: right;
    font-weight: 500;
    font-size: 1rem;
  }
  footer .cont-col .col-droite p:nth-child(1) {
    font-size: 1.25rem;
    font-family: Montserrat;
    margin-top: 100px;
  }
  footer .cont-col .col-droite p:last-child {
    font-size: 0.75rem;
  }
  footer .cont-col .col-droite p:last-child a {
    text-decoration: none;
    color: #FFDF00;
  }
  footer .footer-haut {
    width: 100%;
    margin-top: 2%;
    display: flex;
    justify-content: space-between;
  }
  footer .footer-haut p.master {
    margin-top: 70px;
    font-size: 1.75rem;
  }
  footer .footer-haut p.master br {
    display: inline;
  }
  footer .footer-haut p:first-child {
    margin-left: 3%;
    font-family: Oxygen;
    font-size: 1.25rem;
    color: white;
    width: 15%;
    line-height: 1.5625rem;
    text-transform: uppercase;
  }
  footer .footer-haut p:first-child span {
    color: #FFDF00;
  }
  footer .footer-haut img {
    margin-top: 0;
    width: 250px;
    height: 250px;
  }
  footer .footer-haut .liste-footer-haut {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 3%;
  }
  footer .footer-haut .liste-footer-haut p {
    width: auto;
    font-weight: 500;
  }
  footer .footer-haut .liste-footer-haut p:nth-child(1) {
    font-family: Montserrat;
    font-size: 1.25rem;
    color: #FFDF00;
    margin-bottom: 0;
    margin-top: 70px;
  }
  footer .footer-haut .liste-footer-haut p:nth-child(2) {
    width: fit-content;
    display: block;
    font-size: 1rem;
    font-family: Montserrat;
    text-align: center;
  }
  footer .footer-haut .liste-footer-haut p:nth-child(3) {
    display: block;
    font-size: 1rem;
    font-family: Montserrat;
    text-transform: uppercase;
    text-align: right;
    line-height: 1.25rem;
  }
  footer .footer-bas {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: inherit;
    overflow: hidden;
    margin-top: 20px;
  }
  footer .footer-bas .cont-footer-res {
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: 20%;
  }
  footer .footer-bas p:first-child {
    display: block;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.75rem;
    font-family: Montserrat;
    margin-top: 1%;
    margin-left: 3%;
  }
  footer .footer-bas p:last-child {
    display: block;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.75rem;
    font-family: Montserrat;
    margin-top: 1%;
    margin-right: 3%;
  }
  footer .footer-bas p:last-child span {
    color: #FFDF00;
  }
  footer .footer-bas p:last-child span a {
    text-decoration: none;
    color: #FFDF00;
  }
  footer .footer-bas p:last-child span a:visited {
    text-decoration: none;
    color: #FFDF00;
  }
  footer .footer-bas .cont-reseau-d {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 10%;
    left: 43%;
  }
  footer .footer-bas .cont-reseau-d a {
    text-decoration: none;
  }
  footer .footer-bas .cont-reseau-d a:last-child div {
    margin-left: 20px;
  }
  footer .footer-bas .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  footer .footer-bas .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
  }
  footer .footer-bas .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  footer .footer-bas .cont-reseau-d a:first-child img:first-child, footer .footer-bas .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  footer .footer-bas .cont-reseau-d a:first-child img:last-child, footer .footer-bas .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  footer .footer-bas .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  footer .footer-bas .cont-reseau-d a:last-child {
    position: relative;
  }
  footer .footer-bas .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  footer .footer-bas .cont-reseau-d a:last-child img:first-child, footer .footer-bas .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 15%;
    transition: all ease 300ms;
  }
  footer .footer-bas .cont-reseau-d a:last-child img:last-child, footer .footer-bas .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 150%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  footer .footer-bas .cont-reseau-d a:last-child:hover img {
    transform: translateY(45px);
  }
}

@media screen and (min-width: 1280px) {
  footer .footer-mobile .cont-footer-res .cont-reseau-d {
    top: 595px;
  }
  footer .footer-bas .cont-footer-res {
    margin-right: 24%;
    margin-left: auto;
  }
}

@media screen and (min-width: 1280px) {
  .intro {
    display: block;
  }
  .hero-wrapper {
    background-image: url("../images/Gestion-Samurai_Home-BG.jpg");
    background-position: center center;
  }
  .hero-wrapper .boite-hero {
    height: 100vh;
  }
  footer {
    display: block;
    height: fit-content;
  }
  footer .footer-mobile {
    display: none;
  }
  footer .cont-col {
    display: flex;
    justify-content: space-between;
  }
  footer .cont-col .col-gauche {
    margin-left: 3%;
  }
  footer .cont-col .col-gauche p:first-child {
    font-size: 1.75rem;
    line-height: 1.8rem;
  }
  footer .cont-col .col-gauche p:first-child span {
    color: #FFDF00;
  }
  footer .cont-col .col-milieu {
    margin-top: 40px;
  }
  footer .cont-col .col-milieu img {
    margin-top: 0;
  }
  footer .cont-col .col-milieu img:first-child {
    margin-bottom: 40px;
  }
  footer .cont-col .col-droite p {
    font-family: Montserrat;
  }
  footer .footer-ligne-bas {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  footer .footer-ligne-bas p {
    font-family: Montserrat;
  }
  footer .footer-ligne-bas p:first-child {
    margin-left: 3%;
    font-size: 0.75rem;
    font-family: Montserrat;
    font-weight: 400;
  }
  footer .footer-ligne-bas p:last-child {
    margin-right: 3%;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: Montserrat;
  }
  footer .footer-ligne-bas p:last-child span {
    color: #FFDF00;
  }
  footer .footer-ligne-bas p:last-child span a {
    color: #FFDF00;
    text-decoration: none;
  }
}

@media only screen and (min-width: 1440px) {
  .intro {
    display: block;
  }
  .hero-wrapper {
    background-image: url("../images/Gestion-Samurai_Home-BG.jpg");
  }
  .hero-wrapper .boite-hero .tite-ligne {
    top: 500px;
    left: 13%;
  }
  .hero-wrapper .boite-hero .cont-reseau-d {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 555px;
    left: 12%;
  }
  .hero-wrapper .boite-hero .cont-reseau-d a {
    text-decoration: none;
  }
  .hero-wrapper .boite-hero .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .hero-wrapper .boite-hero .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
  }
  .hero-wrapper .boite-hero .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .hero-wrapper .boite-hero .cont-reseau-d a:first-child img:first-child, .hero-wrapper .boite-hero .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  .hero-wrapper .boite-hero .cont-reseau-d a:first-child img:last-child, .hero-wrapper .boite-hero .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  .hero-wrapper .boite-hero .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  .hero-wrapper .boite-hero .cont-reseau-d a:last-child {
    position: relative;
  }
  .hero-wrapper .boite-hero .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .hero-wrapper .boite-hero .cont-reseau-d a:last-child img:first-child, .hero-wrapper .boite-hero .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 24%;
    transition: all ease 300ms;
  }
  .hero-wrapper .boite-hero .cont-reseau-d a:last-child img:last-child, .hero-wrapper .boite-hero .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 150%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  .hero-wrapper .boite-hero .cont-reseau-d a:last-child:hover img {
    transform: translateY(35px);
  }
  .carousel .carou {
    margin-left: auto;
    margin-right: auto;
  }
  .carousel .carou .slides .slide-1-fr {
    margin-left: 0;
  }
  .carousel .carou .slides .slide-3-fr {
    margin-left: 3%;
  }
  .carousel .carou .slides .slide-3 {
    margin-left: 0%;
  }
  .carousel .carou .slides .slide:nth-child(2) {
    left: 0;
  }
  .carousel .partie-droite {
    margin-top: 80px;
    margin-right: auto;
    margin-left: auto;
  }
  footer {
    display: block;
  }
  footer p {
    text-transform: uppercase;
  }
  footer .footer-mobile {
    display: none;
  }
  footer .cont-col {
    display: flex;
    justify-content: space-between;
  }
  footer .cont-col .col-gauche {
    margin-left: 3%;
  }
  footer .cont-col .col-gauche p:first-child {
    font-size: 1.75rem;
    line-height: 1.8rem;
  }
  footer .cont-col .col-gauche p:first-child span {
    color: #FFDF00;
  }
  footer .cont-col .col-milieu {
    margin-top: 40px;
  }
  footer .cont-col .col-milieu img {
    margin-top: 0;
  }
  footer .cont-col .col-milieu img:first-child {
    margin-bottom: 40px;
  }
  footer .cont-col .col-droite p {
    font-family: Montserrat;
  }
  footer .footer-ligne-bas {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  footer .footer-ligne-bas p {
    font-family: Montserrat;
  }
  footer .footer-ligne-bas p:first-child {
    margin-left: 3%;
    font-size: 0.75rem;
    font-family: Montserrat;
    font-weight: 400;
  }
  footer .footer-ligne-bas p:last-child {
    margin-right: 3%;
    font-size: 0.75rem;
    font-weight: 500;
    font-family: Montserrat;
  }
  footer .footer-ligne-bas p:last-child span {
    color: #FFDF00;
  }
  footer .footer-ligne-bas p:last-child span a {
    color: #FFDF00;
    text-decoration: none;
  }
  footer .footer-haut {
    display: flex;
  }
  footer .footer-haut .liste-footer-haut {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 3%;
  }
  footer .footer-haut img {
    display: flex;
  }
  footer .footer-bas .cont-footer-res {
    margin-left: auto;
    margin-right: 26%;
  }
  footer .footer-bas .cont-reseau-d {
    left: 49.5%;
  }
}

@media screen and (min-width: 1600px) {
  .carousel .carou .slides div:nth-child(3) .slide-3-fr {
    margin-left: 8%;
  }
  .carousel .carou .slides .slide-3 {
    margin-left: 5%;
  }
  .carousel .carou .slides div:nth-child(3) {
    left: 5%;
  }
  .carousel .carou .slides .slide:nth-child(2) {
    left: 0%;
  }
  footer .footer-bas .cont-footer-res {
    margin-left: auto;
    margin-right: 27%;
  }
}

@media screen and (min-width: 1920px) {
  .hero-wrapper {
    background-position-y: center;
  }
  .hero-wrapper .boite-hero {
    position: relative;
    height: 100vh;
  }
  .hero-wrapper .boite-hero .hero div h1 {
    margin-top: 375px;
  }
  .hero-wrapper .boite-hero .cont-fleche {
    top: 714px;
  }
  .hero-wrapper .boite-hero .cont-reseau-d {
    top: 734px;
  }
  .hero-wrapper .boite-hero .tite-ligne {
    top: 612px;
    left: 13%;
  }
  footer .footer-bas .cont-footer-res {
    margin-right: 29%;
  }
}

@media screen and (min-width: 360px) {
  .about-hero {
    color: white;
    background-color: black;
    margin-top: 0;
    position: relative;
    overflow: hidden;
  }
  .about-hero .cont-reseau-d {
    display: none;
  }
  .about-hero .cont-gauche-about-hero {
    position: relative;
  }
  .about-hero .cont-gauche-about-hero h1 {
    display: block;
    font-family: Montserrat;
    margin: 0;
    margin-left: 10%;
    margin-top: 45%;
  }
  .about-hero .cont-gauche-about-hero .about-tite-l {
    position: absolute;
    display: inline-block;
    height: 5px;
    left: 10%;
    top: 70px;
    animation: tite-ligne 600ms ease-in-out forwards;
    background: #FFDF00;
  }
  @keyframes tite-ligne {
    0% {
      width: 0px;
    }
    100% {
      width: 138px;
    }
  }
  .about-hero p {
    font-family: Oxygen;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    width: 80%;
    margin: 20% auto;
    margin-top: 60px;
  }
  .about-hero .about-img {
    margin-left: 10%;
    width: 100%;
    height: 330px;
  }
  .about-hero .about-img img {
    width: 132%;
    height: auto;
  }
  .about-hero .cont-fleche {
    position: absolute;
    top: 85%;
    left: 80%;
  }
  .about-hero .cont-fleche .fleche-bas-a {
    position: relative;
    box-sizing: border-box;
    height: 60px;
    width: 48px;
    display: flex;
    padding: 15px 18px;
  }
  .about-hero .cont-fleche .fleche-bas-a img {
    margin-top: 0;
    margin-bottom: 0;
    width: 13px;
    height: 43px;
    z-index: 22;
  }
  .about-hero .cont-fleche .fleche-bas-a .fleche-bas-div {
    position: absolute;
    top: 0;
    left: 0;
    height: 170px;
    width: 100%;
    background-color: #FFDF00;
    clip-path: polygon(0 0, 100% 0, 100% 15%, 0 30%);
    z-index: 2;
  }
  .about-hero .cont-fleche .fleche-bas-a {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .about-hero .cont-fleche .fleche-bas-a img {
    animation: animate 1.8s ease infinite;
    animation-delay: 1.5s;
  }
  @keyframes animate {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    50% {
      transform: translateY(60px);
    }
    60% {
      transform: translateY(-55px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
    }
  }
  .our-team .ourt {
    margin-left: 10%;
  }
  .our-company {
    color: black;
    margin-top: 80px;
    position: relative;
    overflow: hidden;
  }
  .our-company h1 {
    display: block;
    font-family: Montserrat;
    margin: 0;
    margin-left: 10%;
  }
  .our-company h1 span {
    color: #FFDF00;
  }
  .our-company h2 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-family: Oxygen;
    margin-left: 10%;
  }
  .our-company h2 span {
    color: #FFDF00;
    font-weight: 700;
  }
  .our-company p {
    width: 80%;
    margin-left: 10%;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
  }
  .our-company div {
    margin-right: 10%;
    margin-top: 0px;
  }
  .our-company div img {
    margin-top: 40px;
    width: 100%;
    margin-right: 5%;
  }
  .core-values {
    margin-top: 80px;
  }
  .core-values h1 {
    margin-left: 5%;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.2856pxrem;
    margin-top: 0;
  }
  .core-values h1 span {
    color: #FFDF00;
  }
  .core-values p {
    margin-left: 5%;
    width: 80%;
    font-family: Oxygen;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    margin-bottom: 80px;
  }
  .core-values h1:first-child {
    margin-left: 10%;
  }
  .core-values .p-p {
    margin-left: 10%;
  }
  .core-values .swiper {
    width: 80%;
  }
  .core-values .swiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #EAEAEA;
    height: 250px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .core-values .swiper .swiper-slide:nth-child(2) h2 {
    margin-top: 70px;
  }
  .core-values .swiper .swiper-slide:nth-child(2) .fr-2-about {
    margin-top: 47px;
  }
  .core-values .swiper .swiper-slide:nth-child(3) h2 {
    margin-top: 27px;
  }
  .core-values .swiper .swiper-slide:nth-child(4) h2 {
    margin-top: 70px;
  }
  .core-values .swiper .swiper-slide:nth-child(5) h2 {
    margin-left: 12%;
  }
  .core-values .swiper .swiper-slide:nth-child(6) h2 {
    margin-top: 25px;
  }
  .core-values .swiper .swiper-slide:nth-child(6) .fr-6-about {
    margin-top: 15px;
  }
  .core-values .swiper .swiper-slide h2 {
    text-align: left;
    text-transform: uppercase;
    margin-right: auto;
    margin-left: 12%;
    font-family: Montserrat;
    margin-bottom: 0;
    margin-top: 45px;
  }
  .core-values .swiper .swiper-slide p {
    text-align: left;
  }
  .core-values .cont-swiper-core {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    margin-top: 30px;
  }
  .core-values .cont-swiper-core .cont-button-core {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 10%;
  }
  .core-values .cont-swiper-core .cont-button-core .swiper-button-next-core, .core-values .cont-swiper-core .cont-button-core .swiper-button-prev-core {
    width: 50px;
    height: 36px;
    cursor: pointer;
  }
  .core-values .cont-swiper-core .cont-button-core .swiper-button-next-core img, .core-values .cont-swiper-core .cont-button-core .swiper-button-prev-core img {
    width: 50px;
    height: 14px;
  }
  .core-values .cont-swiper-core .cont-button-core .swiper-button-prev-core img {
    transform: rotate(180deg) translateY(-1px);
  }
  .core-values .cont-swiper-core .cont-button-core .swiper-button-next-core {
    margin-left: 10px;
  }
  .core-values .cont-swiper-core .swiper-pagination-core {
    top: 40%;
    width: 47%;
    left: 10%;
  }
  .core-values .cont-swiper-core .swiper-pagination-core span {
    background: #FFDF00;
  }
  .core-values .cont-controls-values {
    margin-top: 5%;
    height: 36px;
    display: flex;
    justify-content: space-evenly;
  }
  .core-values .cont-tracker-values {
    width: 30%;
    height: 6px;
    background-color: #c4c4c4;
    margin-top: 4%;
  }
  .core-values .cont-tracker-values .tracker-values {
    width: 15%;
    border: 3px solid #FFDF00;
    background-color: #FFDF00;
  }
  .core-values .cont-arrow-values {
    margin-right: 0;
  }
  .core-values .cont-arrow-values img {
    width: 50px;
    height: 14px;
  }
  .core-values .cont-arrow-values img:first-child {
    transform: rotate(180deg) translateY(-16px);
  }
  .our-team {
    margin-top: 80px;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  .our-team h1 {
    margin-left: 10%;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.2856pxrem;
    margin-bottom: 40px;
  }
  .our-team h1 span {
    color: #FFDF00;
  }
  .our-team .swiper2 {
    width: 80%;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 358px;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide .cont-nom {
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide .cont-nom p {
    margin-left: auto;
    text-align: left;
    padding-left: 5%;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide .cont-nom p:first-child {
    font-family: Oxygen;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.125rem;
    color: #FFDF00;
    margin-bottom: 0;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide .cont-nom p:last-child {
    font-family: Oxygen;
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-top: 0;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(1) {
    background-image: url("../images/Gestion-Samurai_F1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(2) {
    background-image: url("../images/Gestion-Samurai_F2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(3) {
    background-image: url("../images/Gestion-Samurai_F3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(4) {
    background-image: url("../images/Gestion-Samurai_F4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(5) {
    background-image: url("../images/Gestion-Samurai_F5.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(6) {
    background-image: url("../images/Gestion-Samurai_F6.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(7) {
    background-image: url("../images/Gestion-Samurai_F7.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(8) {
    background-image: url("../images/Gestion-Samurai_F8.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .our-team .cont-swiper-team {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    width: 95%;
    margin-top: 30px;
  }
  .our-team .cont-swiper-team .cont-button-team {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 5%;
  }
  .our-team .cont-swiper-team .cont-button-team .swiper-button-next-team, .our-team .cont-swiper-team .cont-button-team .swiper-button-prev-team {
    width: 50px;
    height: 36px;
    cursor: pointer;
  }
  .our-team .cont-swiper-team .cont-button-team .swiper-button-next-team img, .our-team .cont-swiper-team .cont-button-team .swiper-button-prev-team img {
    width: 50px;
    height: 14px;
  }
  .our-team .cont-swiper-team .cont-button-team .swiper-button-prev-team img {
    transform: rotate(180deg) translateY(-1px);
  }
  .our-team .cont-swiper-team .cont-button-team .swiper-button-next-team {
    margin-left: 10px;
  }
  .our-team .cont-swiper-team .swiper-pagination-team {
    top: 40%;
    width: 50%;
    left: 10%;
  }
  .our-team .cont-swiper-team .swiper-pagination-team span {
    background: #FFDF00;
  }
  .our-team .carousel-team .slide {
    margin-left: auto;
    margin-right: auto;
    background-color: #e8e8e9;
    width: 80%;
    height: 358px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .our-team .carousel-team .slide:nth-child(2) {
    display: none;
  }
  .our-team .carousel-team .slide:nth-child(3) {
    display: none;
  }
  .our-team .carousel-team .slide1 {
    background-image: url("../images/mand-holding-cup-détouré 1.png");
    background-position: top;
    background-size: cover;
  }
  .our-team .cont-controls-team {
    margin-top: 5%;
    height: 36px;
    display: flex;
    justify-content: space-evenly;
  }
  .our-team .cont-tracker-team {
    width: 30%;
    height: 6px;
    background-color: #c4c4c4;
    margin-top: 4%;
  }
  .our-team .cont-tracker-team .tracker-team {
    width: 15%;
    border: 3px solid #FFDF00;
    background-color: #FFDF00;
  }
  .our-team .cont-arrow-team {
    margin-right: 0;
  }
  .our-team .cont-arrow-team img {
    width: 50px;
    height: 14px;
  }
  .our-team .cont-arrow-team img:first-child {
    transform: rotate(180deg);
  }
}

@media screen and (min-width: 768px) {
  .mobile {
    width: 100%;
    overflow: hidden;
    position: fixed;
    z-index: 99;
  }
  .mobile nav {
    background-color: black;
  }
  .mobile nav ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    height: 110px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
  }
  .mobile nav ul li:first-child img {
    width: 105px;
    height: 105px;
    margin-left: 5%;
  }
  .mobile nav ul li {
    margin: 10% 10px;
    color: white;
    font-family: Montserrat, 'sans-serif';
    font-weight: 500;
    font-size: 0.9375rem;
  }
  .mobile nav ul li a {
    text-decoration: none;
    color: white;
  }
  .mobile nav ul li .ham-cont {
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    width: 50px;
    margin-left: -30%;
  }
  .mobile nav ul li .ham-cont .actual-ham {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-left: 10%;
    margin-top: 30%;
  }
  .mobile nav ul li .ham-cont .actual-ham span {
    display: inline-block;
    border: 1px solid #FFDF00;
    box-sizing: border-box;
    width: 30px;
    height: 2px;
    background-color: #FFDF00;
    margin: 3.5px 4px;
  }
  .mobile nav ul li .ham-cont .actual-ham .t-off1 {
    animation: t-off1 500ms ease forwards;
  }
  @keyframes t-off1 {
    0% {
      transform: translateY(5px) rotate(135deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .mobile nav ul li .ham-cont .actual-ham .t-off2 {
    animation: t-off2 500ms ease forwards;
  }
  @keyframes t-off2 {
    0% {
      transform: translateY(-5px) rotate(-135deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .mobile nav ul li .ham-cont .actual-ham .t-on1 {
    animation: t-on1 300ms ease forwards;
  }
  @keyframes t-on1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: translateY(5px) rotate(135deg);
    }
  }
  .mobile nav ul li .ham-cont .actual-ham .t-on2 {
    animation: t-on2 300ms ease forwards;
  }
  @keyframes t-on2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: translateY(-5px) rotate(-135deg);
    }
  }
  .about-hero p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 70px;
  }
  .about-hero .cont-gauche-about-hero h1 {
    font-size: 3.75rem;
    margin-top: 20%;
  }
  .about-hero .cont-gauche-about-hero .about-tite-l {
    top: 95px;
  }
  .our-company {
    margin-top: 80px;
  }
  .our-company div:first-child {
    margin-top: 0;
  }
  .our-company div:first-child h1 {
    font-size: 3.125rem;
  }
  .our-company div:last-child {
    margin-top: 0;
  }
  .our-company div:last-child img {
    margin-top: 80px;
  }
  .core-values h1 {
    font-size: 3.125rem;
  }
  .core-values .swiper .swiper-slide:nth-child(2) h2 {
    margin-top: 45px;
  }
  .core-values .swiper .swiper-slide:nth-child(3) h2 {
    margin-top: 27px;
  }
  .core-values .swiper .swiper-slide:nth-child(3) .fr-3-about {
    margin-top: 50px;
  }
  .core-values .swiper .swiper-slide:nth-child(4) h2 {
    margin-top: 45px;
  }
  .core-values .swiper .swiper-slide:nth-child(5) h2 {
    margin-top: 45px;
  }
  .core-values .swiper .swiper-slide:nth-child(6) h2 {
    margin-top: 30px;
  }
  .core-values .swiper .swiper-slide:nth-child(6) .fr-6-about {
    margin-top: 35px;
  }
  .core-values .cont-swiper-core .swiper-pagination-core {
    width: 61%;
  }
  .our-team h1 {
    margin-left: 10%;
    font-size: 3.125rem;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(2n-1), .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(2n) {
    background-color: #e4e4e4;
    background-size: contain;
    background-position-x: center;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(2) {
    background-image: url("../images/Gestion-Samurai_F2-detoure.png");
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(3) {
    background-image: url("../images/Gestion-Samurai_F3-detoure.png");
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(4) {
    background-image: url("../images/Gestion-Samurai_F4-detoure.png");
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(5) {
    background-image: url("../images/Gestion-Samurai_F5-detoure.png");
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(6) {
    background-image: url("../images/Gestion-Samurai_F6-detoure.png");
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(7) {
    background-image: url("../images/Gestion-Samurai_F7-detoure.png");
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(8) {
    background-image: url("../images/Gestion-Samurai_F8-detoure.png");
  }
  .our-team .cont-swiper-team .swiper-pagination-team {
    width: 65%;
  }
}

@media screen and (min-width: 1024px) {
  .menu .bg-menu ul {
    position: relative;
  }
  .menu .bg-menu .cont-reseau-d {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 20%;
    left: 34%;
  }
  .menu .bg-menu .cont-reseau-d a {
    text-decoration: none;
  }
  .menu .bg-menu .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .menu .bg-menu .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
  }
  .menu .bg-menu .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .menu .bg-menu .cont-reseau-d a:first-child img:first-child, .menu .bg-menu .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:first-child img:last-child, .menu .bg-menu .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  .menu .bg-menu .cont-reseau-d a:last-child {
    position: relative;
  }
  .menu .bg-menu .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .menu .bg-menu .cont-reseau-d a:last-child img:first-child, .menu .bg-menu .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 15%;
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:last-child img:last-child, .menu .bg-menu .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 150%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  .menu .bg-menu .cont-reseau-d a:last-child:hover img {
    transform: translateY(45px);
  }
  .mobile {
    display: none;
  }
  .about-hero {
    display: flex;
    background-color: black;
    color: white;
    height: 600px;
  }
  .about-hero .cont-reseau-d {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 470px;
    left: 3%;
  }
  .about-hero .cont-reseau-d a {
    text-decoration: none;
  }
  .about-hero .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
  }
  .about-hero .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
  }
  .about-hero .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .about-hero .cont-reseau-d a:first-child img:first-child, .about-hero .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  .about-hero .cont-reseau-d a:first-child img:last-child, .about-hero .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  .about-hero .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  .about-hero .cont-reseau-d a:last-child {
    position: relative;
  }
  .about-hero .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .about-hero .cont-reseau-d a:last-child img:first-child, .about-hero .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 24%;
    transition: all ease 300ms;
  }
  .about-hero .cont-reseau-d a:last-child img:last-child, .about-hero .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 150%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  .about-hero .cont-reseau-d a:last-child:hover img {
    transform: translateY(35px);
  }
  .about-hero .cont-gauche-about-hero {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .about-hero .cont-gauche-about-hero h1 {
    font-family: Montserrat;
    font-size: 4.375rem;
    margin-top: 200px;
  }
  .about-hero .cont-gauche-about-hero .about-tite-l {
    top: 305px;
  }
  .about-hero .about-img img {
    height: 700px;
    width: 100%;
    margin-top: 175px;
  }
  .about-hero .cont-fleche {
    left: 90%;
  }
  .our-company {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-content: center;
    margin: 10% auto;
    margin-top: 0;
  }
  .our-company div {
    margin: 0 auto;
    width: 40%;
  }
  .our-company div:first-child {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .our-company div:first-child h1 {
    margin-top: 40px;
  }
  .our-company div:last-child img {
    margin-top: 0;
  }
  .core-values .carousel-values {
    justify-content: space-evenly;
  }
  .core-values .carousel-values .slide {
    padding: 0;
    width: 30%;
  }
  .core-values .carousel-values .slide:nth-child(2) {
    display: flex;
  }
  .core-values .carousel-values .slide:nth-child(3) {
    display: flex;
  }
  .core-values .cont-controls-values {
    align-items: center;
    margin-bottom: 10%;
  }
  .core-values .cont-controls-values .cont-tracker-values {
    margin-top: 0;
  }
  .our-team .carousel-team {
    display: flex;
    position: relative;
  }
  .our-team .carousel-team .slide {
    padding: 0;
    width: 30%;
  }
  .our-team .carousel-team .slide:nth-child(2) {
    display: flex;
  }
  .our-team .carousel-team .slide:nth-child(3) {
    display: flex;
  }
  .our-team .cont-controls-team {
    margin-top: 5%;
    margin-bottom: 10%;
    height: 36px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .our-team .cont-controls-team .cont-tracker-team {
    width: 30%;
    height: 6px;
    background-color: #c4c4c4;
    margin-top: 0%;
  }
  .our-team .cont-controls-team .cont-tracker-team .tracker-team {
    width: 15%;
    border: 3px solid #FFDF00;
    background-color: #FFDF00;
  }
}

@media screen and (min-width: 1440px) {
  .about-hero {
    height: 700px;
  }
  .about-hero .about-img img {
    height: auto;
    width: 125%;
  }
  .about-hero .cont-gauche-about-hero {
    margin-left: 3%;
  }
  .about-hero .cont-reseau-d {
    top: 550px;
    left: 5.5%;
  }
  .our-team .ourt {
    margin-left: 7%;
  }
  .our-company div:first-child h1 {
    margin-top: 90px;
    font-size: 3.75rem;
  }
  .our-company div:first-child h2 {
    font-size: 1.125rem;
  }
  .core-values h1:first-child {
    font-size: 3.75rem;
    margin-left: 7%;
  }
  .core-values .p-p {
    margin-left: 7%;
  }
  .core-values .swiper {
    margin-left: 7%;
  }
  .core-values .swiper .swiper-slide {
    margin-right: 15px;
  }
  .core-values .swiper .swiper-slide h2 {
    margin-left: 12%;
  }
  .core-values .swiper .swiper-slide:nth-child(3) .fr-3-about {
    margin-top: 25px;
  }
  .core-values .swiper .swiper-slide:nth-child(4) .fr-4-about {
    margin-top: 70px;
  }
  .core-values .swiper .swiper-slide:nth-child(5) h2 {
    margin-top: 25px;
  }
  .core-values .cont-swiper-core {
    margin-left: -3%;
    margin-right: 3%;
  }
  .core-values .cont-swiper-core .swiper-pagination-core {
    width: 70%;
  }
  .our-team h1 {
    margin-left: 7%;
  }
  .our-team .swiper2 {
    margin-left: 7%;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide {
    margin-right: 15px;
    height: 450px;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide .cont-nom {
    height: 0;
    padding: 0;
    transition: all ease 300ms;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(1):hover .cont-nom {
    height: 96px;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(2):hover .cont-nom {
    height: 96px;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(3):hover .cont-nom {
    height: 96px;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(4):hover .cont-nom {
    height: 96px;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(5):hover .cont-nom {
    height: 96px;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(6):hover .cont-nom {
    height: 96px;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(7):hover .cont-nom {
    height: 96px;
  }
  .our-team .swiper2 .swiper-wrapper .swiper-slide:nth-child(8):hover .cont-nom {
    height: 96px;
  }
  .our-team .cont-swiper-team {
    margin-left: -2.5%;
  }
  .our-team .cont-swiper-team .swiper-pagination-team {
    width: 74.5%;
  }
}

@media screen and (min-width: 1600px) {
  .core-values .swiper .swiper-slide:nth-child(5) h2 {
    margin-top: 25px;
  }
  .core-values .swiper .swiper-slide:nth-child(5) .fr-5-about {
    margin-right: 5%;
  }
  .core-values .swiper .swiper-slide:nth-child(4) .fr-4-about {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1920px) {
  .about-hero {
    height: 700px;
  }
  .core-values .swiper .swiper-slide:nth-child(1) h2 {
    margin-right: 58%;
  }
  .core-values .swiper .swiper-slide:nth-child(2) h2 {
    margin-right: 53%;
  }
  .core-values .swiper .swiper-slide:nth-child(3) h2 {
    margin-right: 61%;
  }
  .core-values .swiper .swiper-slide:nth-child(4) h2 {
    margin-right: 54%;
    margin-top: 50px;
  }
  .core-values .swiper .swiper-slide:nth-child(5) .fr-5-about {
    margin-right: auto;
  }
  .core-values .swiper .swiper-slide:nth-child(6) h2 {
    margin-right: 73%;
    margin-top: 0;
  }
  .core-values .swiper .swiper-slide:nth-child(6) .fun {
    margin-right: 61%;
  }
}

@media screen and (min-width: 360px) {
  .services-hero {
    background-color: black;
    position: relative;
    overflow: hidden;
  }
  .services-hero .cont-fleche {
    top: 770px;
  }
  .services-hero div {
    position: relative;
  }
  .services-hero h1 {
    margin-left: 10%;
    margin-top: 45%;
    text-transform: uppercase;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 2.5pxrem;
    color: white;
  }
  .services-hero .services-tite-l {
    position: absolute;
    display: inline-block;
    height: 5px;
    left: 10%;
    top: 70px;
    background: #FFDF00;
    animation: tite-ligne 600ms ease-in-out forwards;
  }
  @keyframes tite-ligne {
    0% {
      width: 0px;
    }
    100% {
      width: 138px;
    }
  }
  .services-hero .cont-reseau-d {
    display: none;
  }
  .services-hero p {
    color: white;
    font-family: Oxygen;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    width: 80%;
    margin: 20% auto;
  }
  .services-hero .cont-hero-img-services {
    background-image: url("../images/Gestion-Samurai_Services-BG-Rentals.svg");
    height: 251px;
    overflow: hidden;
    margin-left: 10%;
    background-size: cover;
  }
  .services-hero .cont-hero-img-services ul {
    list-style: none;
    line-height: 2.1875rem;
    margin-top: 30px;
  }
  .services-hero .cont-hero-img-services ul li {
    margin-top: 0;
  }
  .services-hero .cont-hero-img-services ul li span.active {
    color: #FFDF00;
  }
  .services-hero .cont-hero-img-services ul li #condo-span, .services-hero .cont-hero-img-services ul li #comm-span, .services-hero .cont-hero-img-services ul li #rentals-span {
    color: #FFDF00;
  }
  .services-hero .cont-hero-img-services ul li span {
    font-size: 1.5625rem;
  }
  .services-hero .cont-hero-img-services ul li a {
    text-decoration: none;
    text-transform: uppercase;
    color: white;
    font-size: 1.5625rem;
    cursor: pointer;
  }
  .services-hero .cont-hero-img-services ul li.non-active {
    opacity: 0.5;
  }
  .complete-package {
    margin-top: 70px;
    width: 80%;
    margin-left: 10%;
  }
  .complete-package h1 {
    margin-left: 10%;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 2.2856pxrem;
  }
  .complete-package h1 span {
    color: #FFDF00;
  }
  .complete-package p {
    font-family: Oxygen;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    width: 80%;
    margin: 10% auto;
    margin-bottom: 0;
    margin-top: 0;
  }
  .services-carousel {
    width: 100%;
    margin-top: 80px;
  }
  .services-carousel .carou {
    display: flex;
    justify-content: center;
    margin: 10px auto;
  }
  .services-carousel .carou .cont-fleche {
    top: 700px;
  }
  .cont-fleche {
    position: absolute;
    top: 85%;
    left: 80%;
  }
  .cont-fleche .fleche-bas-a {
    position: relative;
    box-sizing: border-box;
    height: 60px;
    width: 48px;
    display: flex;
    padding: 15px 18px;
  }
  .cont-fleche .fleche-bas-a img {
    margin-top: 0;
    margin-bottom: 0;
    width: 13px;
    height: 43px;
    z-index: 22;
  }
  .cont-fleche .fleche-bas-a .fleche-bas-div {
    position: absolute;
    top: 0;
    left: 0;
    height: 170px;
    width: 100%;
    background-color: #FFDF00;
    clip-path: polygon(0 0, 100% 0, 100% 15%, 0 30%);
    z-index: 2;
  }
  .cont-fleche .fleche-bas-a {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .cont-fleche .fleche-bas-a img {
    animation: animate 1.8s ease infinite;
    animation-delay: 1.5s;
  }
  @keyframes animate {
    0% {
      transform: translateY(0px);
      opacity: 1;
    }
    50% {
      transform: translateY(60px);
    }
    60% {
      transform: translateY(-55px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
    }
  }
  .services-carousel {
    margin-bottom: -10px;
  }
  .services-carousel .carou {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    background-color: #EAEAEA;
  }
  .services-carousel .carou .cont-fleche-carou {
    margin-top: 50px;
    margin-left: 60%;
    display: flex;
  }
  .services-carousel .carou .cont-fleche-carou img:first-child {
    cursor: pointer;
    transform: rotate(180deg) translateY(-1px);
    margin-right: 5px;
    width: 43px;
  }
  .services-carousel .carou .cont-fleche-carou img:last-child {
    cursor: pointer;
    width: 43px;
    margin-left: 5px;
  }
  .services-carousel .carou .greyed {
    opacity: 0.5;
  }
  .services-carousel .carou .hidden {
    display: none;
  }
  .services-carousel .carou .slides {
    position: relative;
    height: fit-content;
    font-family: Montserrat, 'sans-serif';
    font-weight: bold;
    display: flex;
    z-index: 2;
    justify-content: center;
  }
  .services-carousel .carou .slides .slide {
    padding-bottom: 30px;
  }
  .services-carousel .carou .slides .slide h1 {
    color: #FFDF00;
    margin-left: 15%;
    font-family: Montserrat;
    font-size: 2.5pxrem;
    line-height: 3.0475pxrem;
    margin-bottom: 0;
  }
  .services-carousel .carou .slides .slide h2 {
    text-transform: uppercase;
    margin-left: 15%;
    font-family: Montserrat;
    font-size: 1.25pxrem;
    line-height: 1.5237pxrem;
    margin-top: 0;
  }
  .services-carousel .carou .slides .slide .cont-bullet {
    width: 80%;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  .services-carousel .carou .slides .slide .cont-bullet p:first-child {
    font-size: 1.875rem;
    font-weight: 300;
    margin-right: 5%;
    margin-top: 0;
    height: 35px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .services-carousel .carou .slides .slide .cont-bullet p:last-child {
    font-family: Oxygen;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    margin-bottom: 10px;
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .mobile {
    width: 100%;
    overflow: hidden;
    position: fixed;
    z-index: 99;
  }
  .services-hero h1 {
    margin-top: 155px;
    font-size: 3.75rem;
  }
  .services-hero .services-tite-l {
    top: 95px;
  }
  .services-hero p {
    margin-top: 60px;
    margin-bottom: 80px;
  }
  .services-hero .cont-hero-img-services {
    height: 435px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
  .services-hero .cont-hero-img-services ul {
    padding-left: 8%;
    padding-top: 30px;
  }
  .services-hero .cont-hero-img-services ul li {
    margin-bottom: 10px;
  }
  .services-hero .cont-hero-img-services ul li span {
    font-size: 2.5rem;
  }
  .services-hero .cont-hero-img-services ul li a {
    font-size: 2.5rem;
  }
  .services-hero .cont-hero-img-services li {
    font-size: 1.875rem;
  }
  .services-hero .cont-fleche {
    top: 810px;
  }
  .complete-package p {
    margin-top: 20px;
  }
  .services-carousel .carou .cont-fleche-carou {
    margin-left: 80%;
  }
  .services-carousel .carou .slides {
    justify-content: flex-start;
    height: fit-content;
  }
  .services-carousel .carou .slides .cont-fleche-carou {
    margin-left: 78%;
  }
  .services-carousel .carou .slides .slide {
    margin-left: 5%;
    margin-right: auto;
    padding-bottom: 40px;
  }
  .services-carousel .carou .slides .slide:nth-child(2) .cont-bullet:nth-child(6) {
    margin-left: 10%;
  }
  .services-carousel .carou .slides .slide .slide-1 {
    margin-left: 1%;
  }
  .services-carousel .carou .slides .slide .slide-2 {
    margin-left: 5%;
  }
  .services-carousel .carou .slides .slide .slide-3 {
    margin-left: 2%;
  }
  .services-carousel .carou .slides .slide .cont-bullet {
    justify-content: flex-start;
    align-items: center;
    margin-left: 10%;
    margin-right: auto;
    margin-bottom: 0;
    line-height: 0.625rem;
  }
  .services-carousel .carou .slides .slide .cont-bullet p {
    margin-right: 20px;
  }
  .services-carousel .carou .slides .slide .cont-bullet:last-child {
    margin-left: 10%;
  }
}

@media screen and (min-width: 1024px) {
  .menu {
    position: relative;
  }
  .menu .cont-reseau-d {
    position: absolute;
    top: 100%;
    left: 5%;
  }
  .menu .cont-reseau-d a {
    text-decoration: none;
  }
  .menu .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .menu .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
  }
  .menu .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .menu .cont-reseau-d a:first-child img:first-child, .menu .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  .menu .cont-reseau-d a:first-child img:last-child, .menu .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  .menu .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  .menu .cont-reseau-d a:last-child {
    position: relative;
  }
  .menu .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .menu .cont-reseau-d a:last-child img:first-child, .menu .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 175%;
    transition: all ease 300ms;
  }
  .menu .cont-reseau-d a:last-child img:last-child, .menu .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 100%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  .menu .cont-reseau-d a:last-child:hover img {
    transform: translateY(50px);
  }
  #rentals, #comm, #condo {
    transition: all ease 200ms;
  }
  #rentals:hover, #comm:hover, #condo:hover {
    transform: translateX(15px);
  }
  .active-li {
    color: white;
  }
  .mobile {
    display: none;
  }
  .services-hero {
    display: flex;
    padding-top: 162px;
    width: 100%;
  }
  .services-hero .services-tite-l {
    top: 263px;
    width: 20%;
    left: 10%;
  }
  .services-hero div:first-child {
    width: 50%;
  }
  .services-hero div:first-child h1 {
    font-size: 5rem;
  }
  .services-hero .cont-hero-img-services {
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 15%;
    width: 50%;
    height: 75vh;
  }
  .services-hero .cont-hero-img-services ul {
    background-color: rgba(0, 0, 0, 0.2);
    padding-top: 55px;
    padding-left: 10%;
    margin-top: 0;
    height: inherit;
  }
  .services-hero .cont-hero-img-services ul li span {
    color: #FFDF00;
  }
  .services-hero .cont-reseau-d {
    display: none;
  }
  .cont-fleche {
    left: 90%;
    transition: all ease 100ms;
  }
  .complete-package {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 0;
    margin-left: 0;
  }
  .complete-package div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15%;
  }
  .complete-package div h1 {
    margin-left: 0;
    font-size: 3.75rem;
    margin-bottom: 20px;
  }
  .complete-package div p {
    margin-top: 0;
    margin-left: 0;
    margin-bottom: 60px;
  }
  .services-carousel .carou .cont-fleche-carou {
    margin-left: 80%;
  }
}

@media screen and (min-width: 1440px) {
  .services-hero {
    height: 780px;
  }
  .services-hero h1 {
    margin-top: 60px;
  }
  .services-hero .cont-hero-img-services {
    height: inherit;
  }
  .services-hero .cont-hero-img-services ul {
    margin-bottom: 0;
  }
  .services-hero .cont-reseau-d {
    display: block;
    position: absolute;
    top: 625px;
    left: 4.5%;
  }
  .services-hero .cont-reseau-d a {
    text-decoration: none;
  }
  .services-hero .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .services-hero .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
  }
  .services-hero .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .services-hero .cont-reseau-d a:first-child img:first-child, .services-hero .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  .services-hero .cont-reseau-d a:first-child img:last-child, .services-hero .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  .services-hero .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  .services-hero .cont-reseau-d a:last-child {
    position: relative;
  }
  .services-hero .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .services-hero .cont-reseau-d a:last-child img:first-child, .services-hero .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 22%;
    transition: all ease 300ms;
  }
  .services-hero .cont-reseau-d a:last-child img:last-child, .services-hero .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 220%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  .services-hero .cont-reseau-d a:last-child:hover img {
    transform: translateY(50px);
  }
  .services-hero .services-tite-l {
    top: 168px;
    left: 9.5%;
  }
  .flex-box {
    display: flex;
    flex-direction: row-reverse;
    height: fit-content;
    margin-bottom: auto;
  }
  .flex-box .complete-package {
    margin-top: 0;
  }
  .flex-box .complete-package div h1 {
    margin-right: 1%;
  }
  .flex-box .services-carousel {
    margin-top: 0;
  }
  .flex-box .services-carousel .carou {
    margin-top: 0;
  }
  .flex-box .services-carousel .carou .slides .slide .slide-1, .flex-box .services-carousel .carou .slides .slide .slide-2, .flex-box .services-carousel .carou .slides .slide .slide-3 {
    padding-bottom: 23px;
  }
}

.menu, .request-overlay {
  overflow: hidden;
  height: 0vh;
  background-color: black;
  animation: partie-jaune 200ms ease-out forwards;
  z-index: 87;
  width: 100%;
  position: fixed;
}

.menu .partie-jaune, .request-overlay .partie-jaune {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: url("../images/michael-descharles-UzV2VrbEOC8-unsplash 1.png");
  clip-path: polygon(0 0, 50% 0, 0 70%);
}

.menu .partie-jaune div, .request-overlay .partie-jaune div {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #FFDF00;
  mix-blend-mode: hard-light;
}

@keyframes partie-jaune {
  0% {
    height: 0;
  }
  100% {
    height: 100vh;
  }
}

.menu .request-form, .request-overlay .request-form {
  margin-top: 10vh;
  height: 90vh;
}

.menu .request-form .request-a, .request-overlay .request-form .request-a {
  font-size: 0.75rem;
  text-decoration: underline;
  text-transform: lowercase;
  display: inline;
  margin: 0;
}

.menu .request-form h1, .request-overlay .request-form h1 {
  display: inline-block;
  margin-top: 30vh;
  width: 50%;
  margin-left: 43%;
  color: white;
  font-family: Montserrat;
  font-size: 1.875rem;
  font-weight: 700;
  text-align: right;
}

.menu .request-form form, .request-overlay .request-form form {
  margin-left: 38%;
}

.menu .request-form form .request-a, .request-overlay .request-form form .request-a {
  font-size: 0.75rem;
  text-decoration: underline;
  text-transform: lowercase;
  display: inline;
  margin: 0;
}

.menu .request-form form input, .request-overlay .request-form form input {
  border: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  line-height: 1.25rem;
  margin-bottom: 20px;
  margin-top: 20px;
  color: white;
}

.menu .request-form form input:focus, .request-overlay .request-form form input:focus {
  outline: none;
  color: white;
  padding-bottom: 5px;
}

.menu .request-form form input:focus::placeholder, .request-overlay .request-form form input:focus::placeholder {
  opacity: 0;
}

.menu .request-form form input::placeholder, .request-overlay .request-form form input::placeholder {
  text-align: right;
  color: white;
  margin-bottom: 25px;
  line-height: 1.25rem;
  text-transform: uppercase;
  font-family: Oxygen;
  font-weight: 700;
  font-size: 0.75rem;
}

.menu .request-form form p, .request-overlay .request-form form p {
  font-size: 0.75rem;
  color: white;
  font-weight: 400;
}

.menu .request-form form a, .request-overlay .request-form form a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  z-index: 55;
  margin-left: 46%;
  margin-right: 10%;
  margin-top: 45px;
  font-weight: bold;
  font-family: Oxygen;
  font-size: 0.9375rem;
  text-align: center;
  width: 40%;
}

.menu .request-form form a span, .request-overlay .request-form form a span {
  transition: all ease 300ms;
}

.menu .request-form form a div, .request-overlay .request-form form a div {
  position: absolute;
  width: 115%;
  height: 60px;
  left: -10%;
  transition: all ease 400ms;
  border: 1px solid white;
  z-index: -1;
}

.menu .request-form form a img, .request-overlay .request-form form a img {
  margin-left: 10%;
  width: auto;
  height: 12px;
  z-index: 55;
  transition: all ease 300ms;
}

.menu .request-form form a:hover span, .request-overlay .request-form form a:hover span {
  margin-left: 50%;
}

.menu .request-form form a:hover img, .request-overlay .request-form form a:hover img {
  margin-left: 70%;
  opacity: 0;
}

.menu .bg-menu, .request-overlay .bg-menu {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.menu .bg-menu nav, .request-overlay .bg-menu nav {
  margin-top: 130px;
}

.menu .bg-menu nav ul, .request-overlay .bg-menu nav ul {
  padding: 0;
  list-style: none;
  margin-right: 25px;
}

.menu .bg-menu nav ul .ham-cont, .request-overlay .bg-menu nav ul .ham-cont {
  position: absolute;
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  display: flex;
  height: 50px;
  width: 50px;
  top: -8vh;
  margin-left: 70%;
}

.menu .bg-menu nav ul .ham-cont .actual-ham, .request-overlay .bg-menu nav ul .ham-cont .actual-ham {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-left: 10%;
  margin-top: 30%;
}

.menu .bg-menu nav ul .ham-cont .actual-ham span, .request-overlay .bg-menu nav ul .ham-cont .actual-ham span {
  display: inline-block;
  border: 1px solid #FFDF00;
  box-sizing: border-box;
  width: 30px;
  height: 2px;
  background-color: #FFDF00;
  margin: 3.5px 4px;
}

.menu .bg-menu nav ul .ham-cont .actual-ham .t-off1, .request-overlay .bg-menu nav ul .ham-cont .actual-ham .t-off1 {
  animation: t-off1 500ms ease forwards;
}

@keyframes t-off1 {
  0% {
    transform: translateY(5px) rotate(135deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.menu .bg-menu nav ul .ham-cont .actual-ham .t-off2, .request-overlay .bg-menu nav ul .ham-cont .actual-ham .t-off2 {
  animation: t-off2 500ms ease forwards;
}

@keyframes t-off2 {
  0% {
    transform: translateY(-5px) rotate(-135deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.menu .bg-menu nav ul .ham-cont .actual-ham .t-on1, .request-overlay .bg-menu nav ul .ham-cont .actual-ham .t-on1 {
  animation: t-on1 300ms ease forwards;
}

@keyframes t-on1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: translateY(5px) rotate(135deg);
  }
}

.menu .bg-menu nav ul .ham-cont .actual-ham .t-on2, .request-overlay .bg-menu nav ul .ham-cont .actual-ham .t-on2 {
  animation: t-on2 300ms ease forwards;
}

@keyframes t-on2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: translateY(-5px) rotate(-135deg);
  }
}

.menu .bg-menu nav ul li, .request-overlay .bg-menu nav ul li {
  text-align: right;
  overflow: hidden;
  height: 70px;
}

.menu .bg-menu nav ul li a, .request-overlay .bg-menu nav ul li a {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
  font-family: Oxygen;
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: 0.625%rem;
  text-align: right;
  position: relative;
  transition: all ease 500ms;
  width: inherit;
  overflow: hidden;
}

.menu .bg-menu nav ul li a:before, .request-overlay .bg-menu nav ul li a:before {
  content: "";
  position: absolute;
  opacity: 1;
  left: 15px;
  right: 15px;
  background: white;
  height: 1px;
  top: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-origin: left center;
  transform: translateY(-50%) scaleX(0);
  transition: transform 0.3s cubic-bezier(0.4, 0.38, 0, 0.98);
}

.menu .bg-menu nav ul li a:hover::before, .request-overlay .bg-menu nav ul li a:hover::before {
  transform: translateY(-50%) scaleX(1);
}

.menu .bg-menu nav ul li a:hover .top, .request-overlay .bg-menu nav ul li a:hover .top {
  transform: translateX(-2px);
}

.menu .bg-menu nav ul li a:hover .bottom, .request-overlay .bg-menu nav ul li a:hover .bottom {
  transform: translateX(2px);
}

.menu .bg-menu nav ul li .inner-wrap, .request-overlay .bg-menu nav ul li .inner-wrap {
  position: relative;
  display: inline-block;
}

.menu .bg-menu nav ul li .inner-wrap .main, .request-overlay .bg-menu nav ul li .inner-wrap .main {
  opacity: 0;
}

.menu .bg-menu nav ul li .inner-wrap > span:not(.main), .request-overlay .bg-menu nav ul li .inner-wrap > span:not(.main) {
  position: absolute;
  overflow: hidden;
  transition: 0.3s 0.05s cubic-bezier(0.4, 0.38, 0, 0.98);
}

.menu .bg-menu nav ul li .inner-wrap > span:not(.main).top, .request-overlay .bg-menu nav ul li .inner-wrap > span:not(.main).top {
  top: -50%;
}

.menu .bg-menu nav ul li .inner-wrap > span:not(.main).top span, .request-overlay .bg-menu nav ul li .inner-wrap > span:not(.main).top span {
  display: inline-block;
  transform: translateY(50%);
}

.menu .bg-menu nav ul li .inner-wrap > span:not(.main).bottom, .request-overlay .bg-menu nav ul li .inner-wrap > span:not(.main).bottom {
  top: 50%;
}

.menu .bg-menu nav ul li .inner-wrap > span:not(.main).bottom span, .request-overlay .bg-menu nav ul li .inner-wrap > span:not(.main).bottom span {
  display: inline-block;
  transform: translateY(-50%);
}

.menu .bg-menu nav ul li a:hover .top-slice, .request-overlay .bg-menu nav ul li a:hover .top-slice {
  opacity: 1;
  border-bottom: 1px solid white;
  transform: translateX(10px) scaleX(1);
}

.menu .bg-menu nav ul li a:hover .bot-slice, .request-overlay .bg-menu nav ul li a:hover .bot-slice {
  transform: translateX(20px);
}

.menu .bg-menu nav ul li:nth-child(5), .request-overlay .bg-menu nav ul li:nth-child(5) {
  width: 101.5%;
  overflow: visible;
}

.menu .bg-menu nav ul li:last-child, .request-overlay .bg-menu nav ul li:last-child {
  position: relative;
  width: 101.5%;
}

.menu .bg-menu nav ul .tite-ligne-menu, .request-overlay .bg-menu nav ul .tite-ligne-menu {
  position: absolute;
  width: 50%;
  height: 5px;
  background-color: #FFDF00;
  left: 65%;
  top: 310px;
}

.menu .bg-menu .cont-reseau-d, .request-overlay .bg-menu .cont-reseau-d {
  top: 530px;
  left: 65%;
}

.menu .bg-menu .cont-lang-call, .request-overlay .bg-menu .cont-lang-call {
  position: absolute;
  display: flex;
  top: 650px;
  justify-content: space-between;
  left: 0;
  width: 100%;
  color: white;
}

.menu .bg-menu .cont-lang-call div a, .request-overlay .bg-menu .cont-lang-call div a {
  text-decoration: none;
  color: white;
}

.menu .bg-menu .cont-lang-call div:first-child, .request-overlay .bg-menu .cont-lang-call div:first-child {
  display: flex;
  width: 100%;
  margin-left: 10%;
  text-transform: uppercase;
}

.menu .bg-menu .cont-lang-call div:last-child, .request-overlay .bg-menu .cont-lang-call div:last-child {
  display: flex;
  margin-right: 10%;
}

@media screen and (min-width: 360px) {
  .cont-terms {
    margin-top: 0px;
    height: fit-content;
    overflow: hidden;
  }
  .cont-terms .terms-page {
    width: 80%;
    margin: 150px auto;
    background-color: white;
  }
  .cont-terms .terms-page h1 {
    margin-top: 25px;
    font-weight: 700;
    font-family: Montserrat;
    font-size: 2.8125rem;
    color: black;
  }
  .cont-terms .terms-page p {
    font-family: Oxygen;
    font-weight: 400;
  }
  .cont-terms .terms-page p span {
    font-family: Oxygen;
    font-weight: 700;
  }
  .phone-link {
    color: white;
    text-decoration: none;
  }
  .upperbee {
    color: white;
    background-color: black;
    font-size: 0.9375rem;
  }
  .upperbee2 {
    position: absolute;
    top: 590px;
    left: 10%;
    text-decoration: none;
  }
  .r-call-confirm {
    height: 100vh;
    display: flex;
    background-color: black;
    justify-content: center;
  }
  .r-call-confirm p {
    width: 80%;
    position: absolute;
    margin: 0;
    margin-top: 40vh;
    color: white;
    text-transform: uppercase;
    text-align: center;
  }
  .cont-hero {
    background-color: black;
    color: white;
    position: relative;
    overflow: hidden;
    display: flex;
  }
  .cont-hero h1 {
    margin-top: 170px;
    margin-left: 10%;
    text-transform: uppercase;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 2.5pxrem;
    color: white;
  }
  .cont-hero .cont-reseau-d {
    display: none;
  }
  .cont-hero .services-tite-l {
    position: absolute;
    display: inline-block;
    height: 5px;
    left: 10%;
    top: 236px;
    background: #FFDF00;
    animation: tite-ligne 600ms ease-in-out forwards;
    width: 35%;
  }
  .cont-hero .cont-info-contact {
    margin-top: 50px;
    margin-bottom: 10%;
  }
  .cont-hero .cont-info-contact a {
    text-decoration: none;
    color: white;
  }
  .cont-hero .cont-info-contact div {
    display: flex;
    align-items: center;
  }
  .cont-hero .cont-info-contact div img {
    width: 5%;
  }
  .cont-hero .cont-info-contact div p {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2625rem;
    letter-spacing: 0.3125%rem;
  }
  .cont-hero .cont-info-contact div:nth-child(1) img {
    margin-left: 10%;
  }
  .cont-hero .cont-info-contact div:nth-child(1) p {
    margin-left: 10%;
  }
  .cont-hero .cont-info-contact div:nth-child(2) {
    margin-left: 10%;
  }
  .cont-hero .cont-info-contact div:nth-child(2) img {
    width: 6%;
    margin-right: 10%;
  }
  .cont-hero .cont-info-contact div:nth-child(3) img {
    margin-left: 10%;
    width: 4%;
  }
  .cont-hero .cont-info-contact div:nth-child(3) p {
    width: 60%;
    margin-left: 10%;
  }
  .cont-hero .anchor-form {
    display: none;
  }
  .error {
    position: absolute;
    color: red;
    font-family: Oxygen;
    font-size: 0.75rem;
  }
  .error-name {
    top: 147px;
    left: 23%;
  }
  .error-email {
    top: 215px;
    left: 23%;
  }
  .error-phone {
    top: 282px;
    left: 23%;
  }
  .error-form {
    left: 23%;
    top: 625px;
  }
  .anchor-form {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: black;
    height: 400px;
  }
  .anchor-form form {
    width: 80%;
    height: 720px;
    margin-top: 10px;
    background-color: white;
    padding-bottom: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: black;
  }
  .anchor-form form .request-a {
    font-size: 0.75rem;
    text-decoration: underline;
    text-transform: lowercase;
    display: inline;
    margin: 0;
    padding-left: 0;
  }
  .anchor-form form h1 {
    background-color: white;
    margin-top: 40px;
    margin-bottom: 30px;
    margin-left: 15%;
    margin-right: auto;
    text-transform: uppercase;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .anchor-form form h1 span {
    color: #FFDF00;
  }
  .anchor-form form input {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-family: Oxygen;
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 0.625%rem;
    line-height: 1.25rem;
    border: none;
    border-bottom: 1px solid grey;
    width: 70%;
    margin-bottom: 15%;
  }
  .anchor-form form input:first-child {
    margin-top: 50px;
  }
  .anchor-form form input::placeholder {
    color: black;
    font-weight: 700;
  }
  .anchor-form form input:focus {
    outline: none;
  }
  .anchor-form form input:focus::placeholder {
    color: transparent;
    transition: all ease 300ms;
  }
  .anchor-form form .submit-label {
    font-family: Oxygen;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    width: 70%;
    margin: 5% auto;
  }
  .anchor-form form .submit-label .request-a {
    font-size: 0.75rem;
    text-decoration: underline;
    text-transform: lowercase;
    display: inline;
    margin: 0;
  }
  .anchor-form form a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    z-index: 55;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    font-weight: bold;
    font-family: Oxygen;
    font-size: 0.9375rem;
    text-align: center;
    width: auto;
    padding-left: 2%;
  }
  .anchor-form form a .request-a {
    font-size: 0.75rem;
    text-decoration: underline;
    text-transform: lowercase;
    display: inline;
    margin: 0;
  }
  .anchor-form form a div {
    background-color: #FFDF00;
    position: absolute;
    width: 120%;
    height: 60px;
    left: -10%;
    transition: all ease 400ms;
    clip-path: polygon(0 0, 90% 0, 40% 100%, 0 100%);
    z-index: -1;
  }
  .anchor-form form a img {
    margin-left: 4%;
    padding-left: 10%;
    width: auto;
    height: 12px;
    z-index: 55;
  }
  .anchor-form form a:hover div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .contact-prefooter {
    height: 550px;
    background-image: url("../images/Gestion-Samurai_Contact-BG.svg");
    background-size: cover;
    background-position: bottom;
  }
}

@media screen and (min-width: 768px) {
  .upperbee2 {
    margin-left: 10%;
  }
  .menu .bg-menu nav ul .ham-cont, .request-overlay .bg-menu nav ul .ham-cont {
    top: -16vh;
  }
  .menu .bg-menu .cont-reseau-d, .request-overlay .bg-menu .cont-reseau-d {
    top: 525px;
    left: 84%;
  }
  .menu .bg-menu .cont-lang-call, .request-overlay .bg-menu .cont-lang-call {
    margin-left: 10%;
  }
  .menu .bg-menu .cont-lang-call div:last-child, .request-overlay .bg-menu .cont-lang-call div:last-child {
    margin-right: 15%;
  }
  .cont-terms {
    margin-top: 0px;
    height: fit-content;
    overflow: hidden;
  }
  .cont-terms .terms-page {
    width: 80%;
    margin: 150px auto;
    background-color: white;
  }
  .cont-terms .terms-page h1 {
    margin-top: 225px;
    font-weight: 700;
    font-family: Montserrat;
    font-size: 2.8125rem;
    color: black;
  }
  .cont-terms .terms-page p {
    font-family: Oxygen;
    font-weight: 400;
  }
  .cont-terms .terms-page p span {
    font-family: Oxygen;
    font-weight: 700;
  }
  .error-name {
    top: 267px;
    left: 23%;
  }
  .error-email {
    top: 384px;
    left: 23%;
  }
  .error-phone {
    top: 498px;
    left: 23%;
  }
  .error-form {
    left: 37%;
    top: 928px;
  }
  .request-overlay .request-form {
    display: flex;
    flex-direction: column;
  }
  .request-overlay .request-form form {
    display: flex;
    flex-direction: column;
  }
  .request-overlay .request-form form input {
    width: 80%;
    margin-left: auto;
    margin-right: 15%;
  }
  .request-overlay .request-form form p {
    margin-right: 15%;
    margin-left: 7%;
  }
  .request-overlay .request-form form a {
    margin-top: 10%;
    margin-right: 15%;
  }
  .cont-hero {
    overflow: hidden;
  }
  .cont-hero h1 {
    font-size: 3.75rem;
    margin-left: 17%;
  }
  .cont-hero .services-tite-l {
    top: 265px;
  }
  .cont-hero .cont-info-contact {
    margin-left: 10%;
  }
  .anchor-form {
    height: 730px;
  }
  .anchor-form form {
    height: 1040px;
  }
  .anchor-form form h1 {
    margin-top: 60px;
    font-size: 1.875rem;
    line-height: 2.5rem;
    margin-left: 15%;
  }
  .anchor-form form .request-a {
    font-size: 0.75rem;
    text-decoration: underline;
    text-transform: lowercase;
    display: inline;
    margin: 0;
    padding-left: 0;
  }
  .anchor-form form #yname {
    margin-top: 10%;
  }
  .contact-prefooter {
    height: 480px;
  }
}

@media screen and (min-width: 1024px) {
  .upperbee2 {
    display: none;
  }
  .menu .bg-menu .cont-lang-call div:first-child {
    margin-left: 22%;
  }
  .menu .bg-menu nav {
    margin-right: 3%;
  }
  .menu .bg-menu nav ul .ham-cont {
    top: -21vh;
  }
  .menu .bg-menu nav ul .tite-ligne-menu {
    width: 66%;
  }
  .menu .bg-menu .cont-reseau-d {
    top: 500px;
    left: 85%;
  }
  .menu .bg-menu .cont-lang-call div:last-child {
    margin-right: 15%;
  }
  .request-overlay .ham-cont {
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    width: 50px;
    margin-left: 95.8%;
    margin-top: 130px;
  }
  .request-overlay .ham-cont .actual-ham {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-left: 10%;
    margin-top: 30%;
  }
  .request-overlay .ham-cont .actual-ham span {
    display: inline-block;
    border: 1px solid #FFDF00;
    box-sizing: border-box;
    width: 30px;
    height: 2px;
    background-color: #FFDF00;
    margin: 3.5px 4px;
  }
  .request-overlay .ham-cont .actual-ham .t-off1 {
    animation: t-off1 500ms ease forwards;
  }
  @keyframes t-off1 {
    0% {
      transform: translateY(5px) rotate(135deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .request-overlay .ham-cont .actual-ham .t-off2 {
    animation: t-off2 500ms ease forwards;
  }
  @keyframes t-off2 {
    0% {
      transform: translateY(-5px) rotate(-135deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .request-overlay .ham-cont .actual-ham .t-on1 {
    animation: t-on1 300ms ease forwards;
  }
  @keyframes t-on1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: translateY(5px) rotate(135deg);
    }
  }
  .request-overlay .ham-cont .actual-ham .t-on2 {
    animation: t-on2 300ms ease forwards;
  }
  @keyframes t-on2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: translateY(-5px) rotate(-135deg);
    }
  }
  .request-overlay .request-form form {
    margin-left: 50%;
  }
  .error-name {
    top: 224px;
    left: 16%;
  }
  .error-email {
    top: 289px;
    left: 16%;
  }
  .error-phone {
    top: 353px;
    left: 16%;
  }
  .error-form {
    left: 32%;
    top: 111px;
  }
  .cont-hero {
    overflow: visible;
    justify-content: space-evenly;
  }
  .cont-hero .services-tite-l {
    width: 25%;
    top: 35%;
    left: 10%;
  }
  .cont-hero .anchor-form {
    display: flex;
    width: 50%;
    position: relative;
  }
  .cont-hero .anchor-form .form-movable {
    position: absolute;
    z-index: 55;
    top: 175px;
    width: 100%;
  }
  .cont-hero .anchor-form form {
    width: 100%;
    height: fit-content;
  }
  .cont-hero .anchor-form form h1 {
    color: black;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 1.5625rem;
    margin-left: 15%;
    margin-bottom: 0;
  }
  .cont-hero .anchor-form form input {
    margin-bottom: 8%;
  }
  .cont-hero .anchor-form form .submit-btn {
    position: relative;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
  }
  .cont-hero .anchor-form form .submit-btn .submit-label {
    margin-left: 15%;
    margin-right: 5%;
    width: 60%;
  }
  .cont-hero .anchor-form form .submit-btn .submit-label .request-a {
    font-size: 0.75rem;
    text-decoration: underline;
    text-transform: lowercase;
    display: inline;
    margin: 0;
    padding-left: 0;
  }
  .cont-hero .anchor-form form .submit-btn a {
    margin-left: 5%;
    margin-top: 0;
    margin-right: 0;
  }
  .cont-hero .anchor-form form .submit-btn a div {
    width: 130%;
  }
  .anchor-form {
    display: none;
  }
  .contact-prefooter {
    height: 400px;
    background-position-y: 95%;
  }
}

@media screen and (min-width: 1440px) {
  .menu .bg-menu nav ul li {
    height: 90px;
  }
  .menu .bg-menu nav ul li a {
    font-size: 3.75rem;
  }
  .menu .bg-menu nav ul .ham-cont {
    top: -22vh;
  }
  .menu .bg-menu nav ul .tite-ligne-menu {
    width: 100%;
    top: 395px;
  }
  .menu .bg-menu nav .cont-reseau-d {
    top: 600px;
    left: 88%;
  }
  .menu .bg-menu .cont-lang-call div:last-child {
    margin-right: 15%;
  }
  .error-name {
    top: 183px;
    left: 16%;
  }
  .error-email {
    top: 265px;
    left: 16%;
  }
  .error-phone {
    top: 345px;
    left: 16%;
  }
  .error-form {
    left: 32%;
    top: 111px;
  }
  .cont-hero .services-tite-l {
    top: 265px;
    left: 13.5%;
  }
  .cont-hero .cont-reseau-d {
    display: block;
    position: absolute;
    top: 555px;
    left: 9%;
  }
  .cont-hero .cont-reseau-d a {
    text-decoration: none;
  }
  .cont-hero .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cont-hero .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
  }
  .cont-hero .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .cont-hero .cont-reseau-d a:first-child img:first-child, .cont-hero .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  .cont-hero .cont-reseau-d a:first-child img:last-child, .cont-hero .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  .cont-hero .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  .cont-hero .cont-reseau-d a:last-child {
    position: relative;
  }
  .cont-hero .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .cont-hero .cont-reseau-d a:last-child img:first-child, .cont-hero .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 175%;
    transition: all ease 300ms;
  }
  .cont-hero .cont-reseau-d a:last-child img:last-child, .cont-hero .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 100%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  .cont-hero .cont-reseau-d a:last-child:hover img {
    transform: translateY(50px);
  }
  .request-overlay .request-form h1 {
    margin-left: 46%;
    margin-bottom: 30px;
    font-size: 3.75rem;
    margin-top: 160px;
  }
  .request-overlay .request-form form {
    margin-left: 65%;
  }
  .request-overlay .request-form form input {
    margin-bottom: 30px;
  }
  .request-overlay .request-form form a {
    margin-left: 43%;
  }
  .anchor-form {
    margin-left: 10%;
  }
  .anchor-form .form-movable form #yname {
    margin-top: 50px;
  }
  .anchor-form .form-movable form h1 {
    margin-right: 0;
  }
  .anchor-form .form-movable form h1 br {
    display: none;
  }
}

@media screen and (min-width: 1600px) {
  .error-phone {
    top: 355px;
  }
}

@media screen and (min-width: 1920px) {
  .cont-hero .services-tite-l {
    left: 10%;
  }
  .cont-hero .cont-reseau-d {
    display: block;
    position: absolute;
    top: 555px;
    left: 10%;
  }
  .cont-hero .cont-reseau-d a {
    text-decoration: none;
  }
  .cont-hero .cont-reseau-d div {
    border: solid 2px white;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cont-hero .cont-reseau-d a:first-child {
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
  }
  .cont-hero .cont-reseau-d a:first-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .cont-hero .cont-reseau-d a:first-child img:first-child, .cont-hero .cont-reseau-d a:first-child img:first-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    top: 30%;
    transition: all ease 300ms;
  }
  .cont-hero .cont-reseau-d a:first-child img:last-child, .cont-hero .cont-reseau-d a:first-child img:last-child::before {
    position: absolute;
    width: 6px;
    height: 14px;
    bottom: 140%;
    transition: all ease 300ms;
  }
  .cont-hero .cont-reseau-d a:first-child:hover img {
    transform: translateY(35px);
  }
  .cont-hero .cont-reseau-d a:last-child {
    position: relative;
  }
  .cont-hero .cont-reseau-d a:last-child div {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .cont-hero .cont-reseau-d a:last-child img:first-child, .cont-hero .cont-reseau-d a:last-child img:first-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    top: 175%;
    transition: all ease 300ms;
  }
  .cont-hero .cont-reseau-d a:last-child img:last-child, .cont-hero .cont-reseau-d a:last-child img:last-child::before {
    position: absolute;
    width: 13px;
    height: 13px;
    bottom: 100%;
    content: '';
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    transition: all ease 300ms;
  }
  .cont-hero .cont-reseau-d a:last-child:hover img {
    transform: translateY(50px);
  }
  .error-name {
    top: 202px;
    left: 16%;
  }
  .error-email {
    top: 305px;
    left: 16%;
  }
  .error-phone {
    top: 405px;
    left: 16%;
  }
  .error-form {
    left: 16%;
    top: 111px;
  }
  .anchor-form .form-movable form #yname {
    margin-top: 70px;
  }
  .anchor-form .form-movable form h1 {
    margin-right: 0;
  }
  .anchor-form .form-movable form h1 br {
    display: none;
  }
  .contact-prefooter {
    height: 600px;
  }
}

.m-t-5 {
  margin-top: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.u-hidden {
  display: none !important;
}

@media print {
  .hidden-print {
    display: none !important;
  }
}
