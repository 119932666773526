.menu, .request-overlay {
  overflow: hidden;
  //height: fit-content;
  height: 0vh;
  background-color: black;
  animation: partie-jaune 200ms ease-out forwards;
  z-index: 87;
  width: 100%;
  position: fixed;

  .partie-jaune {
    position: absolute;
    //top:10%;
    width: 100%;
    height: 100vh;

    background-image: url("../images/michael-descharles-UzV2VrbEOC8-unsplash 1.png");
    //mix-blend-mode: hard-light;
    clip-path: polygon(0 0, 50% 0, 0 70%);
    //animation: partie-jaune 200ms ease-in forwards;
    //animation-delay: 100ms;
    div {
      position: absolute;
      width: 100%;
      height: 100vh;
      background-color: #FFDF00;
      mix-blend-mode: hard-light;
      // animation: partie-jaune 200ms ease-in forwards;
      //animation-delay: 100ms;
    }
  }

  @keyframes partie-jaune {
    0% {
      height: 0;
    }
    100% {
      height: 100vh;
    }
  }

  .request-form {
    margin-top: 10vh;
    //background-color: red;
    height: 90vh;
    .request-a{
      font-size: 0.75rem;
      text-decoration: underline;
      text-transform: lowercase;
      display: inline;
      margin: 0;
    }

    h1 {
      display: inline-block;
      margin-top: 30vh;
      width: 50%;
      margin-left: 43%;
      //margin-right: 0;
      color: white;
      font-family: Montserrat;
      font-size: rem(30);
      font-weight: 700;
      text-align: right;
    }

    form {
      margin-left: 38%;
      .request-a{
        font-size: 0.75rem;
        text-decoration: underline;
        text-transform: lowercase;
        display: inline;
        margin: 0;
      }

      input {
        border: none;
        border-bottom: 1px solid white;
        background-color: transparent;
        line-height: rem(20);
        margin-bottom: 20px;
        margin-top: 20px;
        color: white;

      }
      input:focus{
        outline: none;
        color: white;
        padding-bottom: 5px;
      }

      input:focus::placeholder{
        opacity: 0;
      }

      input::placeholder {
        text-align: right;
        color: white;
        margin-bottom: 25px;
        line-height: rem(20);
        text-transform: uppercase;
        font-family: Oxygen;
        font-weight: 700;
        font-size: rem(12);
      }

      p {
        font-size: rem(12);
        color: white;
        font-weight: 400;
      }

      a {
        position: relative;
        display: flex;
        //display: inline-block;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        text-decoration: none;
        color: white;
        z-index: 55;
        margin-left: 46%;
        margin-right: 10%;
        margin-top: 45px;
        font-weight: bold;
        font-family: Oxygen;
        font-size: rem(15);
        text-align: center;

        width: 40%;

        span {
          //.margin-left: auto;
          transition: all ease 300ms;
        }

        div {
          //background-color: #FFDF00;
          position: absolute;
          width: 115%;
          height: 60px;
          left: -10%;
          transition: all ease 400ms;
          border: 1px solid white;
          //clip-path: polygon(0 0, 50% 0, 40% 100%, 0 100%);
          z-index: -1;
        }


        img {
          margin-left: 10%;
          width: auto;
          height: 12px;
          z-index: 55;

          transition: all ease 300ms;
        }
      }

      a:hover {

        // padding-right: 50%;
        span {
          margin-left: 50%;
        }

        // margin-left: auto;
        div {
          //clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          //          opacity: 0;
        }

        img {
          margin-left: 70%;
          opacity: 0;
        }
      }
    }
  }

  .bg-menu {
    display: flex;
    justify-content: flex-end;
    //position: sticky;
    margin-top: 20px;

    nav {
      margin-top: 130px;
      // position: relative;
      ul {
        padding: 0;
        list-style: none;
        margin-right: 25px;

        .ham-cont {
          position: absolute;
          border: 2px solid #FFFFFF;
          box-sizing: border-box;
          display: flex;
          height: 50px;
          width: 50px;
          top: -8vh;
          margin-left: 70%;
          //margin-right: 30%;

          .actual-ham {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            margin-left: 10%;
            margin-top: 30%;


            //les pains du hamburger
            span {
              display: inline-block;
              border: 1px solid #FFDF00;
              box-sizing: border-box;
              width: 30px;
              height: 2px;
              background-color: #FFDF00;
              margin: 3.5px 4px;
            }

            .t-off1 {
              animation: t-off1 500ms ease forwards;
            }

            @keyframes t-off1 {
              0% {
                transform: translateY(5px) rotate(135deg);

              }
              100% {
                transform: rotate(0deg);
              }
            }

            .t-off2 {
              animation: t-off2 500ms ease forwards;
            }

            @keyframes t-off2 {
              0% {
                transform: translateY(-5px) rotate(-135deg);

              }
              100% {
                transform: rotate(0deg);

              }
            }

            .t-on1 {

              animation: t-on1 300ms ease forwards;
            }

            @keyframes t-on1 {
              0% {
                transform: rotate(0deg);

              }
              100% {
                transform: translateY(5px) rotate(135deg);

              }
            }

            .t-on2 {
              animation: t-on2 300ms ease forwards;
            }

            @keyframes t-on2 {
              0% {
                transform: rotate(0deg);

              }
              100% {
                transform: translateY(-5px) rotate(-135deg);

              }
            }

          }
        }

        li {
          text-align: right;
          //position: relative;
          overflow: hidden;
          height: 70px;

          a {
            display: inline-block;
            text-decoration: none;
            text-transform: uppercase;
            color: white;
            //color: transparent;
            font-family: Oxygen;
            font-size: rem(40);
            font-weight: 700;
            letter-spacing: rem(10%);
            text-align: right;
            position: relative;
            transition: all ease 500ms;
            width: inherit;
            overflow: hidden;

            &:before {
              content: "";
              position: absolute;
              opacity: 1;
              left: 15px;
              right: 15px;
              background: white;
              height: 1px;
              top: 50%;
              -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
              transform-origin: left center;
              transform: translateY(-50%) scaleX(0);
              transition: transform 0.3s cubic-bezier(0.4, 0.38, 0, 0.98);
            }



          }

          a:hover::before {
            //transform: t;
            transform: translateY(-50%) scaleX(1);
          }

          a:hover {
            .top {
              transform: translateX(-2px);
            }

            .bottom {
              transform: translateX(2px);
            }
          }

          .inner-wrap {
            position: relative;
            display: inline-block;

            .main {
              opacity: 0;
            }
          }

          .inner-wrap > span:not(.main) {
            position: absolute;
            overflow: hidden;
            transition: 0.3s 0.05s cubic-bezier(0.4, 0.38, 0, 0.98);
          }

          .inner-wrap > span:not(.main).top {
            top: -50%;
          }

          .inner-wrap > span:not(.main).top span {
            display: inline-block;
            transform: translateY(50%);
          }

          .inner-wrap > span:not(.main).bottom {
            top: 50%;
          }

          .inner-wrap > span:not(.main).bottom span {
            display: inline-block;
            transform: translateY(-50%);
          }


          a:hover {
            .top-slice {
              //display: inline-block;
              opacity: 1;
              border-bottom: 1px solid white;
              transform: translateX(10px) scaleX(1);

            }

            .bot-slice {
              transform: translateX(20px);
              //opacity: 1;
              //transform: translateY(+20px);
            }
          }


        }

        li:nth-child(5) {
          width: 101.5%;
          overflow: visible;
        }

        li:last-child {
          position: relative;
          width: 101.5%;
        }

        .tite-ligne-menu {
          position: absolute;
          width: 50%;
          height: 5px;
          background-color: #FFDF00;
          left: 65%;
          top:  310px;
        }
      }
    }

    .cont-reseau-d {
      top: 530px;
      left: 65%;
    }

    .cont-lang-call {
      position: absolute;
      display: flex;
      top: 650px;
      justify-content: space-between;
      left: 0;
      width: 100%;
      color: white;

      div {
        a {
          text-decoration: none;
          color: white;
        }
      }

      div:first-child {
        display: flex;
        width: 100%;
        margin-left: 10%;
        //margin-right: 70%;
        text-transform: uppercase;
      }

      div:last-child {
        display: flex;
        margin-right: 10%;
      }
    }
  }
}
@media screen and(min-width: 360px) {

  .cont-terms{
    margin-top: 0px;
    height: fit-content;
    overflow: hidden;
    .terms-page{
      width: 80%;
      margin: 150px auto;
      background-color: white;
      h1{
        margin-top: 25px;
        font-weight: 700;
        font-family: Montserrat;
        font-size: rem(45);
        color: black;
      }
      p{
        font-family: Oxygen;
        font-weight: 400;
        span{
          font-family: Oxygen;
          font-weight: 700;
        }
      }
    }
  }
  .phone-link{
    color: white;
    text-decoration: none;
  }
  .upperbee{
    color: white;
    //border: 2px solid white;
    background-color: black;
    //padding: 5px;
    font-size: rem(15);
  }
  .upperbee2{
    position: absolute;
    top:590px;
    left: 10%;
    text-decoration: none;
  }

  .r-call-confirm{
    height: 100vh;
    display: flex;
    background-color: black;
    justify-content: center;
    p{
      width: 80%;
      position: absolute;
      margin: 0;
      margin-top: 40vh;
      color: white;
      text-transform: uppercase;
      text-align: center;

    }
  }
  .cont-hero {
    background-color: black;
    //margin-top: 0;
    color: white;
    position: relative;
    overflow: hidden;
    display: flex;

    h1 {
      margin-top: 170px;
      margin-left: 10%;
      text-transform: uppercase;
      font-family: Montserrat;
      font-weight: 700;
      font-size: rem(40px);
      color: white;
    }
    .cont-reseau-d{
      display: none;
    }

    .services-tite-l {
      position: absolute;
      display: inline-block;
      height: 5px;
      left: 10%;
      top: 236px;
      background: #FFDF00;
      animation: tite-ligne 600ms ease-in-out forwards;
      width: 35%;
    }

    .cont-info-contact {
      margin-top: 50px;
      margin-bottom: 10%;
      a{
        text-decoration: none;
        color: white;
      }

      div {
        display: flex;
        //justify-content: center;
        align-items: center;

        img {
          width: 5%;

        }

        p {

          font-family: Montserrat;
          font-weight: 400;
          font-size: rem(16);
          line-height: rem(20.2);
          letter-spacing: rem(5%);
        }
      }

      div:nth-child(1) {
        img {
          margin-left: 10%;
          //margin-right: auto;
        }

        p {
          margin-left: 10%;
        }
      }

      div:nth-child(2) {
        margin-left: 10%;

        img {
          width: 6%;
          margin-right: 10%;
        }

        p {
          //margin-left:20%;
        }
      }

      div:nth-child(3) {
        img {
          //margin: 0 auto;
          margin-left: 10%;
          width: 4%;
        }

        p {
          width: 60%;
          margin-left: 10%;
        }
      }

    }

    .anchor-form {
      display: none;
    }
  }

  .error{
    position: absolute;
    color: red;
    font-family: Oxygen;
    font-size: rem(12);

  }
  .error-name{
    //background-color: red;
    top:147px;
    left:23%;
  }
  .error-email{
    top:215px;
    left: 23%;
    ////background-color: red;
  }
  .error-phone{
    top: 282px;
    left: 23%;
    //background-color: red;
  }
  .error-form{
    left: 23%;
    top:625px;
    //background-color: red;
  }


  .anchor-form {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: black;
    height: 400px;

    form {
      width: 80%;
      height: 720px;
      margin-top: 10px;
      background-color: white;
      padding-bottom: 20%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      color: black;
      .request-a{
        font-size: 0.75rem;
        text-decoration: underline;
        text-transform: lowercase;
        display: inline;
        margin: 0;
        padding-left: 0;
      }

      h1 {
        background-color: white;
        margin-top: 40px;
        margin-bottom: 30px;
        margin-left: 15%;
        margin-right: auto;
        text-transform: uppercase;
        font-family: Montserrat;
        font-weight: 700;
        font-size: rem(16);
        line-height: rem(20);

        span {
          color: #FFDF00;
        }
      }

      input {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        font-family: Oxygen;
        font-weight: 700;
        font-size: rem(12);
        letter-spacing: rem(10%);
        line-height: rem(20);

        border: none;
        border-bottom: 1px solid grey;
        width: 70%;
        margin-bottom: 15%;



      }

      input:first-child {
        margin-top: 50px;
      }

      input::placeholder {
        color: black;
        font-weight: 700;
      }
      input:nth-child(6){
       // margin-bottom: 30px;
       // background-color: red;
      }

      input:focus {
        outline: none;

      }

      input:focus::placeholder {
        color: transparent;
        transition: all ease 300ms;
      }

      .submit-label {
        font-family: Oxygen;
        font-weight: 400;
        font-size: rem(12);
        line-height: rem(20);
        width: 70%;
        margin: 5% auto;
        .request-a{
          font-size: 0.75rem;
          text-decoration: underline;
          text-transform: lowercase;
          display: inline;
          margin: 0;
        }
      }

      a {
        position: relative;
        display: flex;
        //display: inline-block;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        text-decoration: none;
        color: black;
        z-index: 55;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10%;
        font-weight: bold;
        font-family: Oxygen;
        font-size: rem(15);
        text-align: center;
        width: auto;
        padding-left: 2%;
        .request-a{
          font-size: 0.75rem;
          text-decoration: underline;
          text-transform: lowercase;
          display: inline;
          margin: 0;
        }


        div {
          background-color: #FFDF00;
          position: absolute;
          width: 120%;
          height: 60px;
          left: -10%;
          transition: all ease 400ms;

          clip-path: polygon(0 0, 90% 0, 40% 100%, 0 100%);
          z-index: -1;
        }


        img {
          margin-left: 4%;
          padding-left: 10%;
          width: auto;
          height: 12px;
          z-index: 55;
        }
      }


      a:hover {
        div {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }
      }

    }

  }
  .contact-prefooter {
    height: 550px;
    background-image: url("../images/Gestion-Samurai_Contact-BG.svg");
    background-size: cover;
    background-position: bottom;
  }
}

@media screen and(min-width: 768px) {
  .upperbee2{
    margin-left: 10%;
  }
  .menu, .request-overlay {
    .bg-menu {
      nav {
        ul {
          .ham-cont {
            top: -16vh;
            //margin-left: 15%;
          }
        }
      }


      .cont-reseau-d {
        top: 525px;
        left: 84%;
      }
      .cont-lang-call{
        margin-left: 10%;
        div:last-child{
          margin-right: 15%;
          a{
            //color: red;
          }

        }
      }
    }
  }
  .cont-terms{
    margin-top: 0px;
    height: fit-content;
    overflow: hidden;
    .terms-page{
      width: 80%;
      margin: 150px auto;
      background-color: white;
      h1{
        margin-top: 225px;
        font-weight: 700;
        font-family: Montserrat;
        font-size: rem(45);
        color: black;
      }
      p{
        font-family: Oxygen;
        font-weight: 400;
        span{
          font-family: Oxygen;
          font-weight: 700;
        }
      }
    }
  }

  .error-name{
    //background-color: red;
    top:267px;
    left:23%;
  }
  .error-email{
    top:384px;
    left: 23%;
    ////background-color: red;
  }
  .error-phone{
    top: 498px;
    left: 23%;
    //background-color: red;
  }
  .error-form{
    left: 37%;
    top:928px;
    //background-color: red;
  }

  .request-overlay {
    .request-form {
      //width: 80%;
      display: flex;
      flex-direction: column;

      form {
        display: flex;
        flex-direction: column;

        input {
          width: 80%;
          margin-left: auto;
          margin-right: 15%;
        }

        p {
          margin-right: 15%;
          margin-left: 7%;
        }

        a {
          margin-top: 10%;
          margin-right: 15%;
        }
      }
    }
  }

  .cont-hero {
    overflow: hidden;
    h1{
      font-size: rem(60);
      margin-left: 17%;
    }
    .services-tite-l{
      top:265px;
    }
    .cont-info-contact{
      margin-left: 10%;
    }
  }
  .anchor-form {
    height: 730px;

    form {
      height: 1040px;
      h1{
        margin-top: 60px;
        font-size: rem(30);
        line-height: rem(40);
        margin-left: 15%;
      }
      .request-a{
        font-size: 0.75rem;
        text-decoration: underline;
        text-transform: lowercase;
        display: inline;
        margin: 0;
        padding-left: 0;
      }

      #yname {
        margin-top: 10%;
      }
    }
  }
  .contact-prefooter{
    height: 480px;
  }
}

@media screen and(min-width: 1024px) {

  .upperbee2{
  display: none;
}
  .menu {
    //position: relative;
    .bg-menu {
      .cont-lang-call{
        div:first-child{
          margin-left: 22%;
        }
      }

      nav {
        margin-right: 3%;
        ul {
          .ham-cont {
            top: -21vh;
          }
          .tite-ligne-menu{
            width: 66%;
          }
        }
      }

      .cont-reseau-d {
        top: 500px;
        left: 85%;
      }
      .cont-lang-call{
        div:last-child{
          margin-right: 15%;
        }
      }
    }
  }
  .request-overlay {
    .ham-cont {
      border: 2px solid #FFFFFF;
      box-sizing: border-box;
      display: flex;
      height: 50px;
      width: 50px;
      margin-left: 95.8%;
      margin-top: 130px;
      //margin-right: 30%;

      .actual-ham {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-left: 10%;
        margin-top: 30%;


        //les pains du hamburger
        span {
          display: inline-block;
          border: 1px solid #FFDF00;
          box-sizing: border-box;
          width: 30px;
          height: 2px;
          background-color: #FFDF00;
          margin: 3.5px 4px;
        }

        .t-off1 {
          animation: t-off1 500ms ease forwards;
        }

        @keyframes t-off1 {
          0% {
            transform: translateY(5px) rotate(135deg);

          }
          100% {
            transform: rotate(0deg);
          }
        }

        .t-off2 {
          animation: t-off2 500ms ease forwards;
        }

        @keyframes t-off2 {
          0% {
            transform: translateY(-5px) rotate(-135deg);

          }
          100% {
            transform: rotate(0deg);

          }
        }

        .t-on1 {

          animation: t-on1 300ms ease forwards;
        }

        @keyframes t-on1 {
          0% {
            transform: rotate(0deg);

          }
          100% {
            transform: translateY(5px) rotate(135deg);

          }
        }

        .t-on2 {
          animation: t-on2 300ms ease forwards;
        }

        @keyframes t-on2 {
          0% {
            transform: rotate(0deg);

          }
          100% {
            transform: translateY(-5px) rotate(-135deg);

          }
        }

      }
    }
    .request-form{
      form{
        margin-left: 50%;
      }
    }
  }

  .error-name{
    //background-color: red;
    top:224px;
    left:16%;
  }
  .error-email{
    top:289px;
    left: 16%;
    ////background-color: red;
  }
  .error-phone{
    top: 353px;
    left: 16%;
    //background-color: red;
  }
  .error-form{
    left: 32%;
    top:111px;
    //background-color: red;
  }


  .cont-hero {
    overflow: visible;
    justify-content: space-evenly;

    .services-tite-l {
      width: 25%;
      top: 35%;
      left: 10%;
    }

    .anchor-form {
      display: flex;
      width: 50%;
      position: relative;

      .form-movable {
        position: absolute;
        z-index: 55;
        top:175px;
        width: 100%;
      }

      form {
        width: 100%;
        height: fit-content;

        h1 {
          color: black;
          font-family: Montserrat;
          font-weight: 700;
          font-size: rem(25);
          margin-left: 15%;
          margin-bottom: 0;

          //br {
          //  display: none;
          //}
        }

        input {
          margin-bottom: 8%;
        }

        .submit-btn {
          position: relative;
          width: 90%;
          display: flex;
          justify-content: center;
          align-items: center;
          //margin-left: 10%;
          margin-right: auto;
          //overflow: hidden;
          //margin-top: 20px;

          .submit-label {
            margin-left: 15%;
            margin-right: 5%;
            width: 60%;
            .request-a{
              font-size: 0.75rem;
              text-decoration: underline;
              text-transform: lowercase;
              display: inline;
              margin: 0;
              padding-left: 0;
            }
          }

          a {
            margin-left: 5%;
            margin-top: 0;
            margin-right: 0;

            div {
              width: 130%;
            }
          }

          a:hover {
            div {

            }
          }
        }
      }

    }
  }
  .anchor-form {
    display: none;
  }

  .contact-prefooter{
    height: 400px;
    background-position-y:95% ;
  }
}

@media screen and(min-width: 1440px) {
  .menu {
    .bg-menu {
      nav {
        ul {
          li{
            height: 90px;
            a{
              font-size: rem(60);
            }
          }
          .ham-cont {
            top: -22vh;
          }
          .tite-ligne-menu{
            width: 100%;
            top:395px;
          }
        }
        .cont-reseau-d {
          top: 600px;
          left: 88%;
          //background-color: red;
        }
      }


      .cont-lang-call{
        div:last-child{
          margin-right: 15%;
        }
      }
    }
  }
  .error-name{
    //background-color: red;
    top:183px;
    left:16%;
  }
  .error-email{
    top:265px;
    left: 16%;
    ////background-color: red;
  }
  .error-phone{
    top: 345px;
    left: 16%;
    //background-color: red;
  }
  .error-form{
    left: 32%;
    top:111px;
    //background-color: red;
  }
  .cont-hero{
    .services-tite-l{
      top:265px;
      //left: 12%;
      left:13.5%;
    }
    .cont-reseau-d {
      display: block;
      position: absolute;
      top: 555px;
      left: 9%;

      a {
        text-decoration: none;
      }

      div {
        border: solid 2px white;
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

      }

      a:first-child {
        display: inline-block;
        margin-bottom: 10px;
        position: relative;

        div {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

        img:first-child, img:first-child::before {
          position: absolute;
          width: 6px;
          height: 14px;
          top: 30%;
          transition: all ease 300ms;
        }

        img:last-child, img:last-child::before {
          position: absolute;
          width: 6px;
          height: 14px;
          bottom: 140%;
          transition: all ease 300ms;
        }


      }

      a:first-child:hover {
        img {
          transform: translateY(35px);
        }

      }

      a:last-child {
        position: relative;

        div {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }


        img:first-child, img:first-child::before {
          position: absolute;
          width: 13px;
          height: 13px;
          top: 175%;
          transition: all ease 300ms;
        }

        img:last-child, img:last-child::before {
          position: absolute;
          width: 13px;
          height: 13px;
          bottom: 100%;
          content: '';
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          transition: all ease 300ms;

        }


      }

      a:last-child:hover {
        img {

          transform: translateY(50px);
        }
      }

    }
  }
  .request-overlay{
    .request-form{
      h1{
        margin-left: 46%;
        margin-bottom: 30px;
        font-size: rem(60);
        margin-top: 160px;
        //width: 80%;
      }
      form{
        margin-left: 65%;

        input{
          margin-bottom: 30px;
        }
        a{
          //margin-right: ;
          margin-left: 43%;
        }
      }
    }
  }
  .anchor-form{
    margin-left: 10%;
    .form-movable{
      form{
        #yname{
          margin-top: 50px;
        }
        h1{
          margin-right: 0;
         // width: 70%;
          br{
            display: none;
          }
        }
      }
    }
  }
}

@media screen and(min-width: 1600px){
  .error-phone{
    top:355px;
  }
}

@media screen and(min-width: 1920px){
  .cont-hero{
    .services-tite-l{
        left: 10%;
    }
    .cont-reseau-d {
      display: block;
      position: absolute;
      top: 555px;
      left: 10%;

      a {
        text-decoration: none;
      }

      div {
        border: solid 2px white;
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

      }

      a:first-child {
        display: inline-block;
        margin-bottom: 10px;
        position: relative;

        div {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }

        img:first-child, img:first-child::before {
          position: absolute;
          width: 6px;
          height: 14px;
          top: 30%;
          transition: all ease 300ms;
        }

        img:last-child, img:last-child::before {
          position: absolute;
          width: 6px;
          height: 14px;
          bottom: 140%;
          transition: all ease 300ms;
        }


      }

      a:first-child:hover {
        img {
          transform: translateY(35px);
        }

      }

      a:last-child {
        position: relative;

        div {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        }


        img:first-child, img:first-child::before {
          position: absolute;
          width: 13px;
          height: 13px;
          top: 175%;
          transition: all ease 300ms;
        }

        img:last-child, img:last-child::before {
          position: absolute;
          width: 13px;
          height: 13px;
          bottom: 100%;
          content: '';
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          transition: all ease 300ms;

        }


      }

      a:last-child:hover {
        img {

          transform: translateY(50px);
        }
      }

    }
  }
  .error-name{
    //background-color: red;
    top:202px;
    left:16%;
  }
  .error-email{
    top:305px;
    left: 16%;
    ////background-color: red;
  }
  .error-phone{
    top: 405px;
    left: 16%;
    //background-color: red;
  }
  .error-form{
    left: 16%;
    top:111px;
    //background-color: red;
  }
  .anchor-form{
//margin-left: 20%;
    .form-movable{
      form{
        #yname{
          margin-top: 70px;
        }
        h1{
          margin-right: 0;
          // width: 70%;
          br{
            display: none;
          }
        }
      }
    }
  }
  .contact-prefooter{
    height: 600px;
  }

}