
button, .gform_button {
  margin: 80px 25px;
  padding: 20px;
  border: none;
  background-color: $noir;
  color: $blanc;
  font-family: $ff-title;
  font-weight: $fw-bold;
  font-size: $fz-button;

  position: relative;
  width: 250px;
}

//button:hover a, .gform_button:hover a {
//  color: $bleu;
//}


button::before {
  content: "";

  background-image: url("../images/cercle.png");
  position: absolute;

  width: 161px;
  height: 162px;

  z-index: -1;
  right: 45%;
  top: -70%;
}


button::after {
  content: "";

  background-image: url("../images/triangle.png");
  position: absolute;

  width: 85px;
  height: 93px;

  z-index: 1;
  bottom: 100%;
  right: -40%;

  //opacity: 0;
  transition: 1s;
}


button:hover::after {
  border: none;

  bottom: 60%;
  right: -20%;

  opacity: 1;

  a {
    color: $bleu;
  }
}


.lesboutons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin: 0 auto;
  //width:60%;

  .unbouton {
    width: 45%;
  }
}

.centrer {
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    width: 300px;
  }

  button::before {

    right: 55%;
    top: -70%;
  }


  button::after {

    bottom: 100%;
    right: -30%;
  }

  button:hover::after {

    bottom: 60%;
    right: -15%;
  }
}

@media screen and (max-width: 375px) {

  button::before {
    background-size: 45%;
    background-repeat: no-repeat;
    right: -20%;
    top: -50%;
  }


  button, .gform_button {
    font-size: $fz-mobilebutton;
    margin: 30px 25px;
    padding: 10px;
    font-weight: 700;
    width: 125px;
  }

  button::after {
    background-size: 35%;
    background-repeat: no-repeat;
    bottom: -40%;
    right: -100%;
  }

  button:hover::after {

    bottom: -120%;
    right: -55%;
  }

  .lesboutons {
    display: flex;
    flex-direction: row;

    margin: 0 auto;
    //width:60%;

    .unbouton {
      width: 45%;
    }
    button::after {
      background-size: 35%;
      background-repeat: no-repeat;
      bottom: -40%;
      right: -50%;
    }

    button:hover::after {

      bottom: -120%;
      right: -55%;
    }

  }
}


